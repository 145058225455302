import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addCategorie, getCategories } from "../../reducers/commonReducer";

const schema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string(),
  image: yup.mixed().required(),
  status: yup.string().required(),
  showOnHome: yup.string().required(),
  orderby: yup.string(),
  warehouse: yup.string().required(), 
  settings: yup.array()
  .min(1, 'Please select at least one shipment')
  .required('This field is required'),
});

function CategoriesAdd(props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true); 
  const { wareHouseList, getFileSettingsList } = useSelector((state) => state.commonReducer);

  return (
    <>
      <Button className="mb-2 theme-btn" onClick={handleShow}>
        Add Categories
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Categories</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            await dispatch(addCategorie(values));
            resetForm({ values: "" });
            await dispatch(getCategories());
            handleClose();
          }}
          initialValues={{
            name: "",
            description: "",
            status: "",
            image: "",
            orderby: "",
            showOnHome: '',
            warehouse:'',
            settings:'',
            banner:'',
            bannerstatus:''
          }}
        >
          {({ handleSubmit, handleChange, values, setFieldValue, errors }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label htmlFor="name">Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label htmlFor="myfile">Image</Form.Label>
                    <Form.Control
                      id="myfile"
                      type="file"
                      name="image"
                      onChange={(event) => {
                        const resumeFile = event.target.files[0];
                        setFieldValue("image", resumeFile);
                      }}
                      isInvalid={!!errors.image}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.image}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label htmlFor="banner">Offer Banner</Form.Label>
                    <Form.Control
                      id="banner"
                      type="file"
                      name="banner"
                      onChange={(event) => {
                        const resumeFile = event.target.files[0];
                        setFieldValue("banner", resumeFile);
                      }}
                      isInvalid={!!errors.banner}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.banner}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Label htmlFor="showOnHome">Offer Banner Status</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setFieldValue("bannerstatus", (/true/).test(e.target.value));
                    }}
                    name="bannerstatus"
                    className="form-control"
                    value={values.bannerstatus}
                    isInvalid={!!errors.bannerstatus}
                  >
                    <option value="">Select Offer Banner</option>
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label htmlFor="description">Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Description"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      isInvalid={!!errors.description}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>OrderBY</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="orderby"
                      name="orderby"
                      value={values.orderby}
                      onChange={handleChange}
                      isInvalid={!!errors.orderby}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.orderby}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <Form.Label htmlFor="status">Status</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setFieldValue("status", (/true/).test(e.target.value));
                    }}
                    name="status"
                    className="form-control"
                    value={values.status}
                    isInvalid={!!errors.status}
                  >
                    <option value="">Select Status</option>
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                  </Form.Control>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Label htmlFor="showOnHome">ShowOnHome</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setFieldValue("showOnHome", (/true/).test(e.target.value));
                    }}
                    name="showOnHome"
                    className="form-control"
                    value={values.showOnHome}
                    isInvalid={!!errors.showOnHome}
                  >
                    <option value="">Select ShowOnHome</option>
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row className="mb-3">
              <Col md={12} sm={12}>
                  <Form.Label>Warehouse</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setFieldValue("warehouse", e.target.value);
                    }}
                    name="warehouse"
                    className="form-control"
                    value={values.warehouse}
                    isInvalid={!!errors.warehouse}
                  >
                    <option value="">Select WareHouse</option>
                    {
                      !!wareHouseList && wareHouseList?.map((user, index) =>  <option key={index} value={user?.id}>{user?.name}</option>)
                    }
                  </Form.Control>
                </Col>
              </Row>
              <Row className="mb-3">
              <Col md={12} sm={12}>
                  <Form.Label>Shipment</Form.Label>
                  <Form.Control
                    multiple={true}
                    as="select"
                    onChange={handleChange}
                    name="settings"
                    className="form-control"
                    value={values.settings}
                    isInvalid={!!errors.settings}
                  >
                    {/* <option value="">Select Shipment</option> */}
                    {
                      !!getFileSettingsList && getFileSettingsList?.map((user, index) =>  <option key={index} value={user?.id}>{user?.name}</option>)
                    }
                  </Form.Control>
                </Col>
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
                <Button variant="danger" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default CategoriesAdd;
