import React from "react";
import SideMenu from "../../Components/SideMenu";
import AdminOrderDetail from "./AdminOrderDetail";
import GotoTop from "../../Components/GotoTop";

const UserAdminOrderDetails = () => {
  return (
    <>
      <SideMenu />
        <div className="mt-extra content container-fluid main-print-conatiner">
         <AdminOrderDetail/> 
        </div>
        <GotoTop/>
    </>
  );
};

export default UserAdminOrderDetails;
