import React from 'react';
import './Loader.css';
import fav from "../images/loader.png"

export default function Loader() {

  return (
    <div className="loader-container">
        <img src={fav} alt="Logo" className="loader-logo" />
    </div>
  )
}
