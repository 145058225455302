import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  adminOrders,
  adminOrdersExport,
  manualOrderShip,
  orderDelete,
  orderItemVerify,
  resetAdminOrderList,
} from "../../reducers/orderDetailsReducer";
import ExportData from "../../helpers/ExportData";
import SideMenu from "../../Components/SideMenu";
import moment from "moment";
import OrderUpdate from "./OrderUpdate";
import { Button, Row, Form, InputGroup, Col } from "react-bootstrap";
import { parseInt } from "lodash";

// function useScrollPosition() {
//   const location = useLocation();
//   useEffect(() => {
//     // Restore scroll position on entering the page
//     const savedScrollPosition = sessionStorage.getItem(
//       `scrollPosition_${location.pathname}`
//     );
//     if (savedScrollPosition) {
//       window.scrollTo(0, parseInt(savedScrollPosition, 10));
//     }

//     return () => {
//       // Save the current scroll position before leaving the page
//       sessionStorage.setItem(
//         `scrollPosition_${location.pathname}`,
//         window.scrollY
//       );
//     };
//   }, [location]);
// }

const OrdersList = () => {
  // useScrollPosition();
  const width = useRef(window.innerWidth);
  const location = useLocation();
  const containerRef = useRef(null);
  const currentPosition = useRef(null);
  const [search, setSearch] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [orderStatus, setOrderStatus] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const queryParameters = new URLSearchParams(window.location.search);
  const orderId = queryParameters.get("orderid");
  const userId = queryParameters.get("userid");
  const pageSlug = location.pathname; 
  useEffect(() => {
    setLoading(true)
    dispatch(resetAdminOrderList());
    let timeOut = setTimeout(async() => {
     const apiResp = await dispatch(
        adminOrders({
          search: search,
          userId,
        })
      );
      if(apiResp?.payload?.status){
        setLoading(false)
      }
    }, 50);
    return () => clearTimeout(timeOut);
  }, [search]);

  
  //current location
  useEffect(() => {
    if(!loading){
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        localStorage.setItem(`scroll-height-${pageSlug}`, scrollPosition); 
      }; 
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [location.pathname, loading]);

  useEffect(() =>{
    const savedScrollPosition = localStorage.getItem(`scroll-height-${pageSlug}`);
    if(!loading){
      window.scrollTo(0, +savedScrollPosition);
      localStorage.removeItem(`scroll-height-${pageSlug}`)
    }
  }, [location, loading])
 //end current location


  const { adminOrdersData, adminOrdersBlank, adminOrdersExportList } =
    useSelector((state) => state.orderDetailReducer);
  let orderQty = 0;
  // combo selection
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allOrderIds = adminOrdersData?.map((order) => order.id);
      setSelectedOrders(allOrderIds);
    } else {
      setSelectedOrders([]);
    }
  };

  const handleSelectOrder = (event, orderId) => {
    if (event.target.checked) {
      setSelectedOrders([...selectedOrders, orderId]);
    } else {
      setSelectedOrders(selectedOrders.filter((id) => id !== orderId));
    }
  };

  const header = [
    // "Sr",
    "OrderId",
    "ItemName",
    "Date",
    "ItemQty",
    "TaxableAmount",
    "TaxIGSTAmount",
    "TaxIgst",
    "TaxSGSTAmount",
    "TaxSgst",
    "TaxCGSTAmount",
    "TaxCgst ",
    "TotalAmount",
    "DiscountAnyCoupan",
    "PaymentGateway",
    "PaymentType",
    "PaymentMethod",
    "CODCharges",
    "Address",
    "City",
    "PINCode",
    "State",
  ];
  const body = [];
  // Temp stop order export
  let checking = 0;
  !!adminOrdersExportList && checking &&
    adminOrdersExportList?.forEach((data) => {
      data?.items?.forEach((item) => {
        const totalAmount = item?.sale ? item?.sale : item?.mrp;
        const taxPerc = parseInt(item?.productId?.tax_class?.percentage);
        const gstAmount =
          totalAmount - ((item?.sale ? item?.sale : item?.mrp) * taxPerc) / 100;
        const itemName = item?.productId?.name || ""; // Fallback if name is missing
        const itemQty = item.quantity;

        // Create a row for each quantity unit
        for (let i = 0; i < itemQty; i++) {
          body.push({
            // Sr: index + 1,
            OrderId: data?.successid ? data?.successid : data?.orderid,
            ItemName: itemName,
            Date: moment(data?.createdAt).format("DD/MM/YYYY HH:mm:ss"),
            ItemQty: 1, // Each row represents 1 unit
            TaxableAmount: gstAmount,
            TaxIGSTAmount:
              data?.shippingAddress?.state !== "HARYANA"
                ? ((item?.sale ? item?.sale : item?.mrp) * taxPerc) / 100
                : "",
            TaxIgst:
              data?.shippingAddress?.state !== "HARYANA" ? `${taxPerc}%` : "",
            TaxSGSTAmount:
              data?.shippingAddress?.state === "HARYANA"
                ? ((item?.sale ? item?.sale : item?.mrp) * taxPerc) / 100 / 2
                : "",
            TaxSgst:
              data?.shippingAddress?.state === "HARYANA"
                ? `${taxPerc / 2}%`
                : "",
            TaxCGSTAmount:
              data?.shippingAddress?.state === "HARYANA"
                ? ((item?.sale ? item?.sale : item?.mrp) * taxPerc) / 100 / 2
                : "",
            TaxCgst:
              data?.shippingAddress?.state === "HARYANA"
                ? `${taxPerc / 2}%`
                : "",
            TotalAmount: totalAmount,
            DiscountAnyCoupan: data?.coupondiscount || data?.qtydiscount,
            PaymentGateway: data?.paymentGateway,
            PaymentType: data?.status === 1 ? "Cancel" : data?.paymenttype,
            PaymentMethod: data?.status === 1 ? "Cancel" : data?.paymentMethod,
            CODCharges: data?.codChargesAmount,
            Address: `${data?.shippingAddress?.addressline1} ${
              data?.shippingAddress?.addressline12 || ""
            }`,
            City: data?.shippingAddress?.city,
            PINCode: data?.shippingAddress?.zipcode,
            State: data?.shippingAddress?.state,
          });
        }
      });
    });
   
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid" ref={containerRef}>
        <Row className="mb-1">
          <Col md={3} className="mt-4">
            <InputGroup className="search-wrapper">
              <InputGroup.Text
                id="inputGroup-sizing-default"
                className="custom-search-icon"
              >
                <i className="bi bi-search"></i>
              </InputGroup.Text>
              <Form.Control
                className="animated-search-input"
                placeholder="Search anything..."
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                onChange={(e) => setSearch(e.target.value)}
              />
            </InputGroup>
          </Col>
          <Col md={2} sx={6} xs={6}>
            <Form.Group>
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                className="animated-search-input"
                type="date"
                placeholder="Start Date"
                name="startDate"
                onChange={(e) => {
                  dispatch(resetAdminOrderList());
                  dispatch(
                    adminOrders({
                      userId,
                      endDate,
                      startDate: e.target.value,
                      search,
                      page,
                    })
                  );
                  dispatch(
                    adminOrdersExport({
                      userId,
                      endDate,
                      startDate: e.target.value,
                    })
                  );
                  setStartDate(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col md={2} sx={6} xs={6}>
            <Form.Group>
              <Form.Label>End Date</Form.Label>
              <Form.Control
                className="animated-search-input"
                type="date"
                placeholder="End Date"
                name="endtDate"
                onChange={(e) => {
                  dispatch(resetAdminOrderList());
                  dispatch(
                    adminOrders({
                      search: search,
                      userId,
                      endDate: e.target.value,
                      startDate,
                      search,
                      page,
                    })
                  );
                  dispatch(
                    adminOrdersExport({
                      userId,
                      endDate: e.target.value,
                      startDate,
                    })
                  );
                  setEndDate(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col
            md={2}
            sx={6}
            xs={6}
            className={width.current > 500 ? "mt-4" : "mt-1"}
          >
            <Form.Select
              onChange={(e) => {
                dispatch(resetAdminOrderList());
                setOrderStatus(+e.target.value);
                dispatch(
                  adminOrders({
                    status: e.target.value === "all" ? "all" : +e.target.value,
                    page,
                    startDate,
                    endDate,
                  })
                );
                dispatch(
                  adminOrdersExport({
                    status: e.target.value === "all" ? "all" : +e.target.value,
                    endDate,
                    startDate,
                  })
                );
              }}
              aria-label="Default select example"
              className="fancyy-select-dropdown"
            >
              <option value={"all"}>Select Status</option>
              <option value={"all"} className="text-dark">
                <b>All</b>
              </option>
              {/* <option value={0} className="text-warning">
                  <b>Pending</b>
                </option> */}
              <option value={1} className="text-danger">
                <b>Cancel</b>
              </option>
              <option value={2} className="text-primary">
                <b>Processing</b>
              </option>
              <option value={3} className="text-secondary">
                <b>Verify</b>
              </option>
              <option value={4} className="text-dark">
                <b>In_Transit</b>
              </option>
              <option value={5} className="text-info">
                <b>Shipping</b>
              </option>
              <option value={6} className="text-success">
                <b>Completed</b>
              </option>
            </Form.Select>
          </Col>
          <Col
            md={2}
            sx={3}
            xs={3}
            className={width.current > 500 ? "mt-4 text-end" : "mt-3"}
          >
            <Button
              variant="danger"
              // className="animatedd-clear-btn"
              onClick={() => window.location.reload()}
            >
              Clear
            </Button>
          </Col>
          <Col
            md={1}
            sx={3}
            xs={3}
            className={width.current > 500 ? "mt-4" : "mt-3"}
          >
            {localStorage.getItem("slug") === "admin" ? (
              <ExportData
                title="Export"
                tableName={"Ranbazaar Orders"}
                header={header}
                body={body}
              />
            ) : null}
          </Col>
        </Row>
        {localStorage.getItem("slug") === "admin" ? (
          <>
            <Row className="no-more-tables no-more-tables-font">
              <table className={width.current > 500 ? "table w-100" : null}>
                <thead>
                  <tr className="tables">
                    {/* <th>
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={adminOrdersData.length > 0 && selectedOrders.length === adminOrdersData.length}
                />
                </th> */}
                    <th>Order</th>
                    <th>AWB / WayBillNo</th>
                    <th>AdminOrderId</th>
                    <th>Items</th>
                    <th>Date</th>
                    <th>Total</th>
                    <th>Payment Status</th>
                    <th>Gateway/Method</th>
                    <th>Customer</th>
                    <th>Delivery Method</th>
                    <th>Order Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="tbody-table">
                  {!!adminOrdersData &&
                    adminOrdersData?.map((orders, index) => {
                      let orderItemQty = 0;
                      orders?.items?.forEach((item) => {
                        orderItemQty += item.quantity;
                      });
                      // orderQty += orderItemQty;
                      return (
                        <tr key={index} className="mbl-table-card">
                          {/* <td>
                        <input
                          type="checkbox"
                          onChange={(e) => handleSelectOrder(e, orders.id)}
                          checked={selectedOrders.includes(orders.id)}
                        />
                      </td> */}
                          <td
                            data-title="Order"
                            className="button-pointer"
                            onClick={() =>
                              navigate(`/order-details?orderid=${orders?.id}`)
                            }
                          >
                            <b className="f-16">
                              {orders?.successid
                                ? orders?.successid
                                : orders?.orderid}
                            </b>
                          </td>
                          {width.current < 668 ? (
                            orders?.waybillno !== "" && (
                              <td data-title="AWB / WayBillNo">
                                {orders?.otherWayBills?.length
                                  ? orders?.otherWayBills?.map(
                                      (billnumber, bilIndex) => (
                                        <span key={bilIndex}>
                                          {billnumber?.waybillno},{" "}
                                        </span>
                                      )
                                    )
                                  : orders?.waybillno
                                  ? orders?.waybillno
                                  : orders?.shiplog?.remarks?.[0]}
                              </td>
                            )
                          ) : (
                            <td data-title="AWB / WayBillNo">
                              {orders?.otherWayBills?.length
                                ? orders?.otherWayBills?.map(
                                    (billnumber, bilIndex) => (
                                      <span key={bilIndex}>
                                        {billnumber?.waybillno},{" "}
                                      </span>
                                    )
                                  )
                                : orders?.waybillno
                                ? orders?.waybillno
                                : orders?.shiplog?.remarks?.[0]}
                            </td>
                          )}
                          <td data-title="AdminOrderId">{orders?.orderid}</td>
                          <td
                            data-title="Items"
                            className="button-pointer"
                            onClick={() =>
                              navigate(`/order-details?orderid=${orders?.id}`)
                            }
                          >
                            {orderItemQty}
                          </td>
                          <td
                            data-title="Date"
                            className="button-pointer"
                            onClick={() =>
                              navigate(`/order-details?orderid=${orders?.id}`)
                            }
                          >
                            {moment(orders?.createdAt).format(
                              "D MMM [at] h:mm a"
                            )}
                             {/* <br/>
                             {moment.utc(orders?.createdAt).format(
                              "D MMM [at] h:mm a"
                            )}<br/>
                            {new Date(orders?.createdAt).toLocaleString()} */}
                          </td>
                          {/* <td data-title="Order">₹ {orders?.payment}</td>
                      <td data-title="Order"><span className={`rounded px-2 f-13 ${orders?.paymenttype === "PENDING" && `bg-warning`} ${orders?.paymenttype === "COD" && `bg-info`}`}>{((orders?.paymenttype === "Prepaid") ? "Paid" : orders?.paymenttype)}</span></td> */}
                          <td
                            data-title="Total"
                            className="button-pointer"
                            onClick={() =>
                              navigate(`/order-details?orderid=${orders?.id}`)
                            }
                          >
                            ₹{" "}
                            {orders?.totalPaybleAmount +
                              orders?.codChargesAmount}
                          </td>
                          <td
                            data-title="Payment Status"
                            className="button-pointer"
                            onClick={() =>
                              navigate(`/order-details?orderid=${orders?.id}`)
                            }
                          >
                            <span
                              className={`rounded px-2 f-13 ${
                                orders?.paymenttype === "PENDING" &&
                                `bg-warning`
                              } ${orders?.paymenttype === "COD" && `bg-info`}`}
                            >
                              {(orders?.paymenttype === "Prepaid" && "Paid") ||
                                (orders?.paymenttype === "COD" && "UnPaid") ||
                                (orders?.paymenttype === "PENDING" &&
                                  "PENDING")}
                            </span>
                          </td>
                          <td
                            data-title="Gateway/Method"
                            className="button-pointer"
                            onClick={() =>
                              navigate(`/order-details?orderid=${orders?.id}`)
                            }
                          >
                            {orders?.paymentGateway} / {orders?.paymentMethod}
                          </td>
                          <td
                            data-title="Customer"
                            className="button-pointer"
                            onClick={() =>
                              navigate(`/order-details?orderid=${orders?.id}`)
                            }
                          >
                            <b>
                              {orders?.shippingAddress?.firstName
                                ? `${orders?.shippingAddress?.firstName} ${
                                    orders?.shippingAddress?.lastName || ""
                                  }`
                                : "No Customer"}
                            </b>{" "}
                            <span className={"f-right"}>
                              Total Orders:{" "}
                              <b>{orders?.user?.orderCounter?.total}</b>
                            </span>
                          </td>
                          <td
                            data-title="Delivery Method"
                            onClick={() =>
                              navigate(`/order-details?orderid=${orders?.id}`)
                            }
                            className="f-16 button-pointer"
                          >
                            <b>{orders?.paymenttype}</b>
                          </td>
                          <td data-title="Order Status">
                            <span
                              className={
                                (orders?.status === 0 &&
                                  "text-dark bg-primary rounded px-2 f-13 ") ||
                                (orders?.status === 1 &&
                                  "text-dark bg-danger rounded px-2 f-13") ||
                                (orders?.status === 2 &&
                                  "text-dark bg-warning rounded px-2 f-13") ||
                                (orders?.status === 3 &&
                                  "text-dark bg-secondary rounded px-2 f-13") ||
                                (orders?.status === 4 &&
                                  "text-white bg-dark rounded px-2 f-13") ||
                                (orders?.status === 5 &&
                                  "text-dark bg-info rounded px-2 f-13") ||
                                (orders?.status === 6 &&
                                  "text-dark bg-success rounded px-2 f-13")
                              }
                            >
                              {/* 0 pending,1 cancel, 2 processing, 3 verify 4 In_Transit , 5 shipping, 6 completed */}
                              <b className="td1 ">
                                {orders?.deliveryStatus?.Status
                                  ? orders?.deliveryStatus?.Status
                                  : (orders?.status === 0 && "Pending") ||
                                    (orders?.status === 1 && "Cancel") ||
                                    (orders?.status === 2 && "Processing") ||
                                    (orders?.status === 3 && "Verify") ||
                                    (orders?.status === 4 && "In_Transit") ||
                                    (orders?.status === 5 && "Shipping") ||
                                    (orders?.status === 6 && "Completed")}
                              </b>
                            </span>
                          </td>
                          <td
                            data-title="Action"
                            className="d-flex justify-content-center"
                          >
                            <Link
                              to={`/order-details?orderid=${orders?.id}`}
                              // onClick={() =>dispatch(
                              //   singleOrder({ id: orderId })
                              // )}
                            >
                              <Button
                                className={
                                  width.current < 668
                                    ? "px-1 py-0 bg-thememain btn-mbl-text"
                                    : "m-1 bg-thememain btn-mbl-text"
                                }
                              >
                                <i className="bi bi-eye-fill"></i>
                              </Button>
                            </Link>
                            <OrderUpdate
                              title={"Update Order Status"}
                              orderStatus={orders?.status}
                              orderId={orders?.id}
                              listingApi={adminOrders}
                              cod={orders?.codChargesAmount}
                              paymentStatusUpdate={orders?.paymenttype}
                            />
                           
                            {localStorage.getItem("username") ===
                              "master@example.com" &&
                              orders?.status === 1 &&
                              index === 0 && (
                                <Button
                                  variant="danger"
                                  className="m-1 btn btn-danger"
                                  onClick={() => {
                                    const alertResp = window.confirm(
                                      "Are You sure Delete this order."
                                    );
                                    if (alertResp) {
                                      dispatch(
                                        orderDelete({
                                          id: orders?.id,
                                        })
                                      );
                                      window.location.reload();
                                    }
                                  }}
                                >
                                  <i class="bi bi-trash"></i>
                                </Button>
                              )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Row>
          </>
        ) : (
          <Row className="mb-1">
            {!!adminOrdersData &&
              adminOrdersData?.map((orders, index) => (
                <Col sm={12} xs={12} md={4} key={index} className="mb-3">
                  <div className="bg-gray p-2 rounded">
                    <div className="d-flex justify-content-between">
                      <h4 className="text-black">
                        OrderId:-{" "}
                        <b>
                          {orders?.successid
                            ? orders?.successid
                            : orders?.orderid}
                        </b>
                      </h4>
                      {/* <Link to={`/order-details?orderid=${orders?.id}`}>
                        <Button
                          className={
                            width.current < 668
                              ? "px-1 py-0 bg-thememain btn-mbl-text"
                              : "m-1 bg-thememain btn-mbl-text"
                          }
                        >
                          <i className="bi bi-eye-fill"></i>
                        </Button>
                      </Link> */}
                    </div>
                    {orders?.items?.map((item, itemIndex) => (
                      <Fragment key={itemIndex}>
                        <div className="bg-white mb-2 rounded p-1">
                          <Button
                            variant={item?.verifyStatus ? "success" : "warning"}
                            //disabled={item?.verifyStatus ? true : false}
                            className="p-1"
                            onClick={async () => {
                              // const confirmation = window.confirm(
                              //   "Are you sure verify this order?"
                              // );
                              // if (confirmation) {
                                const apiResp = await dispatch(
                                  orderItemVerify({
                                    orderid: orders?.id,
                                    index: itemIndex,
                                    status: !item?.verifyStatus,
                                  })
                                );
                                if (apiResp?.payload?.status) {
                                  dispatch(resetAdminOrderList());
                                  dispatch(
                                    adminOrders({
                                      page,
                                      userId,
                                    })
                                  );
                                }
                              // }
                            }}
                          >
                            {item?.verifyStatus ? "Verified" : "Verify"}
                          </Button>
                          {!item?.verifyStatus && (
                            // <Link
                            //   to={`/product/${item?.productId?.slug}?orderid=${orders?.id}&itemIndex=${itemIndex}&productType=true`}
                            // >
                              <Button 
                                onClick={() => {
                                  // localStorage.setItem(`scroll-height`, window.scrollY)
                                  navigate(`/product/${item?.productId?.slug}?orderid=${orders?.id}&itemIndex=${itemIndex}&productType=true`)
                                }}
                                variant="warning" 
                                className="p-1 f-right">
                                <i className="bi bi-pen-fill"></i>
                              </Button>
                            // </Link>
                          )}
                          <img
                            key={itemIndex}
                            src={item?.mergeimg}
                            alt={item?.productId?.name}
                            className="mb-2"
                          />
                        </div>
                      </Fragment>
                    ))}
                  </div>
                </Col>
              ))}
          </Row>
        )}
        {!!adminOrdersBlank && adminOrdersBlank?.length > 0 ? (
          <Row>
            <Button
              onClick={() => {
                setPage(page + 1);
                dispatch(
                  adminOrders({
                    search: search,
                    page: page + 1,
                    status: orderStatus,
                    startDate,
                    endDate,
                    userId,
                  })
                );
              }}
            >
              Load More
            </Button>
          </Row>
        ) : (
          <p className="text-center">
            <b>No record found 😔.</b>
          </p>
        )}
      </div>
    </>
  );
};

export default OrdersList;
