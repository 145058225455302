import React from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { adminOrderComment, singleOrder } from "../../reducers/orderDetailsReducer";

const schema = yup.object().shape({
  comment: yup.string().required(),
});

const OrderComment = () => {
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const orderId = queryParameters.get("orderid");
  return (
    <>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            const apiResp = await dispatch(adminOrderComment(values));
            if(apiResp?.payload?.status){
              dispatch(singleOrder({ id: orderId }));
              resetForm({ values: "" });
            }
          }}
          initialValues={{
            orderid: orderId,
            comment: ""
          }}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <Form className="container mt-3" onSubmit={handleSubmit}>
              <Row>
                <h6 className="px-0"><b>Timeline Comment</b></h6>
                <Col md={12} sm={12} className="px-0">
                  <Form.Group>
                    <Form.Control
                      as="textarea"
                      type="text"
                      placeholder="Leave a comment......."
                      row={4}
                      name="comment"
                      value={values.comment}
                      onChange={handleChange}
                      isInvalid={!!errors.comment}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.comment}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Button className="f-right" variant="success" type="submit">
                  Post
                </Button>
              </Row>
            </Form>
          )}
        </Formik>
    </>
  );
};

export default OrderComment;
