import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import SideMenu from "../../Components/SideMenu";
import { getUserAllAddress } from "../../reducers/commonReducer";

const AdminUserAddress = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("userid");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserAllAddress({ id }));
  }, []);
  const { userAllAddress } = useSelector((state) => state.commonReducer);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid">
        <Row>
          {!!userAllAddress &&
            userAllAddress?.map((data, index) => {
              return (
                <Col md={3} key={index}>
                  <div className={`shadow p-3 mb-5 text-white rounded ${data?.servicable ? `bg-success` : `bg-danger`}`}>
                    <p className="mb-0">
                      <b>
                        {data?.firstName} {data?.lastName || ""}
                      </b>
                    </p>
                    <p className="mb-0">
                      <b>Phone: </b>
                      {data?.phone}
                    </p>
                    <p className="mb-0">
                      <b>Email: </b>
                      {data?.email}
                    </p>
                    <p className="mb-0">
                      <b>AlternateNumber: </b>
                      {data?.alternateNumber}
                    </p>
                    <p className="mb-0">
                      <b>Address: </b>
                      {`${data?.addressline1 || ""} ${
                        data?.addressline2 || ""
                      }`}
                    </p>
                    <p className="mb-0">
                      <b>PIN Code: </b>
                      {data?.zipcode}
                    </p>
                    <p className="mb-0">
                      <b>City: </b>
                      {data?.city}
                    </p>
                    <p className="mb-0">
                      <b>State: </b>
                      {data?.state}
                    </p>
                  </div>
                </Col>
              );
            })}
        </Row>
      </div>
    </>
  );
};

export default AdminUserAddress;
