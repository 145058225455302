import React from "react";
import { handleContextMenu } from "../../const";

const Banner = (props) => {
  return (
    <>
        <img src={props?.bannerImg}  onContextMenu={handleContextMenu}/>
    </>
  );
};

export default Banner;
