import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideMenu from "../../Components/SideMenu";
import {
  deleteInvoiceOrderPdfFiles,
  getInvoiceList,
  getOrderPdfFiles,
  orderIdWiseInvoice,
} from "../../reducers/commonReducer";
import { handleDownload, home } from "../../const";
import { Button, Col, Row, Table } from "react-bootstrap";
import FilterList from "./FilterList";

const InvoiceFiles = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [orderid, setOrderid] = useState();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate(home);
    }
    dispatch(getInvoiceList());
  }, []);
  //delete order pdf
  const deleteRecord = async() => {
    const apiResp = await dispatch(deleteInvoiceOrderPdfFiles());
    if (apiResp?.payload?.status) {
      dispatch(getOrderPdfFiles());
      dispatch(getInvoiceList());
    }
  };
  const { getInvoiceData } = useSelector((state) => state.commonReducer);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid">
        <Row className="mb-3 mt-3">
          <Col>
            <div className="d-flex">
              <div className="form-group w-80">
                <input type="text" onChange={(e) => setOrderid(e.target.value)} className="form-control" placeholder="Enter Order Id Like:- 123, 456"/>
              </div>
              <Button variant="success" onClick={() => dispatch(orderIdWiseInvoice({orderid}))}>Submit</Button>
            </div>
          </Col>
        </Row>
        <FilterList />
        {getInvoiceData?.length ? <Row>
          <Col>
            <Button
              onClick={deleteRecord}
              variant="danger"
              className="float-right"
            >
              Delete <i className="bi bi-trash3-fill"></i>
            </Button>
          </Col>
        </Row> : null}
        {/* Group orders */}
        <Row>
          <Col md={12}>
            <h3 className="text-black">
              <b>Invoice Pdf</b>
            </h3>
          </Col>
        </Row>
        <Table>
          <thead>
            <tr className="tables">
              <td className="col-1">Sr.</td>
              <td className="col-6">Name</td>
              <td className="col-1">Download</td>
            </tr>
          </thead>
          {!!getInvoiceData &&
            getInvoiceData?.map((data, index) => {
              return (
                <tbody className="tbody-table">
                <tr key={index}>
                  <td>{index + 1}.</td>
                  <td className="p-2">
                    {" "}
                    {data.substring(data.lastIndexOf("/") + 1)}
                  </td>
                  <td className="text-success p-2">
                    <b>
                      <span onClick={() => handleDownload(data)}>Download</span>
                    </b>
                  </td>
                </tr>
                </tbody>
              );
            })}
        </Table>
      </div>
    </>
  );
};

export default InvoiceFiles;
