import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideMenu from "../../Components/SideMenu";
import { Button } from "react-bootstrap";
import { deleteCategorie, getCategories, getFileSettings, getWareHouse } from "../../reducers/commonReducer";
import CategoriesUpdate from "./CategoriesUpdate";
import CategoriesAdd from "./CategoriesAdd";
import { handleContextMenu, home } from "../../const";

const Categories = () => {
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const categId = queryParameters.get("catId");
  const dispatch = useDispatch();


  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate(home);
    }
    dispatch(
      categId
        ? getCategories({
            parent: categId,
          })
        : getCategories()
    );
    dispatch(getWareHouse())
    dispatch(getFileSettings())
  }, [categId]);

  const { getCategoriesData } = useSelector((state) => state.commonReducer);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid">
        <span className="m-2">
          <CategoriesAdd />
        </span>
        <table className="table w-100">
          <thead>
            <tr className="tables">
              <th className="over-col-size">Name</th>
              <th className="col-1">OrderBY</th>
              <th className="col-1">Image</th>
              <th className="over-col-size">Warehouse</th>
              <th className="col-3">Description</th>
              <th className="col-1">Status</th>
              <th className="col-1">ShowOnHome</th>
              <th className="col-1">OfferBanner</th>
              <th className="over-col-size">OfferBannerStatus</th>
              <th className="over-col-size">Action</th>
            </tr>
          </thead>
          <tbody className="tbody-table">
            {!!getCategoriesData &&
              getCategoriesData?.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{data?.name}</td>
                    <td><b>{data?.orderby}</b></td>
                    <td>
                      <img className="tbl-img" src={data?.image} onContextMenu={handleContextMenu}/>
                    </td>
                    <td>{data?.warehouse?.name}</td>
                    <td>{data?.description}</td>
                    <td>
                      <b>{data?.status === true ? <span className="text-white bg-success rounded px-2 f-13">True</span> : <span className="text-white bg-danger rounded px-2 f-13">False</span>}</b>
                    </td>
                    <td>
                      <b>{data?.showOnHome === true ? <span className="text-white bg-success rounded px-2 f-13">Active</span> : <span className="text-white bg-danger rounded px-2 f-13">InActive</span>}</b>
                    </td>
                    <td>
                      <img className="w-50" src={data?.banner} onContextMenu={handleContextMenu}/>
                    </td>
                    <td><b>{data?.status === true ? <span className="text-white bg-success rounded px-2 f-13">True</span> : <span className="text-white bg-danger rounded px-2 f-13">False</span>}</b></td>
                    <td>
                      <CategoriesUpdate
                        title="Update Categorie"
                        categorieName={data?.name}
                        categoriedes={data?.description}
                        userStatus={data?.status}
                        id={data?.id}
                        catorderby={data?.orderby}
                        catImage={data?.image}
                        slug={data?.slug}
                        showOnHome={data?.showOnHome}
                        warehouse={data?.warehouse?.id}
                        settings={data?.settings}
                        banner={data?.banner}
                        bannerstatus={data?.bannerstatus}
                      />
                      <Button
                        className="mt-2"
                        variant="danger"
                        onClick={async () => {
                          await dispatch(deleteCategorie(data?.id));
                          dispatch(getCategories());
                        }}
                      >
                        <i className="bi bi-trash"></i>
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Categories;
