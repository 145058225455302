import React, { useState, useEffect, useRef, Suspense } from "react";
import { useSelector } from "react-redux";
import {calculateImageSize, capitalizeText, handleContextMenu} from "../../const.js"
import Loader from "../../Components/Loader.js";

const CustomizeProduct = ({ customizeDetails, coloums, page }) => {
  const width = useRef(window.innerWidth);
  // const [loading, setLoading] = useState(true);
  const [productType, setProductType] = useState(false);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const { getSingleProductData } = useSelector((state) => state.commonReducer);
  let customerImg = customizeDetails?.[0]?.customerImg;
  let inputFields = customizeDetails?.[0]?.inputFields;
  let customBg = customizeDetails?.[0]?.productbgColor;
  let bgCustomImage = customizeDetails?.[0]?.productImg;

  const [images, setImages] = useState([]);
  //customize image setting
  const [customizeHeight, setCustomizeHeight] = useState("0px");
  const [customizeWidth, setCustomizeWidth] = useState("0px");
  useEffect(() => {
    const { cheight, cwidth } = calculateImageSize(width.current, coloums);
    setCustomizeHeight(cheight);
    setCustomizeWidth(cwidth);
  }, [width.current]);
  //onload customize img
  useEffect(() => {
    // setLoading(true)
    const loadImage = async() => {
      if (!getSingleProductData) return;
      const imageUrl = !productType
        ? getSingleProductData.productImg
        : getSingleProductData?.customizeDetails?.[
            getSingleProductData?.defaultSample
          ]?.productImg;
      if (!imageUrl) return;
      const img = new Image();
      img.src = `${imageUrl}`;
      img.onload = () => {
        setImageDimensions({
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
      };
    };
    loadImage();
    // setLoading(false)
  }, [getSingleProductData, productType]);


  useEffect(() => {
    const loadBgImage = () => {
      if (!bgCustomImage) return;

      const img = new Image();
      img.src = `${bgCustomImage}`;
      img.onload = () => {
        setImageDimensions({
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
      };
    };

    loadBgImage();
  }, [bgCustomImage]);
  return (
    <Suspense fallback={<Loader/>}>
      {
        !!inputFields ? <div
        onContextMenu={handleContextMenu} 
          style={{
            background: `${customBg}`,
            width : customizeWidth,
            height : customizeHeight,
            position: "relative",
          }}
        >
          {/* Image behind the name-slip */}
          {!!customerImg &&
            customerImg?.map((img, index) => {
              let imageSrc =
                localStorage.getItem(img.name) || `${img?.imageUrl}`;
              return (
                <div
                onContextMenu={handleContextMenu} 
                  key={index}
                  className="overflow-hidden"
                  style={{
                    position: "absolute",
                    userSelect: "auto",
                    width: img.width.endsWith("px") ? img.width : `${img.width}px`,
                    height: img.width.endsWith("px") ? img.height : `${img.height}px`,
                    display: "inline-block",
                    cursor: "move",
                    zIndex: 1,
                    transform:`translate(${img.x}px, ${img.y}px)`,
                    boxSizing: "border-box",
                    flexShrink: 0,
                  }}
                >
                  <img
                    className="w-100"
                    src={imageSrc}
                    alt={images?.[index]?._id}
                    style={{
                      position: "relative",
                      top: `${localStorage.getItem(`imageTopBottoms${index}`)}px`,
                      left: `${localStorage.getItem(`imageLeftRights${index}`)}px`,
                      transform: `rotate(${localStorage.getItem(`rotations${index}`) || 0}deg) 
                                  scale(${localStorage.getItem(`imageScales${index}`) || 1}) `,
                      objectFit: "contain",
                      height: "100%",
                      width: "100%",
                    }}
                    onContextMenu={handleContextMenu}
                  />
                </div>
              );
            })}
          <div
            className="name-slip"
            style={{
              background: `url(${bgCustomImage})`,
              width : customizeWidth,
              height : customizeHeight,
              // backgroundSize: `cover`,
              backgroundRepeat: `no-repeat`,
              backgroundSize: "contain",
              overflow: "hidden",
              position: "relative",
              zIndex: 2,
            }}
          >
            {/* input box start */}
            {!!inputFields &&
              inputFields.map((field, index) => {
                const maxLength = +field?.maxLength;
                const storedValue = localStorage.getItem(field?.label?.slug);
                let fieldValue = storedValue !== null ? storedValue : field.value;
                if (fieldValue.length > maxLength) {
                  let trimmedValue = fieldValue.substring(0, maxLength);
                  if (fieldValue[maxLength] !== ' ' && trimmedValue.lastIndexOf(' ') !== -1) {
                    trimmedValue = trimmedValue.substring(0, trimmedValue.lastIndexOf(' '));
                  }
                  fieldValue = trimmedValue;
                }
                const formattedName = capitalizeText(fieldValue);
                const fontSize = field?.style?.fontSize.endsWith("px") ? field?.style?.fontSize : `${field?.style?.fontSize}px`;
                return (
                  <div
                    key={index}
                    className="input react-draggable react-draggable-dragged"
                    style={{
                      position: "absolute",
                      userSelect: "auto",
                      color: field?.style?.color,
                      fontFamily: field?.style?.fontFamily,
                      fontWeight: field?.style?.fontWeight,
                      textAlign: field?.style?.textAlignment,
                      textTransform: field?.style?.textType,
                      transform:  `rotate(${field?.style?.rotation || 0}deg)`,
                      fontSize: fontSize,
                      width:  field?.width?.endsWith("px") ? field?.width : `${field?.width}px`,
                      display: "inline-block",
                      top: `${field?.y}px`,
                      left: `${field?.x}px`,
                      cursor: "move",
                      maxWidth: "9.0072e+15px",
                      maxHeight: "9.0072e+15px",
                      boxSizing: "border-box",
                      flexShrink: "0",
                      zIndex: 3,
                    }}
                  >
                     {formattedName ? formattedName : fieldValue}
                  </div>
                );
              })}
          </div>
        </div> : <Loader/>
      }
    
    </Suspense>
  );
};

export default CustomizeProduct;
