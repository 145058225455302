import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCategoriesFrontEnd } from "../../reducers/frontEndReducer";
import { getProducts, resetProductList } from "../../reducers/commonReducer";
import { calculateImageSize, handleContextMenu } from "../../const";

const Categories = () => {
  const dispatch = useDispatch();
  const [showAll, setShowAll] = useState(false);
  // const [pHeight, setPHeight] = useState();
  // const [pWidth, setPWidth] = useState();
  // const width = useRef(window.innerWidth);
  useEffect(() => {
    dispatch(getCategoriesFrontEnd());
  }, [dispatch]);

  const { getCatsFrontEndData } = useSelector((state) => state.frontEndReducer);

  const handleToggle = () => {
    setShowAll(!showAll);
  };
  // useEffect(() => {
  //   const { cheight, cwidth } = calculateImageSize(width.current);
  //   setPWidth(cwidth)
  //   setPHeight(cheight)
  //   },[width.current])
  // number of item before show more button
  const isMobile = window.innerWidth < 768;
  const itemsToShow = isMobile && !showAll ? 8 : getCatsFrontEndData.length;

  return (
    <section className="cat-text header-main">
    <div className="container-fluid mb-40 catagories-bg">
      <Row className="justify-content-center">
        {!!getCatsFrontEndData &&
          getCatsFrontEndData.slice(0, itemsToShow).map((cat, index) => {
            const isLastCategory = index === itemsToShow - 1;
            return (
              <Col
                xs={3}
                sm={3}
                md={1}
                lg={1}
                className={`pt-2 pb-2 align-center cat-main ${isLastCategory ? 'last-category' : ''}`}
                key={cat.id}
              >
                <div className="cat-tab">
                  <Link
                    to={`/shop/${cat.slug}?ref_=${cat.id}&catname=${cat?.name}`}
                  >
                    <div className="cat-image-section">
                      <img
                        src={cat.image}
                        alt={cat.name}
                        className="img-fluid"
                        loading="eager"
                        style={{
                          maxHeight: '85%',
                        }}
                        onContextMenu={handleContextMenu}
                      />
                    </div>
  
                    <div className="cat-text-section">
                      <p
                        style={{
                          margin: 0,
                          fontSize: '12px',
                          overflow: 'hidden',
                        }}
                      >
                        {cat.name.length > 33 ? cat.name.slice(0, 33) + "..." : cat.name}
                      </p>
                    </div>
                  </Link>
                </div>
              </Col>
            );
          })}
      </Row>
  
      
    </div>
    {isMobile && getCatsFrontEndData.length > 8 && (
        <div className="text-center mt-3 mb-3">
          <Button className="show-more-btn" onClick={handleToggle}>
          <div className="icon-circle">
          <i className={`bi ${showAll ? "bi-chevron-double-up" : "bi-chevron-double-down"} ${showAll ? "animate-up" : "animate-down"}`}></i></div>
            {showAll ? "Show Less" : "Show More"}
          </Button>
        </div>
      )}
  </section>
  
  

  );
};

export default Categories;