import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { resetUsersList, editMyProfile, zipcodeCityState, getMyProfile } from "../../../reducers/commonReducer";

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(), 
  email: yup.string().required(),
  phone: yup.string().min(10).max(10).required(),
  addressline1: yup.string().required(),
  addressline2: yup.string(),
  city: yup.string().required(),
  zipcode: yup.string().min(6).max(6).required(), 
  state: yup.string().required(),
});

function UpdateUserProfile(props) {
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const popupStatus = queryParameters.get("popup");
  const { title, getUserProfileList, apiFunction, listApi, getMyProfileData } = props;
  const [show, setShow] = useState(popupStatus ? popupStatus : false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const { zipcodeCityStateData } = useSelector(
    (state) => state.orderDetailReducer
  );
  return (
    <>
      <span className="address-edit" onClick={handleShow}>
        <i className="bi bi-pen"></i>
      </span>
      <Modal show={show} size={"lg"} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Formik
          enableReinitialize={true}
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            await dispatch(
              apiFunction ? apiFunction(values) : editMyProfile(values)
            );
            await dispatch(resetUsersList());
            setTimeout(() => {
              dispatch(listApi ? listApi() : getMyProfile());
            }, 500);
            resetForm({ values: "" });
            handleClose();
          }}
          initialValues={{
            id: getMyProfileData?.id,
            firstName: getMyProfileData?.address?.[getMyProfileData?.defaultAddress]?.firstName || '',
            lastName: getMyProfileData?.address?.[getMyProfileData?.defaultAddress]?.lastName || '',
            email: getMyProfileData?.address?.[getMyProfileData?.defaultAddress]?.email || '',
            phone: getMyProfileData?.address?.[getMyProfileData?.defaultAddress]?.phone || '',
            addressline1: getMyProfileData?.address?.[getMyProfileData?.defaultAddress]?.addressline1 || '',
            addressline2: getMyProfileData?.address?.[getMyProfileData?.defaultAddress]?.addressline2 || '',
            city: getMyProfileData?.address?.[getMyProfileData?.defaultAddress]?.city
              ? getMyProfileData?.address?.[getMyProfileData?.defaultAddress]?.city
              : zipcodeCityStateData?.City
              ? zipcodeCityStateData?.City
              : getUserProfileList?.address?.city,
            zipcode: getMyProfileData?.address?.[getMyProfileData?.defaultAddress]?.zipcode
              ? getMyProfileData?.address?.[getMyProfileData?.defaultAddress]?.zipcode
              : zipcodeCityStateData?.Pincode
              ? zipcodeCityStateData?.Pincode
              : getUserProfileList?.address?.zipcode,
            state: getMyProfileData?.address?.[getMyProfileData?.defaultAddress]?.state
              ? getMyProfileData?.address?.[getMyProfileData?.defaultAddress]?.state
              : zipcodeCityStateData?.State
              ? zipcodeCityStateData?.State
              : getUserProfileList?.address?.state,
          }}
        >
          {({ handleSubmit, handleChange, values, setFieldValue, errors }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="FirstName"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      isInvalid={!!errors.firstName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>LastName</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="LastName"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      isInvalid={!!errors.lastName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.lastName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Phone"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      isInvalid={!!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>AddressLine1</Form.Label>
                    <Form.Control
                      type="text"
                      as="textarea"
                      row={3}
                      placeholder="AddressLine1"
                      name="addressline1"
                      value={values.addressline1}
                      onChange={handleChange}
                      isInvalid={!!errors.addressline1}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.addressline1}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>AddressLine2</Form.Label>
                    <Form.Control
                      type="text"
                      as="textarea"
                      row={3}
                      placeholder="AddressLine2"
                      name="addressline2"
                      value={values.addressline2}
                      onChange={handleChange}
                      isInvalid={!!errors.addressline2}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.addressline2}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>PIN Code</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="PIN Code"
                      name="zipcode"
                      value={values.zipcode}
                      onChange={async (e) => {
                        setFieldValue("zipcode", e.target.value);
                        e.target.value?.length === 6 &&
                          dispatch(
                            zipcodeCityState({
                              zipcode: e.target.value,
                            })
                          );
                      }}
                      isInvalid={!!errors.zipcode}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.zipcode}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="City"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      isInvalid={!!errors.city}
                      disabled = {values.id ? false : true}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.city}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="State"
                      name="state"
                      value={values.state}
                      onChange={handleChange}
                      isInvalid={!!errors.state}
                      disabled = {values.id ? false : true}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.state}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
                <Button variant="danger" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default UpdateUserProfile;
