import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideMenu from "../../Components/SideMenu";
import {
  addWareHouse,
  deleteWareHouse,
  getWareHouse,
  updateWareHouse,
} from "../../reducers/commonReducer";
import { Button} from "react-bootstrap";
import { home } from "../../const";
import WarehouseAction from "./WarehouseAction";

const WarehouseList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate(home);
    }
    dispatch(getWareHouse());
  }, []);

  const { wareHouseList } = useSelector((state) => state.commonReducer);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid">
        <WarehouseAction
          title={"Add WareHouse"}
          popUpTitle="Add New WareHouse"
          api={addWareHouse}
        />
        <table className="table mt-2">
          <thead className="normalplace">
            <tr className="tables">
              <th className="col-1">Sr.</th>
              <th className="over-col-size">Name</th>
              <th className="over-col-size">Phone</th>
              <th className="over-col-size">Email</th>
              <th className="over-col-size">Address</th>
              <th className="over-col-size">Registered Name</th>
              <th className="over-col-size">Return Address</th>
              <th className="over-col-size">Status</th>
              <th className="over-col-size">Action</th>
            </tr>
          </thead>
          <tbody className="tbody-table">
            {!!wareHouseList &&
              wareHouseList?.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr key={data?._id}>
                      <td>{index + 1}.</td>
                      <td>{data?.name}</td>
                      <td>{data?.phone}</td>
                      <td>{data?.email}</td>
                      <td>{`${data?.address}, ${data?.city} - ${data?.pin}, ${data?.state}, ${data?.country}`}</td>
                      <td>{data?.registered_name}</td>
                      <td>{`${data?.return_address}, ${data?.return_city} - ${data?.return_pin}, ${data?.return_state}, ${data?.return_country}`}</td>
                      <td>{data?.status ? <span className="text-white bg-success rounded px-2 f-13"><b>Active</b></span> : <span className="text-white bg-danger rounded px-2 f-13"><b>InActive</b></span>}</td>
                      <td>
                        <WarehouseAction
                          title={<i className="bi bi-pencil-square"></i>}
                          popUpTitle="Update WareHouse"
                          api={updateWareHouse}
                          phone={data?.phone}
                          city={data?.city}
                          name={data?.name}
                          pin={data?.pin}
                          state={data?.state}
                          address={data?.address}
                          country={data?.country}
                          email={data?.email}
                          registered_name={data?.registered_name}
                          return_address={data?.return_address}
                          return_pin={data?.return_pin}
                          return_city={data?.return_city}
                          return_state={data?.return_state}
                          return_country={data?.return_country}
                          status={data?.status}
                          id={data?.id}
                        />
                        <Button
                          variant="danger"
                          className="mt-2"
                          onClick={async () => {
                            const apirsp = await dispatch(deleteWareHouse(data?.id));
                            if(apirsp?.payload?.status){
                               dispatch(getWareHouse());
                            }
                          }}
                        > <i className="bi bi-trash3-fill"></i>
                        </Button>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default WarehouseList;
