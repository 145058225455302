export const home = "/";
export const thankyou = "/thankyou";
export const checkout = "/checkout";
export const about = "/about";
export const shop = "/shop";
export const login = "/login";
export const signup = "/signup";
export const addUserAddress = "/add-user-address";
export const getUserAddress = "/user-address";
export const userDashboard = "/user-dashboard";
export const contact = "/contact";
export const dashboard = "/dashboard";
export const attributes = "/attributes";
export const roles = "/roles";
export const users = "/users";
export const vendors = "/vendors";
export const checker = "/checker";
export const manager = "/manager";
export const userCartList = "/user-cart";
export const categories = "/categories";
export const banners = "/banners";
export const vendor = "/vendor";
export const coupons = "/coupons";
export const settings = "/settings";
export const orders = "/orders";
export const adminUserAddress = "/admin-user-address";
export const pendingOrders = "/pending-orders";
export const product = "/product";
export const productLabels = "/product-labels";
export const warehouse = "/warehouse";
export const fileSettings = "/file-setting";
export const orderPdf = "/order-pdf";
export const invoicePdf = "/invoice-pdf";
export const permissions = "/permissions";
export const warranty = "/warranty";
export const reviews = "/reviews";
export const addProduct = "/add-product";
export const productList = "/product-list";
export const packageList = "/package";
export const socialMediaSetting = "/social-media-setting";
export const EmailTemplates = "/email-templates";
export const infoPages = "/pages";
export const taxClasses = "/tax-classes";
export const forgotPass = "/forgot-password";
export const phoneVerify = "/phone-verify";
export const verifyOtp = "/verify-otp";
export const newPassword = "/create-new-password";
export const connection = "/connect";
export const shippingCharges = "/shipping-charges";
export const trackers = "/trackers";
export const rewardSlab = "/reward-slab";
export const offers = "/offers";
export const codList = "/cod";
export const currentUrl = window.location.href;
//baseurl
export const baseUrl = "https://www.ranbazaar.com/";
// common Functions
export const productRating = (times) => {
  var repeatedString = "";
  while (times > 0) {
    repeatedString += "⭐";
    times--;
  }
  return (
    <ul
      dangerouslySetInnerHTML={{ __html: !!repeatedString && repeatedString }}
    />
  );
};

//image  right click disabled
export const handleContextMenu = (event) => {
  event.preventDefault();
};
//rating
export const dynamicPriceRange = (price) => {
  const keys = Object.keys(price);
  const lastKey = keys[keys.length - 1];
  const lastValue = price[lastKey];
  return lastValue;
};

export const openNewTab = (url) => {
  window.open(url, "_blank");
};
export const openTabWithRefresh = (url) => {
  window.open(url);
};
// export const handleDownload = (fileUrl) => {
//   console.log("fileUrl", fileUrl)
//   const anchor = document.createElement("a");
//   anchor.href = fileUrl;
//   anchor.download = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
//   document.body.appendChild(anchor);
//   anchor.click();
//   document.body.removeChild(anchor);
// };

export const handleDownload = (fileUrl) => {
  const anchor = document.createElement("a");
  fetch(fileUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      anchor.href = url;
      anchor.download = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(url); 
    })
    .catch((error) => console.error("Error downloading the file:", error));
};


export const convertImageToBlob = async(imageSrc) => {
  try {
    const response = await fetch(imageSrc);
    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error('Error converting image to blob:', error);
  }
}

export const calculateImageSize = (width, coloums) => {
  let cheight = "0px";
  let widthSize = "0px";

  if (width < 320) {
    if(coloums === 1){
      cheight = "300px";
      widthSize = "300px";
    }else{
      cheight = "135px";
      widthSize = "135px";
    }
  } else if (width >= 320 && width < 500) {
    const baseHeight = (coloums === 1) ? 300 : 135;
    const increment = width - 320;
    cheight = `${baseHeight + (coloums === 1 ? increment : increment / 2)}px`;
    widthSize = `${baseHeight + (coloums === 1 ? increment : increment / 2)}px`;
  } else if (width >= 500 && width < 768) {
    cheight = "215px";
    widthSize = "215px";
  } else if (width >= 768 && width < 1024) {
    cheight = "300px";
    widthSize = "300px";
  } else if (width >= 1024) {
    if (coloums === 4) {
      cheight = "305px";
      widthSize = "305px";
    } else if(coloums === 1){
      cheight = "600px";
      widthSize = "600px";
    } else {
      cheight = "370px";
      widthSize = "370px";
    }
  }

  return { cheight, cwidth: widthSize };
};
//capitalizeText
export function capitalizeText(text) {
  return text
    .toLowerCase() // Convert everything to lowercase
    .split(' ') // Split the string by spaces
    .filter(Boolean) // Remove empty spaces if there are extra spaces
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
    .join(' '); // Join the words back together
}
export const fontFamilies = [
  "Roboto",
  "Sevillana",
  "Aleo Regular",
  "Elmessiri bold",
  "Gilroy Extrabold",
  "Gilroy Light",
  "GrandHotel Regular",
  "Indigo Outline",
  "Indigo Regular",
  "KozGoPr6N Bold",
  "KozGoPr6N Regular",
  "KozGoPro Regular",
  "Lato Regular",
  "Locomotype GigaSansRegular",
  "MyriadArabic Regular",
  "MyriadHebrew Regular",
  "MyriadPro Regular",
  "NotoSansGurmukhi ExtraCondensed Regular",
  "NunitoSans Regular",
  "Oswald Regular",
  "Pluto Regular",
  "Roboto Slab Regular",
  "Trebuc",
  "Trebucbd",
  "Trebucbi",
  "Trebucit",
  "Amsterdam-1",
  "Amsterdam-2",
  "Amsterdam-3",
  "Amsterdam-4",
  "GreatVibes-Regular",
  "Allura-Regular"
];




