import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import SideMenu from "../Components/SideMenu";
import { dashboardRecord, getDashBoard } from "../reducers/commonReducer";
import { Bar, Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import {CategoryScale} from 'chart.js'; 
Chart.register(CategoryScale);

const Dashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(dashboardRecord());
  }, []);
  const { dashboardRecordData } = useSelector((state) => state.commonReducer);

  const successOrderGraph = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Success Orders",
        // data: !!monthWiseOrderDlvGrph &&
        //   Object.values(monthWiseOrderDlvGrph).map(
        //     function (data, key) {
        //      return data
        //     }
        //   ),
        data: [12, 50, 55, 100, 40],
        fill: true,
        backgroundColor: "rgb(242 68 98 / 22%)",
        borderColor: "#f24462",
        tension: 0.5,
        borderWidth: 1.5,
        pointBorderWidth:0,
        pointRadius:0
      }
    ]
  };
  // const sessionChartRef = useRef(null);
  // const salesChartRef = useRef(null);
  // const ordersChartRef = useRef(null);
  // const conversionChartRef = useRef(null);

  // const [currentIndex, setCurrentIndex] = useState(0);
  // const [startX, setStartX] = useState(0);
  // const [isDragging, setIsDragging] = useState(false);

  // const charts = useRef({});

  // useEffect(() => {
  //   const todayOrders = dashboardRecordData?.countOrders?.today || [];
  //   const yesterdayOrders = dashboardRecordData?.countOrders?.yesterday || [];

  //   const createChart = (chartRef, chartData, chartOptions) => {
  //     const ctx = chartRef.current.getContext('2d');

  //     if (charts.current[chartRef.current.id]) {
  //       charts.current[chartRef.current.id].destroy();
  //     }

  //     charts.current[chartRef.current.id] = new Chart(ctx, {
  //       type: 'line',
  //       data: chartData,
  //       options: chartOptions,
  //     });
  //   };

  //   const sessionChartData = {
  //     labels: ['00:00', '06:00', '12:00', '18:00', '24:00'],
  //     datasets: [
  //       {
  //         label: 'Sessions',
  //         data: [5, 10, 15, 7, 0],
  //         backgroundColor: 'rgba(75, 192, 192, 0.2)',
  //         borderColor: 'rgba(75, 192, 192, 1)',
  //         borderWidth: 2,
  //       },
  //       {
  //         label: 'Yesterday Sessions',
  //         data: [3, 7, 12, 5, 2],
  //         borderColor: 'rgba(75, 192, 192, 0.6)',
  //         borderDash: [5, 5],
  //         fill: false,
  //       }
  //     ],
  //   };

  //   const salesChartData = {
  //     labels: Array.from({ length: 24 }, (_, i) => `${i}:00`),
  //     datasets: [
  //       {
  //         label: 'Total Sales Today',
  //         data: todayOrders.length ? todayOrders : new Array(24).fill(1), // Fallback to zeros if no data
  //         backgroundColor: 'rgba(255, 99, 132, 0.2)',
  //         borderColor: 'rgba(255, 99, 132, 1)',
  //         borderWidth: 2,
  //       },
  //       {
  //         label: 'Total Sales Yesterday',
  //         data: yesterdayOrders.length ? yesterdayOrders : new Array(24).fill(0),
  //         borderColor: 'rgba(255, 99, 132, 0.6)',
  //         borderDash: [5, 5],
  //         fill: false,
  //       },
  //     ],
  //   };

  //   const ordersChartData = {
  //     labels: ['00:00', '06:00', '12:00', '18:00', '24:00'],
  //     datasets: [
  //       {
  //         label: 'Total Orders Today',
  //         data: [1, 3, 2, 0, 0],
  //         backgroundColor: 'rgba(54, 162, 235, 0.2)',
  //         borderColor: 'rgba(54, 162, 235, 1)',
  //         borderWidth: 2,
  //       },
  //       {
  //         label: 'Total Orders Yesterday',
  //         data: [0, 1, 2, 2, 1],
  //         borderColor: 'rgba(54, 162, 235, 0.6)',
  //         borderDash: [5, 5],
  //         fill: false,
  //       },
  //     ],
  //   };

  //   const conversionChartData = {
  //     labels: ['00:00', '06:00', '12:00', '18:00', '24:00'],
  //     datasets: [
  //       {
  //         label: 'Conversion Rate',
  //         data: [1.2, 2.3, 3.5, 4.1, 4.5],
  //         backgroundColor: 'rgba(255, 206, 86, 0.2)',
  //         borderColor: 'rgba(255, 206, 86, 1)',
  //         borderWidth: 2,
  //       },
  //       {
  //         label: 'Yesterday Conversion Rate',
  //         data: [1.0, 2.0, 3.2, 3.8, 4.0],
  //         borderColor: 'rgba(255, 206, 86, 0.6)',
  //         borderDash: [5, 5],
  //         fill: false,
  //       },
  //     ],
  //   };

  //   createChart(sessionChartRef, sessionChartData, { scales: { y: { beginAtZero: true } } });
  //   createChart(salesChartRef, salesChartData, { scales: { y: { beginAtZero: true } } });
  //   createChart(ordersChartRef, ordersChartData, { scales: { y: { beginAtZero: true } } });
  //   createChart(conversionChartRef, conversionChartData, { scales: { y: { beginAtZero: true } } });

  // }, [dashboardRecordData]);

  // const handleTouchStart = (e) => {
  //   setStartX(e.touches[0].clientX);
  //   setIsDragging(true);
  // };

  // const handleTouchMove = (e) => {
  //   if (!isDragging) return;
  //   const currentX = e.touches[0].clientX;
  //   const diffX = startX - currentX;

  //   if (diffX > 50) {
  //     setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, 3));
  //     setIsDragging(false);
  //   } else if (diffX < -50) {
  //     setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  //     setIsDragging(false);
  //   }
  // };

  // useEffect(() => {
  //   document.querySelector('.slides').style.transform = `translateX(${-currentIndex * 100}%)`;
  // }, [currentIndex]);
  return (
    <>
      <div className="dboard">
        <SideMenu />
        <div className="mt-extra content container-fluid">
          <Row className="mb-3 ">
            <b>
              <h1>Dashboard</h1>
            </b>
            <div className="dashboard-bottom"></div>
          </Row>
          {/* <Row className="mb-3">
            <Col md={6}>
              <Line data={successOrderGraph} />
            </Col>
          </Row> */}
          <Row>
            <Col md={4}>
              <div className="cards order-card shadow mb-3 animated-card">
                <div className="cards-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="cards-title fs-5">
                      <i className="fas fa-shopping-cart icon-glow"></i> Today
                      Success Orders
                    </p>
                  </div>
                  <div>
                    <h2>{dashboardRecordData?.countSuccessOrders?.today}</h2>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={4}>
              <div className="cards order-card shadow mb-3 animated-card">
                <div className="cards-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="cards-title fs-5">
                      <i className="fas fa-calendar-week icon-glow"></i> This
                      Week Success Orders
                    </p>
                  </div>
                  <div>
                    <h2>{dashboardRecordData?.countSuccessOrders?.thisWeek}</h2>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={4}>
              <div className="cards order-card shadow mb-3 animated-card">
                <div className="cards-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="cards-title fs-5">
                      <i className="fas fa-clipboard-list icon-glow"></i> Total
                      Success Orders
                    </p>
                  </div>
                  <div>
                    <h2>{dashboardRecordData?.countSuccessOrders?.totals}</h2>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <div className="cards payment-card shadow mb-3 animated-card">
                <div className="cards-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="cards-title fs-5">
                      <i className="fas fa-dollar-sign icon-glow"></i> Today
                      Pending Order
                    </p>
                  </div>
                  <div>
                    <h2>
                      {dashboardRecordData?.countPendingOrders?.today || 0}
                    </h2>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={4}>
              <div className="cards payment-card shadow mb-3 animated-card">
                <div className="cards-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="cards-title fs-5">
                      <i className="fas fa-calendar-week icon-glow"></i> This
                      Week Pending Order
                    </p>
                  </div>
                  <div>
                    <h2>
                      {dashboardRecordData?.countPendingOrders?.thisWeek || 0}
                    </h2>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={4}>
              <div className="cards payment-card shadow mb-3 animated-card">
                <div className="cards-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="cards-title fs-5">
                      <i className="fas fa-wallet icon-glow"></i> Total Pending

                      Order
                    </p>
                  </div>
                  <div>
                    <h2>
                      {dashboardRecordData?.countPendingOrders?.totals || 0}
                    </h2>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <div className="cards customer-card shadow mb-3 animated-card">
                <div className="cards-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="cards-title fs-5">
                      <i className="fas fa-user icon-glow"></i> Today Customer
                    </p>
                  </div>
                  <div>
                    <h2>{dashboardRecordData?.countCustomers?.today}</h2>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={4}>
              <div className="cards customer-card shadow mb-3 animated-card">
                <div className="cards-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="cards-title fs-5">
                      <i className="fas fa-users icon-glow"></i> This Week
                      Customer
                    </p>
                  </div>
                  <div>
                    <h2>{dashboardRecordData?.countCustomers?.thisWeek}</h2>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={4}>
              <div className="cards customer-card shadow mb-3 animated-card">
                <div className="cards-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="cards-title fs-5">
                      <i className="fas fa-address-book icon-glow"></i> Total
                      Customer
                    </p>
                  </div>
                  <div>
                    <h2>{dashboardRecordData?.countCustomers?.totals}</h2>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <div className="cards completed-order-card shadow mb-3 animated-card">
                <div className="cards-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="cards-title fs-5">
                      <i className="fas fa-check-circle icon-glow"></i> Today
                      Prepaid Orders
                    </p>
                  </div>
                  <div>
                    <h2>{dashboardRecordData?.countPrepaidOrders?.today}</h2>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={4}>
              <div className="cards completed-order-card shadow mb-3 animated-card">
                <div className="cards-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="cards-title fs-5">
                      <i className="fas fa-calendar-week icon-glow"></i> This
                      Week Prepaid Orders
                    </p>
                  </div>
                  <div>
                    <h2>{dashboardRecordData?.countPrepaidOrders?.thisWeek}</h2>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={4}>
              <div className="cards completed-order-card shadow mb-3 animated-card">
                <div className="cards-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="cards-title fs-5">
                      <i className="fas fa-clipboard-check icon-glow"></i> Total
                      Prepaid Orders
                    </p>
                  </div>
                  <div>
                    <h2>{dashboardRecordData?.countPrepaidOrders?.totals}</h2>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <div className="cards completed-order-card shadow mb-3 animated-card">
                <div className="cards-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="cards-title fs-5">
                      <i className="fas fa-check-circle icon-glow"></i> Today
                      COD Orders
                    </p>
                  </div>
                  <div>
                    <h2>{dashboardRecordData?.countCodOrders?.today}</h2>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={4}>
              <div className="cards completed-order-card shadow mb-3 animated-card">
                <div className="cards-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="cards-title fs-5">
                      <i className="fas fa-calendar-week icon-glow"></i> This
                      Week COD Orders
                    </p>
                  </div>
                  <div>
                    <h2>{dashboardRecordData?.countCodOrders?.thisWeek}</h2>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={4}>
              <div className="cards completed-order-card shadow mb-3 animated-card">
                <div className="cards-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="cards-title fs-5">
                      <i className="fas fa-clipboard-check icon-glow"></i> Total
                      COD Orders
                    </p>
                  </div>
                  <div>
                    <h2>{dashboardRecordData?.countCodOrders?.totals}</h2>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <div className="cards completed-order-card shadow mb-3 animated-card">
                <div className="cards-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="cards-title fs-5">
                      <i className="fas fa-check-circle icon-glow"></i> Today Paid Amount
                    </p>
                  </div>
                  <div>
                    <h2>{dashboardRecordData?.countpayments?.today}/-</h2>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={4}>
              <div className="cards completed-order-card shadow mb-3 animated-card">
                <div className="cards-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="cards-title fs-5">
                      <i className="fas fa-calendar-week icon-glow"></i> This
                      Week Paid Amount
                    </p>
                  </div>
                  <div>
                    <h2>{dashboardRecordData?.countpayments?.thisWeek}/-</h2>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={4}>
              <div className="cards completed-order-card shadow mb-3 animated-card">
                <div className="cards-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="cards-title fs-5">
                      <i className="fas fa-clipboard-check icon-glow"></i> Total Paid
                      Amount
                    </p>
                  </div>
                  <div>
                    <h2>{dashboardRecordData?.countpayments?.totals}/-</h2>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
