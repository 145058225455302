import React, { useState } from "react";
import { Form, Row, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { signUpWithPhone, zipcodeCityState, zipCodeVerify } from "../../../reducers/commonReducer";

const schema = yup.object().shape({
  pincode: yup
    .string()
    .required("PIN Code is required")
    .matches(/^\d{6}$/, "PIN Code must be exactly 6 digits"),
});
function PinCodeServiceAreaVerify({ onSignupStatusChange }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const updateUser = async (values) => {
    setLoading(true);
    const apiResp = await dispatch(zipCodeVerify(values));
    setLoading(false);
    if (apiResp?.payload?.status) {
    dispatch(zipcodeCityState({"zipcode" : values?.pincode}))
    onSignupStatusChange(apiResp?.payload?.status);
    }
  };

  return (
    <>
      <Formik
        validationSchema={schema}
        onSubmit={async (values) => {
          await updateUser(values);
        }}
        initialValues={{
          pincode: localStorage.getItem("pincode") === "undefined" ? null : localStorage.getItem("pincode"),
        }}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <Form
            className="border-danger mb-2"
            onSubmit={handleSubmit}
          >
            <div className="d-flex align-items-end">
              <span><b>Delivery: </b></span>
              <div className="d-flex align-items-end ml-2 custom-input-main">
              <i className="bi bi-geo-alt-fill text-danger"></i>
              <input
                  type="text"
                  className="custom-input ml-2"
                  placeholder="Enter Delivery Pincode"
                  name="pincode"
                  value={values.pincode}
                  onChange={handleChange}
                  isInvalid={!!errors.pincode}
                />
              {loading ? (
              <Spinner className="ml-2" variant="success" animation="grow" />
            ) : (
              <button className="ml-2 text-success bg-none" type="submit">
                <b>Check</b>
              </button>
            )}
           
              </div>
            </div>
            <span className={errors.pincode ? "text-danger" : 'text-success'}>
            <b>
              {[
                localStorage.getItem('city') !== "undefined" && localStorage.getItem('city'),
                localStorage.getItem('pincode') !== "undefined" && localStorage.getItem('pincode')
              ]
                .filter(Boolean)
                .join(" - ") + 
                (localStorage.getItem('state') !== "undefined" && localStorage.getItem('state') 
                  ? `, ${localStorage.getItem('state')}` 
                  : "")
              }
            </b>
            </span>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default PinCodeServiceAreaVerify;
