import React, { useState } from "react";
import Footer from "../../../Sub/Footer";
import CommonHeader from "../../../Sub/CommonHeader";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addCart, addCartUploadImg, getCartlist, otpVerification, sendOtp } from "../../../../reducers/commonReducer";
import { addUserAddress, checkout, home } from "../../../../const";
import GotoTop from "../../../../Components/GotoTop";

const schema = yup.object().shape({
  phone: yup.string().required(), 
  otp: yup.string().required(),
});

const VerifyOtp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { getSingleProductData } = useSelector(
    (state) => state.commonReducer
  );

const userImages = Array.from({ length: +localStorage.getItem("customerImgLength") || 0 }) 
  .map((_, index) => {
    const key = `image${index + 1}`;
    const imageUrl = localStorage.getItem(key);
    return {
      key,
      imageUrl,
    };
  })
  .filter(({ imageUrl }) => imageUrl !== null); 

  return (
    <>
      <CommonHeader />
       <div className="container-fluid">
        <div className="page-main p-5 mbl-pedding-10">
          <div className="section__head d-md-flex justify-content-between mb-40">
            <div className="section__title">
              <h1>Verify OTP</h1>
            </div>
          </div>
          <Row>
            <Col md={8} className="m-auto text-center">
              <p className="text-danger">
                <b>Note:</b> We have sent OTP to your registered Phone. Kindly
                enter OTP to proceed.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="m-auto">
              <Formik
                validationSchema={schema}
                onSubmit={async (values, { resetForm }) => {
                  setLoading(true)
                  const apiResp = await dispatch(otpVerification(values));
                  if(apiResp?.payload?.status){
                    navigate(apiResp?.payload?.addresStatus ? checkout : localStorage.getItem("quantity") ? addUserAddress : home);
                    if(localStorage.getItem("quantity")){
                    const cartResp = await dispatch(
                        addCart({
                          product: localStorage.getItem("product"),
                          quantity: localStorage.getItem("quantity"),
                          // userimg: JSON.parse(localStorage.getItem("userimg")),
                          inputFields: JSON.parse(localStorage.getItem("inputFields")),
                          attributeid: localStorage.getItem("attributeid") !== "undefined" ? localStorage.getItem("attributeid") : '',
                        })
                      )
                    if (cartResp?.payload?.status) {
                      if(getSingleProductData?.productType === "customize"){
                      const uploadimgRsp = await dispatch(
                          addCartUploadImg({
                            captureImageHtml: localStorage.getItem("captureImageHtml"),
                            userimg: userImages,
                            id: cartResp?.payload?.cartid,
                            height: localStorage.getItem("printHeight"),
                            width: localStorage.getItem("printWidth"),
                            fontFamilies: JSON.parse(localStorage.getItem("fontFamilies"))
                          })
                        );
                        if(uploadimgRsp?.payload?.status){
                          localStorage.removeItem("captureImageHtml");
                          localStorage.removeItem("product");
                          localStorage.removeItem("quantity");
                          localStorage.removeItem("userimg");
                          localStorage.removeItem("inputFields");
                          localStorage.removeItem("printWidth");
                          localStorage.removeItem("printHeight")
                        }
                      }else{
                        localStorage.removeItem("captureImageHtml");
                        localStorage.removeItem("product");
                        localStorage.removeItem("quantity");
                        localStorage.removeItem("userimg");
                        localStorage.removeItem("inputFields");
                        localStorage.removeItem("printWidth");
                        localStorage.removeItem("printHeight")
                      }
                      dispatch(getCartlist())
                    }
                  }
                  }
                  setLoading(false)
                }}
                initialValues={{
                  phone: localStorage.getItem("phone"),
                  otp: "",
                }}
              >
                {({ handleSubmit, handleChange, touched, values, errors }) => (
                  <Form className="container" onSubmit={handleSubmit}>
                    <Row className="mb-3">
                      <Col md={12} sm={12}>
                        <Form.Group controlId="validationFormik01">
                          <Form.Label>Enter OTP</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Please Enter OTP"
                            name="otp"
                            value={values.otp}
                            onChange={handleChange}
                            isInvalid={!!errors.otp}
                            className={errors.otp && touched.otp && "errors"}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.otp}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col><p className="mb-0">
                      Didn't receive the OTP?
                        <span onClick={() => dispatch(sendOtp({
                        phone: localStorage.getItem("phone")
                      }))}>
                    Resend OTP</span></p></Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={12} sm={12}>
                        <Button
                          variant="success"
                          className="bg-thememain w-100"
                          type="submit"
                          disabled = {loading ? true : false}
                        >
                          Verify OTP
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </div>
      </div>
      <GotoTop />
      <Footer />
    </>
  );
};

export default VerifyOtp;
