import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Form, ProgressBar, Row, Spinner } from "react-bootstrap";
import { decode } from "tiff";
import CommonHeader from "./CommonHeader";
import uploadimage from "../../images/uploadimage.jpg";
import Footer from "./Footer";
import heic2any from "heic2any";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  addCart,
  addCartUploadImg,
  detailsCustomizeProductsImg,
  getCartlist,
  getSingleProduct,
  resetCustomizeProductsImgDetails,
} from "../../reducers/commonReducer";
import { useNavigate, useParams } from "react-router-dom";
import ProductTab from "./ProductTab";
import {
  calculateImageSize,
  capitalizeText,
  checkout,
  handleContextMenu,
  home,
  orders,
  phoneVerify,
  productRating,
  baseUrl,
  userDashboard,
} from "../../const";
import {
  getProductReviews,
  resetAdminOrderList,
  singleOrderItem,
  updateOrderItem,
} from "../../reducers/orderDetailsReducer";
import PinCodeServiceAreaVerify from "../Main/user/PinCodeServiceAreaVerify";
import ProductMultiSlider from "./ProductMultiSlider";
import Loader from "../../Components/Loader";
import GotoTop from "../../Components/GotoTop";
import PrintImage from "./PrintImage";
import { toast, ToastContainer, Zoom } from "react-toastify";

const ProductsDetails = () => {
  const width = useRef(window.innerWidth);
  const divRef = useRef(null);
  let params = useParams();
  const queryParameters = new URLSearchParams(window.location.search);
  const orderid = queryParameters.get("orderid");
  const itemIndex = queryParameters.get("itemIndex");
  const updateOrderProductType = queryParameters.get("productType");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [productType, setProductType] = useState(
    orderid ? /true/.test(updateOrderProductType) : false
  );
  // const [productType, setProductType] = useState(false);
  
  const [uploadProgress, setUploadProgress] = useState({});
  const [socialMedia, setSocialMedia] = useState(null);
  const [mobileProductFixIndex, setMobileProductFixIndex] = useState();
  const [capture, setCapture] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [buyNow, setBuyNow] = useState(false);
  const [activeSample, setActiveSample] = useState(0);
  const [orderQty, setOrderQty] = useState(1);
  const [inputFields, setInputFields] = useState([]);
  const [qtyWiseDis, setQtyWiseDis] = useState(null);
  const [isLogin, setIsLogin] = useState(0);
  const [productAttributes, setProductAttributes] = useState();
  const [bodyContent , setBodyContent ] = useState();
  const [productAttributesPrice, setProductAttributesPrice] = useState();
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [customizeHeight, setCustomizeHeight] = useState("0px");
  const [customizeWidth, setCustomizeWidth] = useState("0px");
  const [loading, setLoading] = useState(true);
  const [cartLoader, setCartLoader] = useState(false);
  const [customizeProduct, setCustomizeProduct] = useState(true);
  const [images, setImages] = useState([]);

useEffect(() => {
  return () => {
    // Dispatch the reset action when the component unmounts
    dispatch(resetCustomizeProductsImgDetails());
  };
}, [dispatch]);

  //GET PRODUCTS
  useEffect(() => {
    const { cheight, cwidth } = calculateImageSize(width.current, 1);
    setLoading(true);
    const productData = async () => {
      // dispatch(resetCustomizeProductsImgDetails())
      const apiResp = await dispatch(
        getSingleProduct({
          slug: params?.slug,
          width: cwidth,
          height: cheight,
          // width: "1051px",
          // height:'676px'
        })
      );
      if (apiResp?.payload?.status && apiResp?.payload?.product) {
        const timeout = setTimeout(() => {
          setLoading(false);
          setSocialMedia(apiResp?.payload?.product)
        }, 200); 
        return () => clearTimeout(timeout);
      }
    };
    productData();
    setCustomizeHeight(cheight);
    setCustomizeWidth(cwidth);
  }, [width.current]);
  //update order
  useEffect(() => {
    if (orderid) {
      dispatch(singleOrderItem({ id: orderid, index: +itemIndex }));
      // setCustomizeProduct(false);
    }
  }, [orderid]);
  const { getSingleProductData, customizeProductsImgDetails } = useSelector(
    (state) => state.commonReducer
  );
  const { singleOrderItemData } = useSelector(
    (state) => state.orderDetailReducer
  );
  //uploaded images
const userImages = Array.from({
  length:
    (!!getSingleProductData &&
      getSingleProductData?.customizeDetails?.[
        getSingleProductData?.defaultSample
      ]?.customerImg?.length) ||
    0,
}).map((_, index) => {
  const key = `image${index + 1}`;
  const imageUrl = localStorage.getItem(key);
  return {
    key,
    imageUrl,
  };
})
.filter(({ imageUrl }) => imageUrl !== null); 
useEffect(() => {
  setImages(userImages)
},[getSingleProductData?.customizeDetails?.[
  getSingleProductData?.defaultSample
]?.customerImg])

  let customerImg = productType
    ? getSingleProductData?.customizeDetails?.[
        getSingleProductData?.defaultSample
      ]?.customerImg
    : customizeProductsImgDetails?.customerImg ||
      getSingleProductData?.customizeDetails?.[
        getSingleProductData?.productType === "customize"
          ? 0
          : getSingleProductData?.defaultImage
      ]?.customerImg;
  const customBg = productType
    ? getSingleProductData?.customizeDetails?.[
        getSingleProductData?.defaultSample
      ]?.productbgColor
    : customizeProductsImgDetails?.productbgColor ||
      getSingleProductData?.customizeDetails?.[
        getSingleProductData?.productType === "customize"
          ? 0
          : getSingleProductData?.defaultImage
      ]?.productbgColor;
  // const bgCustomImage = productType
  //   ? getSingleProductData?.customizeDetails?.[
  //       getSingleProductData?.defaultSample
  //     ]?.productImg
  //   : customizeProductsImgDetails?.productImg ||
  //     getSingleProductData?.customizeDetails?.[
  //       getSingleProductData?.productType === "customize"
  //         ? 0
  //         : getSingleProductData?.defaultImage
  //     ]?.productImg;

  const bgCustomImage = productType
  ? getSingleProductData?.customizeDetails?.[
      getSingleProductData?.defaultSample
    ]?.productImg
  : customizeProductsImgDetails?.productImg ||
    getSingleProductData?.customizeDetails?.[getSingleProductData?.defaultImage]?.productImg;

  // const bgCustomImage = productType
  // ? getSingleProductData?.customizeDetails?.[
  //     getSingleProductData?.defaultSample
  //   ]?.productImg
  // : customizeProductsImgDetails?.productImg;
  //   console.log("ewfwef", customizeProductsImgDetails)

  //user upload image control
  const initialArray = Array(customerImg?.length).fill(0);
  const initialScalesArray = Array(customerImg?.length).fill(1);
  const [rotations, setRotations] = useState(initialArray);
  const [imageScales, setImageScales] = useState(initialScalesArray);
  const [imageLeftRights, setImageLeftRights] = useState(initialArray);
  const [imageTopBottoms, setImageTopBottoms] = useState(initialArray);
  useEffect(() => {
    const initialRotations = [];
    const initialScales = [];
    const initialLeftRights = [];
    const initialTopBottoms = [];

    customerImg?.forEach((_, index) => {
      initialRotations[index] =
        parseFloat(localStorage.getItem(`rotations${index}`)) || 0;
      initialScales[index] =
        parseFloat(localStorage.getItem(`imageScales${index}`)) || 1;
      initialLeftRights[index] =
        parseFloat(localStorage.getItem(`imageLeftRights${index}`)) || 0;
      initialTopBottoms[index] =
        parseFloat(localStorage.getItem(`imageTopBottoms${index}`)) || 0;
    });

    setRotations(initialRotations);
    setImageScales(initialScales);
    setImageLeftRights(initialLeftRights);
    setImageTopBottoms(initialTopBottoms);
  }, [customerImg?.length]);

  // Effect to update localStorage whenever states change
  useEffect(() => {
    rotations.forEach((rotation, index) => {
      localStorage.setItem(`rotations${index}`, rotation);
    });
    imageScales.forEach((scale, index) => {
      localStorage.setItem(`imageScales${index}`, scale);
    });
    imageLeftRights.forEach((leftRight, index) => {
      localStorage.setItem(`imageLeftRights${index}`, leftRight);
    });
    imageTopBottoms.forEach((topBottom, index) => {
      localStorage.setItem(`imageTopBottoms${index}`, topBottom);
    });
  }, [rotations, imageScales, imageLeftRights, imageTopBottoms]);

  const handleRotation = (index, delta) => {
    setRotations((prevRotations) => {
      const newRotations = [...prevRotations];
      const currentRotation = isNaN(newRotations[index])
        ? 0
        : newRotations[index];
      newRotations[index] = currentRotation + delta;
      return newRotations;
    });
  };
  const handleZoom = (index, delta) => {
    setImageScales((prevScales) => {
      const newScales = [...prevScales];
      const currentScale = isNaN(newScales[index]) ? 1 : newScales[index];
      newScales[index] = Math.max(0.1, currentScale + delta);
      return newScales;
    });
  };
  const handleLeftRight = (index, delta) => {
    setImageLeftRights((prevLeftRights) => {
      const newLeftRights = [...prevLeftRights];
      const currentRecord = isNaN(newLeftRights[index])
        ? 0
        : newLeftRights[index];
      newLeftRights[index] = currentRecord + delta;
      return newLeftRights;
    });
  };
  const handleTopBottom = (index, delta) => {
    setImageTopBottoms((prevTopBottoms) => {
      const newTopBottoms = [...prevTopBottoms];
      const currentRecord = isNaN(newTopBottoms[index])
        ? 0
        : newTopBottoms[index];
      newTopBottoms[index] = currentRecord + delta;
      return newTopBottoms;
    });
  };
  //update order end
  useEffect(() => {
    if (getSingleProductData?.id) {
      dispatch(resetAdminOrderList());
      dispatch(
        getProductReviews({ product: getSingleProductData?.id, page: 1 })
      );
    }
    setActiveSample(0);
  }, [getSingleProductData]);

  useEffect(() => {
    if (!orderid) {
      const { cheight, cwidth } = calculateImageSize(width.current, 1);
      dispatch(
        detailsCustomizeProductsImg({
          productid: getSingleProductData?.id,
          index: 0,
          width: cwidth,
          height: cheight,
        })
      );
    }
  }, [getSingleProductData, width.current]);

  const handleFileChange = async (files, index, imgName) => {
    if (files) {
      const file = files[0];
      // File size limit (10MB)
    
      let oneMb = 1 * 1024 * 1024;

      const maxSize = 20 * oneMb; // 10MB

      if (file?.size > maxSize) {
        alert("File size exceeds 20MB limit. Please upload a smaller file.");
        return; // Stop further processing
      }
      let lesssRatio = 0.9;
      if (file?.size <= (10 * oneMb) && file?.size > (5 * oneMb)) {
        lesssRatio = 0.5;
      } else if (file?.size <= (20 * oneMb) && file?.size > (10 * oneMb)) {
          lesssRatio = 0.3;
      }
      const reader = new FileReader();
      let progress = 0;
      // Simulate upload progress
      const simulateProgress = setInterval(() => {
        if (progress < 100) {
          progress += 10; // Increment the progress by 10%
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [index]: progress,
          }));
        }
      }, 470); // Simulate progress every 200ms
      reader.onloadend = async () => {
        const img = new Image();
        // Handle TIFF files
        if (file.type === "image/tiff" || file.type === "image/tif") {
          try {
            const tiffArrayBuffer = reader.result; // Result is an ArrayBuffer
            const decodedTiff = decode(tiffArrayBuffer); // Decode the TIFF image using tiff.js

            if (decodedTiff.length > 0) {
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");

              // Set canvas dimensions to match TIFF image size
              canvas.width = decodedTiff[0].width;
              canvas.height = decodedTiff[0].height;

              // Put the image data onto the canvas
              const imgData = ctx.createImageData(
                decodedTiff[0].width,
                decodedTiff[0].height
              );
              imgData.data.set(decodedTiff[0].data);
              ctx.putImageData(imgData, 0, 0);

              // Convert the canvas to a JPEG or PNG
              const dataURL = canvas.toDataURL("image/jpeg");
              img.src = dataURL;
              img.onload = () => processImage(img, index, imgName, lesssRatio);
            }
          } catch (error) {
            console.error("Error processing TIFF image:", error);
          }
        } else if (
          file.type === "image/heic" ||
          file.type === "image/heif" ||
          file.type === ""
        ) {
          // Handle HEIC conversion to JPEG
          try {
            const convertedBlob = await heic2any({
              blob: file,
              toType: "image/webp", // Convert HEIC to JPEG
            });

            const convertedReader = new FileReader();
            convertedReader.onloadend = () => {
              const base64String = convertedReader.result.split(",")[1];
              const dataURL = `data:image/webp;base64,${base64String}`;
              img.src = dataURL;
              img.onload = () => processImage(img, index, imgName, lesssRatio);
            };
            convertedReader.readAsDataURL(convertedBlob); // Read the converted file as a Data URL
          } catch (error) {
            console.error("Error converting HEIC image:", error);
          }
        } else if (file.type.startsWith("image/")) {
          // Handle other image types (JPEG, PNG, GIF, etc.)
          const base64String = reader.result.split(",")[1];
          const dataURL = `data:${file.type};base64,${base64String}`;
          img.src = dataURL;

          img.onload = () => processImage(img, index, imgName, lesssRatio);
        } else {
          alert("Unsupported file type. Please upload an image.");
        }
        clearInterval(simulateProgress);
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [index]: 100, // Set progress to 100% on completion
        }));
      };

      // For TIFF files, read the file as an ArrayBuffer
      if (file.type === "image/tiff" || file.type === "image/tif") {
        reader.readAsArrayBuffer(file);
      } else {
        // For other image types, read as DataURL
        reader.readAsDataURL(file);
      }
    }
  };

  // Helper function to process and compress the image
  const processImage = (img, index, imgName, lesssRatio) => {
    // Create a canvas element to compress the image
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    // Set the desired max width for the compressed image
    const maxWidth = 800;
    const scaleSize = maxWidth / img.width;
    canvas.width = maxWidth;
    canvas.height = img.height * scaleSize;
    // Draw the image on the canvas with the new dimensions
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    // Convert the canvas to a compressed Base64 string in JPEG format
    const compressedBase64 = canvas.toDataURL("image/jpeg", lesssRatio); // 70% quality
    // Save the compressed image to localStorage
    localStorage.setItem(imgName, compressedBase64);
    // Create a new image object with the compressed data
    const newImage = {
      id: Date.now(),
      imageUrl: compressedBase64,
    };
    // Update state with the new compressed image
    setImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages[index] = newImage;
      return updatedImages;
    });
  };
  const handleDeleteImage = (index) => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages[index] = null;
      return updatedImages;
    });
  };
  //islogin status
  const handleSignupStatusChange = (status) => {
    setIsLogin(status);
  };
  // input fields
  useEffect(() => {
    if (singleOrderItemData) {
      // Case when order data exists, use data from singleOrderItemData
      setInputFields(singleOrderItemData?.inputFields);
      setImages(singleOrderItemData?.userimg);
      setOrderQty(singleOrderItemData?.quantity);
    } else {
      // Case when no order data, use default input fields
      const initialInputFields = productType
        ? getSingleProductData?.customizeDetails?.[
            getSingleProductData?.defaultSample
          ]?.inputFields
        : customizeProductsImgDetails?.inputFields ||
          getSingleProductData?.customizeDetails?.[0]?.inputFields ||
          [];

      // Update input fields with values from localStorage if available
      const updatedInputFields = initialInputFields?.map((field) => {
        const storedValue = localStorage.getItem(field.label?.slug);
        return {
          ...field,
          value: storedValue !== null ? storedValue : field.value,
        };
      });

      setInputFields(updatedInputFields);
    }
  }, [
    singleOrderItemData,
    productType,
    customizeProductsImgDetails,
    getSingleProductData?.customizeDetails,
  ]);

  //mpbile view ui
  useEffect(() => {
    const initialInputFields = productType
      ? getSingleProductData?.customizeDetails?.[
          getSingleProductData?.defaultSample
        ]?.inputFields
      : customizeProductsImgDetails?.inputFields ||
        getSingleProductData?.customizeDetails?.[0]?.inputFields ||
        [];
    // Update input fields with values from localStorage if available
    const updatedInputFields = initialInputFields?.map((field) => {
      const storedValue = localStorage.getItem(field.label?.slug);
      return {
        ...field,
        value: storedValue !== null ? storedValue : field.value,
      };
    });
    setInputFields(updatedInputFields);
  }, [
    customizeProductsImgDetails,
    productType,
    getSingleProductData?.customizeDetails,
  ]);
//input field
  const handleChange = (index, event, label, duplicateIndices) => {
    const newValue = event.target.value;
    const nonEmojiRegex = /^[\u0020-\u007E\u00A0-\u00FF]*$/;
    if (nonEmojiRegex.test(newValue)) {
      const newInputFields = inputFields.map((field, i) => {
        if (i === index || duplicateIndices.includes(i)) {
          return { ...field, value: newValue };
        }
        return field;
      });
      setInputFields(newInputFields);
      localStorage.setItem(`${label?.slug}`, newValue);
    }
  };
  
  // const handleChange = (index, event, label, duplicateIndices) => {
  //   const newValue = event.target.value;
  //   const newInputFields = inputFields.map((field, i) => {
  //     if (i === index || duplicateIndices.includes(i)) {
  //       return { ...field, value: newValue };
  //     }
  //     return field;
  //   });
  //   setInputFields(newInputFields);
  //   localStorage.setItem(`${label?.slug}`, newValue);
  // };

  //onload customize img
  useEffect(() => {
    const loadImage = () => {
      if (!getSingleProductData) return;
      const imageUrl = !productType
        ? getSingleProductData.productImg
        : getSingleProductData?.customizeDetails?.[
            getSingleProductData?.defaultSample
          ]?.productImg;
      if (!imageUrl) return;
      const img = new Image();
      img.src = `${imageUrl}`;
      img.onload = () => {
        setImageDimensions({
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
      };
    };

    loadImage();
  }, [getSingleProductData, productType]);

  //on load customize image
  useEffect(() => {
    const loadImage = () => {
      if (!getSingleProductData) return;

      const imageUrl = !productType
        ? getSingleProductData.productImg
        : getSingleProductData?.customizeDetails?.[
            getSingleProductData?.defaultSample
          ]?.productImg;

      if (!imageUrl) return;

      const img = new Image();
      img.src = `${imageUrl}`;
      img.onload = () => {
        setImageDimensions({
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
      };
    };

    loadImage();
  }, [getSingleProductData, productType]);

  useEffect(() => {
    const loadBgImage = () => {
      if (!bgCustomImage) return;

      const img = new Image();
      img.src = `${bgCustomImage}`;
      img.onload = () => {
        setImageDimensions({
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
      };
    };

    loadBgImage();
  }, [bgCustomImage]);

  //Product Add To Cart
  const addToCart = async (typeOfProduct, redirectionUrls, customerImgLength, slug) => {
    const fontFamilies = inputFields?.map(item => item?.style?.fontFamily);
    const requiredFields = inputFields.filter((input) => input.required);
    const isValid = requiredFields.every((input) => input.value.trim() !== "");
    if (!isValid) {
      alert("Please fill in all required fields.");
      setCapture(false);
      setBuyNow(false);
      return;
    }
    let apiResp;
    const cartList = (apiResp) => {
      if (apiResp) {
        dispatch(getCartlist());
        if (redirectionUrls === 1) {
          localStorage.getItem("slug") === "admin" ? navigate(orders) : navigate(`${userDashboard}?eventKeytab=second`);
        } else if (redirectionUrls === 2) {
          // navigate(shop);
        } else if (redirectionUrls === 3) {
          localStorage.getItem("slug") === "admin" ? navigate(orders) : navigate(checkout);
        } else {
          navigate(home);
        }
      }
    };
 
    if (!localStorage.getItem("x-auth-token")) {
      localStorage.setItem("captureImageHtml", getSingleProductData?.productType === "normal" ? getSingleProductData?.images?.[0] : bodyContent);
      localStorage.setItem("product", getSingleProductData?.id);
      localStorage.setItem("attributeid", productAttributes);
      localStorage.setItem("quantity", orderQty);
      localStorage.setItem("userimg", JSON.stringify(images));
      localStorage.setItem("inputFields", JSON.stringify(inputFields));
      localStorage.setItem("customerImgLength", customerImgLength);
      localStorage.setItem("fontFamilies", JSON.stringify(fontFamilies));
      navigate(phoneVerify);
    } else if (typeOfProduct === "normal" && orderid) {
      apiResp = await dispatch(
        updateOrderItem({
          product: getSingleProductData?.id,
          quantity: orderQty,
          id: orderid,
          index: +itemIndex,
          attributeid: productAttributes,
        })
      );
      cartList(apiResp?.payload.status);
    } else if (typeOfProduct === "normal") {
      localStorage.getItem("x-auth-token")
        ? (apiResp = await dispatch(
            addCart({
              product: getSingleProductData?.id,
              quantity: orderQty,
              attributeid: productAttributes,
            })
          ))
        : navigate(phoneVerify);
      cartList(apiResp?.payload.status);
    } else if (typeOfProduct === "limitedEdition" && orderid) {
      setSaveLoading(true)
      apiResp = await dispatch(
        updateOrderItem({
          product: getSingleProductData?.id,
          quantity: orderQty,
          inputFields,
          id: orderid,
          index: +itemIndex,
          attributeid: productAttributes,
          captureImageHtml: bodyContent,
          width : imageDimensions?.width,
          height : imageDimensions?.height
        })
      );
      if(apiResp?.payload.status){
        setSaveLoading(false)
      }
      cartList(apiResp?.payload.status);
    } else if (typeOfProduct === "limitedEdition") {
      localStorage.getItem("x-auth-token")
        ? (apiResp = await dispatch(
            addCart({
              product: getSingleProductData?.id,
              quantity: orderQty,
              inputFields,
              attributeid: productAttributes,
            })
          ))
        : navigate(phoneVerify);
      cartList(apiResp?.payload.status);
    } else if (typeOfProduct === "customize" && orderid) {
      setSaveLoading(true)
      apiResp = await dispatch(
        updateOrderItem({
          product: getSingleProductData?.id,
          quantity: orderQty,
          userimg: images ? images : [],
          inputFields,
          id: orderid,
          index: +itemIndex,
          attributeid: productAttributes,
          captureImageHtml: bodyContent,
          width : imageDimensions?.width,
          height : imageDimensions?.height,
          fontFamilies
        })
      );
      if(apiResp?.payload.status){
        setSaveLoading(false)
        // window.close();
        // localStorage.setItem("updateItem", 1)
        if(localStorage.getItem("slug") === "admin"){
          navigate(`/order-details?orderid=${orderid}`)
        }else if(localStorage.getItem("slug") === "checker"){
          navigate(`/orders`)
        }else{
          navigate(`/order?orderid=${orderid}`)
        }
      }
      // cartList(apiResp?.payload.status);
    } else if (typeOfProduct === "customize") {
      if (localStorage.getItem("x-auth-token")) {
        const apiResp = await dispatch(
          addCart({
            product: getSingleProductData?.id,
            quantity: orderQty,
            inputFields,
            index: +itemIndex,
            attributeid: productAttributes,
          })
        );
        !!images &&
          images?.map((img, index) =>
            localStorage.setItem(`userImg${index}`, img?.imageUrl)
          );
        if (apiResp?.payload?.status) {
          // const userImages = Object.keys(localStorage)
          //   .filter((key) => key.startsWith("userImg"))
          //   .map((key) => ({ key, imageUrl: localStorage.getItem(key) }));
          const userImages = Array.from({
            length:
              (!!getSingleProductData &&
                getSingleProductData?.customizeDetails?.[
                  getSingleProductData?.defaultSample
                ]?.customerImg?.length) ||
              0,
          })
            .map((_, index) => {
              const key = `userImg${index}`;
              const imageUrl = localStorage.getItem(key);
              return {
                key,
                imageUrl,
              };
            })
            .filter(({ imageUrl }) => imageUrl !== null);
          setCartLoader(true)
          await dispatch(
            addCartUploadImg({
              userimg: userImages,
              id: apiResp?.payload?.cartid,
              captureImageHtml: bodyContent,
              width : imageDimensions?.width,
              height : imageDimensions?.height,
              fontFamilies
            })
          ) .then((response) => {
            console.log('Success:', response);
          })
          .catch((error) => {
            console.error('Error:', error);
          })
          .finally(() => {
            console.log('Dispatch process completed');
            setCartLoader(false)
          });
          cartList(apiResp?.payload?.status);
        }
      } else {
        navigate(phoneVerify);
      }
    } else {
      alert("Server Error Please Wait...");
    }
  };
  const baseHeight = 300;
  const increment = width.current - 320;
  let newWidth = width.current < 500 ? increment + baseHeight : 600;
  let newHeight = 0;
  newHeight = (newWidth / imageDimensions.width) * imageDimensions.height;
  if (imageDimensions.width < imageDimensions.height) {
    newHeight = width.current < 500 ? increment + baseHeight : 600;
    newWidth = (newHeight / imageDimensions.height) * imageDimensions.width;
  }
  //qty wise price discount
  let discountWiseProductPrice = getSingleProductData?.sale;
    if (getSingleProductData.price) {
      let pricediscounts = Object.keys(getSingleProductData.price);
      pricediscounts = pricediscounts.sort((a, b) => b - a)
      for (let singlepriceDiscount of pricediscounts) {
          if (orderQty >= parseInt(singlepriceDiscount)) {
            discountWiseProductPrice = parseInt(getSingleProductData.price[parseInt(singlepriceDiscount)])
            break;
          }
      }
    }
  //input fields
  let inputFieldsShowAndUpdated = orderid
    ? getSingleProductData?.customizeDetails?.[
        getSingleProductData?.defaultSample
      ]?.inputFields
    : inputFields;
  //get Unique inputs and showing duplicate on products
  const uniqueInputNames = new Set();
  const uniqueInputs =
    !!getSingleProductData?.customizeDetails?.[
      getSingleProductData?.defaultSample
    ]?.inputFields &&
    getSingleProductData?.customizeDetails?.[
      getSingleProductData?.defaultSample
    ]?.inputFields
    
    const uniqueFieldNames = new Set();
    const calculatedProductPrice = 
    (Number(productAttributesPrice ? productAttributesPrice : discountWiseProductPrice) * 
    (Number(orderQty)));
    //Print Height width
    useEffect(() => {
      localStorage.setItem("printHeight", imageDimensions?.height)
      localStorage.setItem("printWidth", imageDimensions?.width)
    }, [imageDimensions])
    //price object length
  const priceLength = getSingleProductData?.price ? Object.keys(getSingleProductData?.price).length : 0
  if (!socialMedia) {
    return <Loader/>; // Or return null if you don’t want a loading indicator
  }
  return (
    <>
      {socialMedia && (<Helmet>
        <title>{socialMedia?.metaTitle}</title>
        <meta name="description" content={socialMedia?.metaDescription}/>
        <meta name="keywords" content={socialMedia?.metaTags}/>
        <link rel="canonical" href={`${baseUrl}product/${socialMedia?.slug}`} />
        <meta property="og:title" content={socialMedia?.metaTitle}/>
        <meta property="og:site_name" content="Ranbazaar" />
        <meta property="og:url" content={`${baseUrl}product/${socialMedia?.slug}`} />
        <meta property="og:description" content={socialMedia?.metaDescription}/>
        <meta property="og:type" content="website" />
        <meta property="og:image" content={socialMedia?.images?.[0]}/>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Product", // You can change this type based on your content
            "name": socialMedia?.metaTitle,
            "description": socialMedia?.metaDescription,
            "image": `${socialMedia?.images?.[0]}`, // Or you can use dynamic product image
            "url": `${baseUrl}product/${socialMedia?.slug}`,
            "brand": {
              "@type": "Brand",
              "name": "Ranbazaar"
            },
            "offers": {
              "@type": "AggregateOffer",
              "priceCurrency": "INR", // Adjust based on your currency
              "lowPrice": socialMedia?.sale,
              "highPrice": socialMedia?.mrp,
              "offerCount": "1",
             // "price": socialMedia?.sale, // Assuming you have a price field
              "availability": "https://schema.org/InStock", // Adjust based on availability
              "url": `${baseUrl}product/${socialMedia?.slug}`
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": socialMedia?.avgRating,
              "bestRating": "5",
              "worstRating": "1",
              // "ratingCount": "50"
            }
          })}
        </script>

        
      </Helmet>)}
    <div className={`product-details-page ${cartLoader ? `overlay` : ``}`}>
      <CommonHeader />
      {/* {capture && <Loader />} */}
      {loading ? (
        <Loader />
      ) : (
        <section
          className={`${capture ? "opacity" : ""} ${
            width.current > 500 ? `mt-5 mb-5` : `mt-2 mb-5`
          }`}
        >
          <div className="container-fluid page-go-to-top">
            <Row
              className={`${
                productType && width.current < 500 && mobileProductFixIndex >= 0
                  ? "mobile-page"
                  : ""
              }`}
            >
              <Col md={6} sm={12} xs={12}>
                {getSingleProductData?.productType !== "customize" ? (
                  <ProductMultiSlider
                    images={getSingleProductData?.images}
                    productType={productType}
                    customizeSlide={getSingleProductData?.customizeDetails}
                  />
                ) : (
                  <>
                  {/* (productType && (!orderid && customizeProduct)) */}
                   {(productType && (!orderid && customizeProduct)) ? <Loader/> : <div
                      onContextMenu={handleContextMenu}
                      style={{
                        backgroundColor: `${customBg}`,
                        width: customizeWidth,
                        height: customizeHeight,
                        maxWidth: `${newWidth}px`,
                        maxHeight: `${newHeight}px`,
                        position: "relative",
                        overflow: "hidden",
                        margin: "auto",
                      }}
                    >
                      {/* Image behind the name-slip */}
                      {!!customerImg &&
                        customerImg?.map((img, index) => {
                          let imageSrc = orderid
                            ? images?.[index]?.imageUrl
                            : localStorage.getItem(img.name) ||
                              `${img?.imageUrl}`;
                          return (
                            <div
                              key={index}
                              onContextMenu={handleContextMenu}
                              className="overflow-hidden"
                              style={{
                                position: "absolute",
                                userSelect: "auto",
                                width: img?.width?.endsWith("px")
                                  ? img.width
                                  : `${img.width}px`,
                                height: img?.height?.endsWith("px")
                                  ? img.height
                                  : `${img.height}px`,
                                display: "inline-block",
                                cursor: "move",
                                zIndex: 1,
                                transform: `translate(${img.x}px, ${img.y}px)`,
                                boxSizing: "border-box",
                                flexShrink: 0,
                                overflow: "hidden"
                              }}
                            >
                              <img
                                onContextMenu={handleContextMenu}
                                className="w-100"
                                src={imageSrc}
                                alt={images?.[index]?._id}
                                style={{
                                  position: "relative",
                                  top: `${imageTopBottoms[index]}px`,
                                  left: `${imageLeftRights[index]}px`,
                                  transform: `
                                  rotate(${rotations[index] || 0}deg) 
                                  scale(${imageScales[index] || 1}) `,
                                  objectFit: "contain",
                                  height: "100%",
                                  width: "100%",
                                }}
                              />
                            </div>
                          );
                        })}
                      <div
                        className="name-slip name-slip-bg product-bg-container"
                        style={{
                          background: `url(${bgCustomImage})`,
                          width: customizeWidth,
                          height: customizeHeight,
                          backgroundSize: `cover`,
                          backgroundRepeat: `no-repeat`,
                          maxWidth: `${newWidth}px`,
                          maxHeight: `${newHeight}px`,
                          backgroundSize: "contain",
                          overflow: "hidden",
                          position: "relative",
                          zIndex: 2,
                        }}
                      >
                        {!!inputFieldsShowAndUpdated &&
                          inputFieldsShowAndUpdated?.map((field, index) => {
                            const fontSize = parseFloat(field?.style?.fontSize);
                            const maxLength = +field?.maxLength;
                            let fieldValue = orderid
                              ? inputFields?.[index]?.value
                              : (localStorage.getItem(field?.label?.slug) || field.value);
                              if (fieldValue.length > maxLength) {
                                fieldValue = fieldValue.substring(0, maxLength);
                                if (fieldValue[maxLength - 1] !== ' ' && fieldValue.lastIndexOf(' ') !== -1) {
                                  fieldValue = fieldValue.substring(0, fieldValue.lastIndexOf(' '));
                                }
                              }
                                //font size according to width
                              // const adjustFontSize = (fieldValue, maxWidth, initialFontSize) => {
                              //   let adjustedFontSize = initialFontSize;
                              //   const span = document.createElement('span');
                              //   span.style.fontSize = `${adjustedFontSize}px`;
                              //   span.style.visibility = 'hidden';
                              //   span.style.whiteSpace = 'nowrap';
                              //   document.body.appendChild(span);
                              //   span.innerHTML = fieldValue;
                              //   while (span.offsetWidth > maxWidth && adjustedFontSize > 10) {
                              //     adjustedFontSize -= 1;
                              //     span.style.fontSize = `${adjustedFontSize}px`;
                              //   }
                              //   document.body.removeChild(span);
                              //   return adjustedFontSize;
                              // };
                              // const adjustedFontSize = adjustFontSize(fieldValue, field?.width, fontSize); 
                              const formattedName = capitalizeText(fieldValue);
                            return (
                              <div
                                key={index}
                                className="input react-draggable react-draggable-dragged"
                                style={{
                                  position: "absolute",
                                  userSelect: "auto",
                                  color: field?.style?.color,
                                  fontFamily: field?.style?.fontFamily,
                                  fontWeight: field?.style?.fontWeight,
                                  textAlign: field?.style?.textAlignment,
                                  textTransform: field?.style?.textType ? field?.style?.textType : `inherit`,
                                  transform: `rotate(${
                                    field?.style?.rotation || 0
                                  }deg)`,
                                  fontSize: `${fontSize}px`,
                                  width: field?.width?.endsWith("px")
                                    ? field?.width
                                    : `${field?.width}px`,
                                  display: "inline-block",
                                  top: `${field?.y}px`,
                                  left: `${field?.x}px`,
                                  cursor: "move",
                                  maxWidth: "9.0072e+15px",
                                  maxHeight: "9.0072e+15px",
                                  boxSizing: "border-box",
                                  flexShrink: "0",
                                  overflow: "hidden",
                                  zIndex: 3,
                                }}
                              >
                                {formattedName ? formattedName : fieldValue}
                              </div>
                            );
                          })}
                      </div>
                    </div>}
                    <PrintImage
                      updateOrderImg={images}
                      orderid={orderid}
                      capture={capture}
                      newWidth={newWidth}
                      newHeight={newHeight}
                      cnewWidth={customizeWidth}
                      cnewHeight={customizeHeight}
                      divRef={divRef}
                      customBg={customBg}
                      imageDimensions={imageDimensions}
                      customerImg={customerImg}
                      images={images}
                      imageTopBottoms={imageTopBottoms}
                      imageLeftRights={imageLeftRights}
                      rotations={rotations}
                      imageScales={imageScales}
                      bgCustomImage={bgCustomImage}
                      inputFields={inputFields}
                      setBodyContent={setBodyContent}
                      /> 
                  </>
                )}
                {!productType &&
                  getSingleProductData?.productType === "customize" && (
                    <Row className="mt-3">
                      {!!getSingleProductData?.customizeDetails &&
                        getSingleProductData?.customizeDetails?.map(
                          (sampleimg, index) =>
                            getSingleProductData?.defaultSample !== index && (
                              <Col
                                key={index}
                                md={2}
                                sm={2}
                                xs={2}
                                className={`mt-2 img-tabs shadow p-2 ${
                                  activeSample === index && `bg-success p-1`
                                }`}
                              >
                                <img
                                  onClick={() => {
                                    dispatch(
                                      detailsCustomizeProductsImg({
                                        productid: getSingleProductData?.id,
                                        index: index + 1,
                                        width: customizeWidth,
                                        height: customizeHeight,
                                      })
                                    );
                                    setActiveSample(index);
                                  }}
                                  className="text-center"
                                  src={`${sampleimg?.productImg}`}
                                  alt={sampleimg?.name}
                                  onContextMenu={handleContextMenu}
                                />
                              </Col>
                            )
                        )}
                    </Row>
                  )}
              </Col>
              <Col
                md={6}
                sm={12}
                xs={12}
                className={`p-2 ${
                  productType &&
                  width.current < 500 &&
                  mobileProductFixIndex >= 0
                    ? "inputs-container"
                    : ""
                }`}
              >
                {!productType && (
                  <Row>
                    <h4 className="fw-bold product-details-name">
                      {getSingleProductData?.name}
                    </h4>
                    <hr />
                    <p className="mb-1">{getSingleProductData?.avgRating > 0 ? productRating(Math.round(getSingleProductData?.avgRating)) : null}</p>
                    <h3 className="mb-0 font-weight-semibold product__price text-black">
                      <span className="text-danger">
                        -
                        {(
                          ((getSingleProductData?.mrp -
                            getSingleProductData?.sale) /
                            getSingleProductData?.mrp) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                      <sup className="ml-2 f-18">₹</sup>
                      <b>
                        {calculatedProductPrice}
                      </b>
                      {/* <b>
                        {(productAttributesPrice
                          ? productAttributesPrice
                          : getSingleProductData?.sale) * orderQty}
                      </b> */}
                    </h3>
                    <p className="mb-2 discount-price f-13">
                      M.R.P.: <b>₹</b>{getSingleProductData?.mrp}
                    </p>
                    {!productType && (
                      <>
                        <p className="text-secondColor f-16 mb-0">
                          <b>Tax & Shipping Included. No Hidden Charges.</b>
                        </p>
                        <p className="f-16 mb-0">
                          <b>
                            Vendor:{" "}
                            {`${getSingleProductData?.vendor?.firstName} ${getSingleProductData?.vendor?.lastName}`}
                          </b>
                        </p>
                        <p className="text-secondColor f-16 mb-0">
                          <b>Dispatch within 3 to 7 working days.</b>
                        </p>
                        {/* {getSingleProductData?.description && (
                          <Row
                            dangerouslySetInnerHTML={{
                              __html: getSingleProductData?.description
                                ? getSingleProductData.description.substring(
                                    0,
                                    150
                                  ) +
                                  (getSingleProductData.description.length > 150
                                    ? "..."
                                    : "")
                                : "",
                            }}
                          />
                        )} */}
                      </>
                    )}
                  </Row>
                )}
                {
                    priceLength > 0 && <>
                     <Row>
                      <Col>
                        <h5 className="mb-0 mt-2 qty-discount-title"><i className="bi bi-gift-fill"></i> BUY MORE, SAVE MORE</h5>
                      </Col>
                     </Row>
                     <Row className={`qty-offer-scrolling ${width.current > 500 && `justify-content-center`}`}>
                     {
                        !!getSingleProductData?.price && Object.entries(getSingleProductData?.price).map(([key, value]) => {
                          return(
                            <Col xs={6} sm={6} md={2} className={`mb-1 mt-1 px-2 qty-offer-scrolling-col button-pointer`}
                            onClick={() => {
                              setOrderQty(+key)
                              setQtyWiseDis(+key)
                            }}
                            >
                              <div className={`qty-price-tab py-2 ${qtyWiseDis === +key ? `bg-success text-white` : null}`}>
                              <p className="mb-0">Buy Any</p>
                              <p className={`mb-0 ${qtyWiseDis === +key ? `bold-white` : `bold-black`}`}>{key} Packs @</p>
                              <p className={`mb-0 ${qtyWiseDis === +key ? `bold-white` : `bold-black`}`}>{value}/- Each</p>
                              <p className="mb-0">Pack</p>
                              </div>
                            </Col>
                          )
                        })
                      }
                      </Row>
                    </>
                  }
                <Row>
                {/* {priceLength > 0 && <Col md={6}>
                <h5 className="mb-0 mt-2 qty-discount-title"><i className="bi bi-gift-fill"></i> BUY MORE, SAVE MORE</h5>
                {
                  Object.entries(getSingleProductData?.price).map(([key, value]) => {
                    return(
                      <Col xs={6} sm={6} md={2} className="qty-price-tab" key={key}>
                        <p className="mb-0">Buy Any</p>
                        <p className="mb-0">{key} Packs @</p>
                        <p className="mb-0">{value}/- Each</p>
                        <p className="mb-0">Pack</p>
                      </Col>
                    )
                  })
                }
                <Form.Label className="mb-0 mt-2">Qty Wise Discount</Form.Label>
                <Form.Select size="md" 
                      onChange={(e) => {
                        e.target.value === "0" ?  setOrderQty(1) : setOrderQty(+e.target.value)
                        setQtyWiseDis(+e.target.value);
                      }}
                      value={qtyWiseDis}
                >
                <option value={0}>Select Qty Wise Discount</option>
                {
                  Object.entries(getSingleProductData?.price).map(([key, value]) => {
                    return(
                      <option key={key} value={key}>{`Qty : ${key} (Price : ${value})`}</option>
                    )
                  })
                }
                </Form.Select>
                </Col>} */}
                {!!getSingleProductData?.attributes &&
                  getSingleProductData?.attributes?.[0]?.value && (
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Product Attribute</Form.Label>
                          <Form.Select
                            as="select"
                            value={productAttributes}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              setProductAttributesPrice(
                                e.target.selectedOptions[0].getAttribute(
                                  "data-value"
                                )
                              );
                              setProductAttributes(selectedValue);
                            }}
                          >
                            <option value="">Select Attribute</option>
                            {getSingleProductData?.attributes?.map(
                              (attribute, aIndex) => (
                                <option
                                  key={aIndex}
                                  data-value={attribute?.value}
                                  value={attribute?.attributeid?.id}
                                >
                                  {attribute?.attributeid?.name} (
                                  {attribute?.value}/-)
                                </option>
                              )
                            )}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                  )}
              </Row>
                {/* {!productType && (
                  <Col md={12} className="mt-2">
                    <Row>
                      <PinCodeServiceAreaVerify 
                        onSignupStatusChange={handleSignupStatusChange}
                      />
                    </Row>
                  </Col>
                )} */}
                <Row className={"mbl-fixed-btn"}>
                  {getSingleProductData?.productType === "customize" &&
                    !productType && (
                      <Col md={12} className={width.current < 500 && "p-0"}>
                        <p className={width.current < 500 ? "m-0" : "mt-2"}>
                          <Button
                            onClick={() => {
                              setProductType(true);
                              const timeout = setTimeout(() => {
                                setCustomizeProduct(false);
                              }, 200); 
                              return () => clearTimeout(timeout);
                            }}
                            className={
                              width.current < 500
                                ? "personalize-btn-mbl w-100 custombt-btn"
                                : "theme-btn w-100"
                            }
                          >
                           Customize & Add To Cart
                          </Button>
                        </p>
                      </Col>
                    )}
                </Row>
                {((productType &&
                  getSingleProductData?.productType === "customize") ||
                  getSingleProductData?.productType === "limitedEdition") && (
                  <>
                    <Row className="mb-3">
                      {!!uniqueInputs &&
                        uniqueInputs.map((fields, index) => {
                          let fieldValue = orderid
                            ? inputFields?.[index]?.value
                            : localStorage.getItem(fields?.label?.slug) || "";                          
                          let maxLength = +fields?.maxLength;
                          if (fieldValue.length > maxLength) {
                            let trimmedValue = fieldValue.substring(0, maxLength);
                            if (fieldValue[maxLength] !== ' ' && trimmedValue.lastIndexOf(' ') !== -1) {
                              trimmedValue = trimmedValue.substring(0, trimmedValue.lastIndexOf(' '));
                            }
                            fieldValue = trimmedValue;
                          }
                          let characterCount = maxLength - fieldValue?.length;
                          const isDuplicate = uniqueFieldNames.has(fields?.label?.slug);
                          if (!isDuplicate) {
                            uniqueFieldNames.add(fields?.label?.slug); // Mark this input as displayed
                          }
                          const duplicateIndices = uniqueInputs.reduce((acc, field, i) => {
                            if (field?.label?.slug === fields?.label?.slug && i !== index) {
                              acc.push(i);
                            }
                            return acc;
                          }, []);
                          return (
                            <Col
                              md={6} 
                              sm={6} 
                              xs={uniqueInputs.length === 1 ? 12 : 6} 
                              key={index}
                              className={` ${width.current > 500 ? "p-2" : ""} ${isDuplicate ? 'd-none' : ''}`}
                            >
                              <label>
                                <strong className="text-dark">
                                  {" "}
                                  {fields?.name}
                                </strong>{" "}
                                (
                                <b
                                  className={
                                    maxLength > fieldValue?.length
                                      ? "text-success"
                                      : "text-danger"
                                  }
                                >
                                  Enter Max {characterCount}
                                </b>
                                )
                              </label>
                              <input
                                key={index}
                                className={`${
                                  !fieldValue > 0 &&
                                  fields?.required &&
                                  "border-danger"
                                }`}
                                type="text"
                                name={fields?.label?.slug}
                                maxLength={fields.maxLength}
                                value={fieldValue}
                                required={fields?.required}
                                onChange={(e) =>
                                  handleChange(index, e, fields?.label, duplicateIndices)
                                }
                                style={{
                                  width: "100%",
                                  color: "rgb(0, 0, 0)",
                                  fontSize: "16px",
                                  fontFamily: "Arial, sans-serif",
                                  fontWeight: "normal",
                                }}
                              />
                              {!fieldValue > 0 && fields?.required && (
                                <p className="mb-0 text-danger">
                                  This field is Required.
                                </p>
                              )}
                            </Col>
                          );
                        })}
                    </Row>

                    <Row>
                      {customerImg?.map((item, index) => {
                        const imageSrc =
                          images?.[index]?.imageUrl || uploadimage;
                        const progress = uploadProgress?.[index] || 0;
                        return (
                          <React.Fragment key={index}>
                            <Col
                              sm={6}
                              xs={6}
                              className={width?.current > 500 ? "mb-3" : "mb-1"}
                            >
                              <input
                                value=""
                                type="file"
                                accept="image/png, image/jpeg, image/jpg, image/webp, image/svg, image/bmp, image/tiff, image/tif, image/heif, image/heic, image/ico, image/raw, image/cr2, image/nef"
                                onChange={(e) =>
                                  handleFileChange(
                                    e.target.files,
                                    index,
                                    item?.name
                                  )
                                }
                                style={{ display: "none" }}
                                id={`image-input-${index}`}
                              />
                              <div
                                style={{ position: "relative" }}
                                className={width?.current < 500 && `bg-light`}
                              >
                                <img
                                  className={`w-100 ${
                                    width?.current < 500 &&
                                    images?.[index]?.imageUrl &&
                                    `user-mbl-img-input`
                                  }`}
                                  onClick={() =>
                                    document
                                      .getElementById(`image-input-${index}`)
                                      .click()
                                  }
                                  src={imageSrc}
                                  alt="Upload"
                                  onContextMenu={handleContextMenu}
                                />
                                {!images[index]?.imageUrl && (
                                  <p className="mb-0 text-danger">
                                    <b>Please upload image is Required.</b>
                                  </p>
                                )}
                                {progress > 1 && progress < 100 && (
                                  <>
                                    <ProgressBar
                                      striped
                                      variant="success"
                                      now={progress}
                                    />
                                    {progress}%
                                  </>
                                )}
                                {images[index] && (
                                  <Button
                                    variant="danger"
                                    size="sm"
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      right: "10px",
                                    }}
                                    onClick={() => handleDeleteImage(index)}
                                  >
                                    <i className="bi bi-trash3"></i>
                                  </Button>
                                )}
                              </div>
                            </Col>
                            <Col
                              md={6}
                              sm={6}
                              xs={6}
                              className="text-center overflow-hidden"
                            >
                              <Row>
                                <Col className="mt-2" md={3} sm={3} xs={3}>
                                  <Button
                                    variant="secondary"
                                    className={
                                      width.current > 500 ? `` : ` px-1 py-0`
                                    }
                                    onClick={() => handleRotation(index, 10)}
                                  >
                                    <i className="bi bi-arrow-clockwise"></i>
                                  </Button>
                                </Col>
                                <Col className="mt-2" md={3} sm={3} xs={3}>
                                  <Button
                                    variant="secondary"
                                    className={
                                      width.current > 500 ? `` : ` px-1 py-0`
                                    }
                                    onClick={() => handleRotation(index, -10)}
                                  >
                                    <i className="bi bi-arrow-counterclockwise"></i>
                                  </Button>
                                </Col>
                                <Col className="mt-2" md={3} sm={3} xs={3}>
                                  <Button
                                    variant="secondary"
                                    className={
                                      width.current > 500 ? `` : ` px-1 py-0`
                                    }
                                    onClick={() => handleZoom(index, 0.1)}
                                  >
                                    <i className="bi bi-zoom-in"></i>
                                  </Button>
                                </Col>
                                <Col className="mt-2" md={3} sm={3} xs={3}>
                                  <Button
                                    variant="secondary"
                                    className={
                                      width.current > 500 ? `` : ` px-1 py-0`
                                    }
                                    onClick={() => handleZoom(index, -0.1)}
                                  >
                                    <i className="bi bi-zoom-out"></i>
                                  </Button>
                                </Col>
                                <Col className="mt-2" md={3} sm={3} xs={3}>
                                  <Button
                                    variant="secondary"
                                    className={
                                      width.current > 500 ? `` : ` px-1 py-0`
                                    }
                                    onClick={() => handleLeftRight(index, -1)}
                                  >
                                    <i className="bi bi-arrow-left-circle"></i>
                                  </Button>
                                </Col>
                                <Col className="mt-2" md={3} sm={3} xs={3}>
                                  <Button
                                    variant="secondary"
                                    className={
                                      width.current > 500 ? `` : ` px-1 py-0`
                                    }
                                    onClick={() => handleLeftRight(index, 1)}
                                  >
                                    <i className="bi bi-arrow-right-circle"></i>
                                  </Button>
                                </Col>
                                <Col className="mt-2" md={3} sm={3} xs={3}>
                                  <Button
                                    variant="secondary"
                                    className={
                                      width.current > 500 ? `` : ` px-1 py-0`
                                    }
                                    onClick={() => handleTopBottom(index, -1)}
                                  >
                                    <i className="bi bi-arrow-up-circle"></i>
                                  </Button>
                                </Col>
                                <Col className="mt-2" md={3} sm={3} xs={3}>
                                  <Button
                                    variant="secondary"
                                    className={
                                      width.current > 500 ? `` : ` px-1 py-0`
                                    }
                                    onClick={() => handleTopBottom(index, 1)}
                                  >
                                    <i className="bi bi-arrow-down-circle"></i>
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </React.Fragment>
                        );
                      })}
                    </Row>
                  </>
                )}
                <Row>
                  {!orderid && (
                    <Col md={6} className="mt-2">
                      <div className="numbers text-center d-flex">
                        <span
                          className="minus bg-danger"
                          onClick={() =>
                            orderQty > 1 && setOrderQty(orderQty - 1)
                          }
                        >
                          -
                        </span>
                        <input
                            className="w-70"
                            type="number"
                            min={1}
                            step={1} 
                            value={orderQty}
                            onChange={(e) => {
                              const value = Math.floor(+e.target.value); 
                              if (value >= 1) {
                                setOrderQty(value);
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "." || e.key === "e" || e.key === "+" || e.key === "-") {
                                e.preventDefault();
                              }
                            }}
                          />

                        {/* <input
                          className="w-70"
                          type="number"
                          min={1}
                          value={orderQty}
                          onChange={(e) =>
                            e.target.value >= 1 && setOrderQty(+e.target.value)
                          }
                        /> */}
                        <span
                          className="plus bg-success"
                          onClick={() => setOrderQty(orderQty + 1)}
                        >
                          +
                        </span>
                      </div>
                    </Col>
                  )}
                  {orderid && (
                    <Col md={6} className="mt-2">
                    {productType && <Button
                        className="w-100"
                        variant="success"
                        onClick={() =>{
                          const validImages = images?.filter(img => img !== null);
                          if (singleOrderItemData?.userimg?.length !== validImages?.length) {
                            toast('Please Upload Images.', {
                              position: "top-right",
                              autoClose: 1000,
                              hideProgressBar: false,
                              rtl: false,
                              pauseOnFocusLoss: true,
                              closeOnClick: true,
                              pauseOnHover: true,
                              theme: "light",
                              transition: Zoom,
                              position: "bottom-center"
                              });
                          }else{
                            addToCart(getSingleProductData?.productType, 1)
                          }
                        }}
                        // onClick={() =>addToCart(getSingleProductData?.productType, 1)}
                        disabled={saveLoading ? true : false}
                      >
                        {saveLoading ? <Spinner animation="grow" /> : <>Save Changes <i className="bi bi-pen"></i></>}
                      </Button>}
                    </Col>
                  )}
                  {!orderid &&
                    (productType ||
                      getSingleProductData?.productType === "normal" ||
                      getSingleProductData?.productType ===
                        "limitedEdition") && (
                      <>
                        <Col
                          md={3}
                          sm={6}
                          xs={6}
                          className="mt-2 add-to-cart-mbl"
                        >
                          <Button
                            className="theme-btn w-100 btn-addToCart-buyNow-mbl"
                            onClick={() =>{
                              const validImages = images?.filter(img => img !== null);
                              // Check for any required fields with missing values
                              const missingRequiredInputs = inputFieldsShowAndUpdated?.filter(
                                input => input.required && (!localStorage.getItem(input?.label?.slug)?.length > 0)
                              );
                                if (missingRequiredInputs.length > 0) {
                                  toast.error('Please fill all required fields.', {
                                    position: "bottom-center",
                                    autoClose: 1000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                  });
                                  return;
                                }
                              // Proceed with existing conditions and add to cart
                              if(getSingleProductData?.productType === "normal"){
                                if(getSingleProductData?.attributes?.length && !productAttributes?.length){
                                  toast.error('Please Select Attribute', {
                                    position: "bottom-center",
                                    autoClose: 1000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                    });
                                }else{
                                  addToCart(getSingleProductData?.productType, 3, customerImg?.length, getSingleProductData?.slug)
                                }
                            }else{
                              if(getSingleProductData?.attributes?.length && !productAttributes?.length){
                                toast.error('Please Select Attribute', {
                                    position: "bottom-center",
                                    autoClose: 1000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                  });
                                }else if (getSingleProductData?.customizeDetails?.[getSingleProductData?.defaultSample]?.customerImg?.length !== validImages?.length) {
                                  toast.error('Please Upload Images.', {
                                    position: "bottom-center",
                                    autoClose: 1000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                    });
                                }else{
                                  addToCart(getSingleProductData?.productType, 2, customerImg?.length, getSingleProductData?.slug)
                                }
                            }}}
                            disabled={capture ? true : false}
                          >
                            {capture ? (
                              <Spinner animation="grow" />
                            ) : (
                              <>
                                Add To Cart <i className="bi bi-cart-plus"></i>
                              </>
                            )}
                          </Button>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mt-2 buy-now-mbl">
                          <Button
                           variant="success"
                            className="w-100 btn-addToCart-buyNow-mbl"
                            // onClick={() =>
                            //   addToCart(getSingleProductData?.productType, 3, customerImg?.length, getSingleProductData?.slug)
                            // }
                              onClick={() =>{
                              const validImages = images?.filter(img => img !== null);
                              const missingRequiredInputs = inputFieldsShowAndUpdated?.filter(
                                input => input.required && (!localStorage.getItem(input?.label?.slug)?.length > 0)
                              );
                                if (missingRequiredInputs.length > 0) {
                                  toast.error('Please fill all required fields.', {
                                    position: "bottom-center",
                                    autoClose: 1000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                  });
                                  return;
                                }
                              if(getSingleProductData?.productType === "normal"){
                                if(getSingleProductData?.attributes?.length && !productAttributes?.length){
                                  toast.error('Please Select Attribute', {
                                    position: "bottom-center",
                                    autoClose: 1000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                    });
                                }else{
                                  addToCart(getSingleProductData?.productType, 3, customerImg?.length, getSingleProductData?.slug)
                                }
                            }else{
                              if(getSingleProductData?.attributes?.length && !productAttributes?.length){
                                toast.error('Please Select Attribute', {
                                  position: "bottom-center",
                                    autoClose: 1000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                  });
                                }else if (getSingleProductData?.customizeDetails?.[getSingleProductData?.defaultSample]?.customerImg?.length !== validImages?.length) {
                                  toast.error('Please Upload Images.', {
                                    position: "bottom-center",
                                    autoClose: 1000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                    });
                                }else{
                                  addToCart(getSingleProductData?.productType, 3, customerImg?.length, getSingleProductData?.slug)
                                }
                            }}}
                            disabled={buyNow ? true : false}
                          >
                            {buyNow ? (
                              <Spinner animation="grow" />
                            ) : (
                              <>
                                Buy Now{" "}
                                <i className="bi bi-arrow-right-circle"></i>
                              </>
                            )}
                          </Button>
                        </Col>
                      </>
                    )}
                </Row>
              </Col>
            </Row>
            {width.current < 668 && <Row>
                  {getSingleProductData?.productType === "customize" &&
                    !productType && (
                      <Col md={12}>
                        <p className={width.current < 500 ? "mt-3" : "mt-2"}>
                          <Button
                            onClick={() => {
                              setProductType(true);
                              const timeout = setTimeout(() => {
                                setCustomizeProduct(false);
                              }, 200); 
                              return () => clearTimeout(timeout);
                            }}
                            className={"w-100"}
                            variant="success"
                          >
                           Personalize & Add To Cart
                          </Button>
                        </p>
                      </Col>
                    )}
                </Row>
                }
            {!productType && (
              <Row>
                <div className="productsreview mt-5">
                  <ProductTab
                    productDesc={getSingleProductData?.description}
                    productid={getSingleProductData?.id}
                  />
                </div>
              </Row>
            )}
          </div>
        </section>
      )}
      <GotoTop />
      {!productType && <Footer />}
      </div>
    </>
  );
};

export default ProductsDetails;
