import React, { useEffect } from "react";
import "./app.css";
import { Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./Pages/LoginPage";
import { ToastContainer, Zoom  } from "react-toastify";
// import NotFoundPage from "./Components/NotFoundPage";
import Dashboard from "./Pages/Dashboard";
import Attribute from "./Pages/Attribute/Attribute";
import Categories from "./Pages/Categories/Categories";
import Banners from "./Pages/Banners/Banners";
import Coupons from "./Pages/Coupons/Coupons";
import Setting from "./Pages/Setting/Setting";
import Permissions from "./Pages/Permission/Permissions"; 
import ProductsDetails from "./ComponentsWeb/Sub/ProductsDetails";
import Home from "./ComponentsWeb/Main/Home";
import Reviews from "./Pages/Review/Reviews";
import About from "./ComponentsWeb/Main/About";
import Contact from "./ComponentsWeb/Main/Contact";
import Shop from "./ComponentsWeb/Sub/Shop";
import Checkout from "./ComponentsWeb/Main/Checkout";
import Response from "./ComponentsWeb/Main/Response";
import UserLogin from "./ComponentsWeb/Main/user/UserLogin";
import UserSignup from "./ComponentsWeb/Main/user/UserSignup";
import AddProduct from "./Pages/Products/AddProduct";
import AddUserAddress from "./ComponentsWeb/Main/user/AddUserAddress";
import UserPanel from "./ComponentsWeb/Main/user/UserPanel";
import OrdersList from "./Pages/Orders/OrdersList";
import SocialMediaSetting from "./Pages/SocialMediaSetting";
import ProductsList from "./Pages/Products/ProductsList";
import EmailTemplates from "./Pages/EmailTemplates/EmailTemplates";
import Pages from "./Pages/infoPages/Pages";
import DynamicPages from "./Components/DynamicPages";
import UpdateProduct from "./Pages/Products/UpdateProduct";
import TaxClasses from "./Pages/TaxClasses/TaxClasses";
import ForgotPassword from "./ComponentsWeb/Main/user/forgotpassword/ForgotPassword";
import VerifyOtp from "./ComponentsWeb/Main/user/forgotpassword/VerifyOtp";
import NewPassword from "./ComponentsWeb/Main/user/forgotpassword/NewPassword";
import ShippingChargesList from "./Pages/ShippingCharges/ShippingChargesList";
import UserOrderReper from "./Pages/Orders/UserOrderReper";
import TrackersList from "./Pages/Trackers/TrackersList";
import UsersCartList from "./Pages/UsersCart/UsersCartList";
import OffersList from "./Pages/Offers/OffersList";
import RewardSlabList from "./Pages/RewardSlab/RewardSlabList";
import Users from "./Pages/User/Users"
import IpAddressComponent from "./ComponentsWeb/Sub/IpAddressComponent";
import PaymentOptions from "./ComponentsWeb/Main/PaymentOptions";
import UserAddress from "./ComponentsWeb/Main/user/UserAddress";
import RegisterWithPhone from "./ComponentsWeb/Main/user/RegisterWithPhone";
import ProductLabelList from "./Pages/ProductLabels/ProductLabelList";
import WarehouseList from "./Pages/Warehouse/WarehouseList";
import FileSettingList from "./Pages/FileSettings/FileSettingList";
import OrderFiles from "./Pages/ShippingTool/OrderFiles";
import ProductFieldAction from "./Pages/Products/ProductFieldAction";
import PackageList from "./Pages/Package/PackageList";
import InvoiceFiles from "./Pages/Invoice/InvoiceFiles";
import CodList from "./Pages/Cod/CodList";
import UserAdminOrderDetails from "./Pages/Orders/UserAdminOrderDetails";
import ThankYouPage from "./ComponentsWeb/Sub/ThankYouPage";
import PendingOrders from "./Pages/Orders/PendingOrders";
import SideMenu from "./Components/SideMenu";
import AdminUserAddress from "./Pages/User/AdminUserAddress";

function App() {
  const currentUrl = window.location.href;
  const getTimeUntilMidnight = () => {
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setHours(23, 59, 59, 999);
    if (now > tomorrow) {
      tomorrow.setDate(tomorrow.getDate() + 1);
    }
    return tomorrow - now; 
  };
  const clearLocalStorageAtMidnight = () => {
    Object.keys(localStorage).forEach((key) => {
      if (key !== "x-auth-token" && key !== "slug" && key !== "userRole" && key !== "username") {
        localStorage.removeItem(key);
      }
    });
    const timeUntilNextMidnight = getTimeUntilMidnight();
    setTimeout(clearLocalStorageAtMidnight, timeUntilNextMidnight);
  };
  useEffect(() => {
    const timeUntilNextMidnight = getTimeUntilMidnight();
    const timer = setTimeout(clearLocalStorageAtMidnight, timeUntilNextMidnight);
    return () => clearTimeout(timer);
  }, []);

  const PrivateRoute = ({ children, allowedRoles  }) => {
    const isAuthenticated = !!localStorage.getItem('x-auth-token'); // or any other auth logic
    const userRole = localStorage.getItem("slug");
    if (!isAuthenticated) {
      return <Navigate to="/" replace />; 
    }
    if (allowedRoles && !allowedRoles.includes(userRole)) {
      return <Navigate to="/" replace />; 
    }
    return children;
    // return isAuthenticated ? children : <Navigate to="/" replace />;
  };
  
  if (window.location.hostname === 'www.ranbazaar.com') {
    // Redirect to the non-www version
    window.location.replace('https://ranbazaar.com' + window.location.pathname + window.location.search);
}
  return (
    <>
   
      <Routes>
        {/* web routes start*/}
        <Route path="/" element={<Home />} /> 
        <Route path="/abc" element={<IpAddressComponent />} />
        <Route path="/response" element={<Response />} />
        <Route path="/thankyou" element={<ThankYouPage />} />
        <Route path="/product/:slug" element={<ProductsDetails />} />
        <Route path="/shop/:slug" element={<Shop />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/about" element={<About />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/login" element={<UserLogin />} />
        <Route path="/signup" element={<UserSignup />} />
        <Route path="/user-address" element={<UserAddress />} />
        <Route path="/add-user-address" element={<AddUserAddress />} />
        <Route path="/user-dashboard" element={<UserPanel />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/:slug" element={<DynamicPages />} />
        <Route path="/phone-verify" element={<RegisterWithPhone />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify-otp" element={<VerifyOtp />} />
        <Route path="/create-new-password" element={<NewPassword />} />
        {/* web routes end*/}
        <Route path="/admin-login" element={<LoginPage />} />
        <Route path="/dashboard" element={<PrivateRoute allowedRoles={["admin"]}><Dashboard /></PrivateRoute>} />
        <Route path="/social-media-setting" element={<PrivateRoute allowedRoles={["admin"]}><SocialMediaSetting /></PrivateRoute>} />
        <Route path="/tax-classes" element={<PrivateRoute allowedRoles={["admin"]}><TaxClasses /></PrivateRoute>} />
        <Route path="/shipping-charges" element={<PrivateRoute allowedRoles={["admin"]}><ShippingChargesList /></PrivateRoute>} />
        <Route path="/offers" element={<PrivateRoute allowedRoles={["admin"]}><OffersList /></PrivateRoute>} />
        <Route path="/trackers" element={<PrivateRoute allowedRoles={["admin"]}><TrackersList /></PrivateRoute>} />
        <Route path="/reward-slab" element={<PrivateRoute allowedRoles={["admin"]}><RewardSlabList /></PrivateRoute>} />
        <Route path="/attributes" element={<PrivateRoute allowedRoles={["admin"]}><Attribute /></PrivateRoute>} />
        <Route path="/users" element={<PrivateRoute allowedRoles={["admin"]}><Users /></PrivateRoute>} />
        <Route path="/admin-user-address" element={<PrivateRoute allowedRoles={["admin"]}><AdminUserAddress /></PrivateRoute>} />
        <Route path="/vendors" element={<PrivateRoute allowedRoles={["admin"]}><Users /></PrivateRoute>} />
        <Route path="/checker" element={<PrivateRoute allowedRoles={["admin"]}><Users /></PrivateRoute>} />
        <Route path="/manager" element={<PrivateRoute allowedRoles={["admin"]}><Users /></PrivateRoute>} />
        <Route path="/user-cart" element={<PrivateRoute allowedRoles={["admin"]}><UsersCartList /></PrivateRoute>} />
        <Route path="/orders" element={<PrivateRoute allowedRoles={["admin", "checker"]}><OrdersList /></PrivateRoute>} />
        {/* <Route path="/admin" >
         <Route path="orders" element={<PrivateRoute><OrdersList /></PrivateRoute>} />
        </Route> */}

        <Route path="/pending-orders" element={<PrivateRoute allowedRoles={["admin"]}><PendingOrders /></PrivateRoute>} />
        <Route path="/order-details" element={<PrivateRoute><UserAdminOrderDetails /></PrivateRoute>} />
        <Route path="/order" element={<PrivateRoute><UserOrderReper /></PrivateRoute>} />
        <Route path="/email-templates" element={<PrivateRoute allowedRoles={["admin"]}><EmailTemplates /></PrivateRoute>} />
        <Route path="/payment-option" element={<PrivateRoute><PaymentOptions /></PrivateRoute>} />
        <Route path="/categories" element={<PrivateRoute allowedRoles={["admin"]}><Categories /></PrivateRoute>} />
        <Route path="/banners" element={<PrivateRoute allowedRoles={["admin"]}><Banners /></PrivateRoute>} />
        <Route path="/coupons" element={<PrivateRoute allowedRoles={["admin"]}><Coupons /></PrivateRoute>} />
        <Route path="/settings" element={<PrivateRoute allowedRoles={["admin"]}><Setting /></PrivateRoute>} />
        <Route path="/permissions" element={<PrivateRoute allowedRoles={["admin"]}><Permissions /></PrivateRoute>} />
        <Route path="/reviews" element={<PrivateRoute allowedRoles={["admin"]}><Reviews /></PrivateRoute>} />
        <Route path="/add-product" element={<PrivateRoute allowedRoles={["admin"]}><ProductFieldAction /></PrivateRoute>} />
        <Route path="/customize-product-img" element={<PrivateRoute allowedRoles={["admin"]}><AddProduct /></PrivateRoute>} />
        <Route path="/product-labels" element={<PrivateRoute allowedRoles={["admin"]}><ProductLabelList /></PrivateRoute>} />
        <Route path="/package" element={<PrivateRoute allowedRoles={["admin"]}><PackageList /></PrivateRoute>} />
        <Route path="/cod" element={<PrivateRoute allowedRoles={["admin"]}><CodList /></PrivateRoute>} />
        <Route path="/file-setting" element={<PrivateRoute allowedRoles={["admin"]}><FileSettingList /></PrivateRoute>} />
        <Route path="/warehouse" element={<PrivateRoute allowedRoles={["admin"]}><WarehouseList /></PrivateRoute>} />
        <Route path="/product-list" element={<PrivateRoute allowedRoles={["admin"]}><ProductsList /></PrivateRoute>} />
        <Route path="/product-edit/:slug" element={<PrivateRoute allowedRoles={["admin"]}><UpdateProduct /></PrivateRoute>} />
        <Route path="/pages" element={<Pages />} />
        <Route path="/email-templates" element={<EmailTemplates />} />
        <Route path="/order-pdf" element={<PrivateRoute allowedRoles={["admin"]}><OrderFiles /></PrivateRoute>} />
        <Route path="/invoice-pdf" element={<PrivateRoute allowedRoles={["admin"]}><InvoiceFiles /></PrivateRoute>} />
        <Route path="*" element={<Navigate to="/" replace />} />
        
      </Routes>
      <ToastContainer
          position="bottom-center"
          autoClose={500}
          hideProgressBar
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition={Zoom}
        />
       {/* {currentUrl.includes(!'shop') && <GoToTop />} */}
    </>
  );
}
export default App;
