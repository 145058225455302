import React, { useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import {
  updateOrderStatus,
  updatePaymentOrderStatus,
  resetAdminOrderList
} from "../../reducers/orderDetailsReducer";

function OrderUpdate(props) {
  const dispatch = useDispatch();
  const width = useRef(window.innerWidth);
  const { orderStatus, title, cod, orderId, listingApi, paymentStatusUpdate } = props;
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState(orderStatus);
  const [codCharges, setCodCharges] = useState(cod);
  const [paymentStatus, setPaymentStatus] = useState(paymentStatusUpdate);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const updateUser = async() => {
    if(status !== orderStatus){
      await dispatch(updateOrderStatus({id: orderId, status: +status}))
    }
    if(paymentStatus !== paymentStatusUpdate || codCharges !== cod){
      await dispatch(updatePaymentOrderStatus({ id: orderId, paystatus:paymentStatus, codCharges}));
    }
      await dispatch(resetAdminOrderList());
      await dispatch(listingApi());
      setShow(false);
  };
  return (
    <>
      <Button className={width.current < 668 ? "px-1 py-0 mx-1" : "m-1"} variant="primary" onClick={handleShow}>
        <i className="bi bi-pencil-square"></i>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Row>
          <Col md={6}>
            <Form.Group className="p-3">
              <label>Order Status</label>
              <Form.Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option>Order Status</option>
                <option value={0}>Pending</option>
                <option value={1}>Cancel</option>
                <option value={2}>Processing</option>
                <option value={3}>Verify</option>
                <option value={4}>In_Transit</option>
                <option value={5}>Shipping</option>
                <option value={6}>Completed</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="p-3">
              <label>Payment Status</label>
              <Form.Select
                value={paymentStatus}
                onChange={(e) => setPaymentStatus(e.target.value)}
              >
                <option>Payment Status</option>
                <option value={"Prepaid"}>Paid</option>
                <option value={"COD"}>UnPaid</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
       {(paymentStatus === "COD") ? <Row>
          <Col md={12}>
            <Form.Group className="p-3">
              <label for="exampleInputEmail1">COD Charges</label>
              <input type="number" value={codCharges} onChange={(e) => setCodCharges(+e.target.value)} className="form-control" placeholder="COD Charges" />
            </Form.Group>
          </Col>
        </Row> : null}
        <Modal.Footer>
          <Button variant="success" onClick={updateUser}>
            Save
          </Button>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OrderUpdate;
