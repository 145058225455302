import { useEffect } from "react";
import { Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CommonHeader from "../ComponentsWeb/Sub/CommonHeader";
import Footer from "../ComponentsWeb/Sub/Footer";
import { getSinglePage } from "../reducers/frontEndReducer";
import NotFoundPage from "./NotFoundPage";
import ReactPlayer from "react-player";
import Home from "../ComponentsWeb/Main/Home";
import { home } from "../const";
import GotoTop from "./GotoTop";

const DynamicPages = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let params = useParams();
  useEffect(() => {
    dispatch(
      getSinglePage({
        slug: params?.slug,
      })
    );
  }, []);
  const { getSinglePageData } = useSelector((state) => state.frontEndReducer);
  const videoUrlRegex = /<oembed[^>]*url="([^"]+)"[^>]*><\/oembed>/;
  const videoUrlMatch =
    !!getSinglePageData?.description &&
    getSinglePageData?.description.match(videoUrlRegex);
  const videoUrl = videoUrlMatch ? videoUrlMatch[1] : null;
  return (
    <>
      <CommonHeader />
      <div className="container-fluid">
        <div className="page-main p-5">
          {!!getSinglePageData ? (
            <>
              <Row>
                <div className="section__head d-md-flex justify-content-between mb-40">
                  <div className="section__title">
                    <h1>{getSinglePageData?.name}</h1>
                  </div>
                </div>
              </Row>
              {videoUrl && (
                <ReactPlayer
                  url={videoUrl}
                  controls
                  width="100%"
                  height="360px"
                />
              )}
              <Row
                dangerouslySetInnerHTML={{
                  __html: getSinglePageData?.description,
                }}
              />
            </>
          ) : (
            navigate(home)
          )}
        </div>
      </div>
      <GotoTop/>
      <Footer />
    </>
  );
};

export default DynamicPages;
