import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addUserProductReview, resetAdminOrderList, userOrders } from "../../../reducers/orderDetailsReducer";

const schema = yup.object().shape({
  product: yup.string().required(),
  title: yup.string().required(),
  rating: yup.string().required(),
  description: yup.string(),
  reviewType: yup.string().required(),
  // images: yup.mixed(),
  // images: yup.mixed().test("fileSize", "Please upload at least one image", (value) => {
  //   return value && value.length > 0; // Check if array of images is not empty
  // }),
  video: yup.mixed().when('reviewType', {
    is: 'video',
    then: yup.mixed().required('Video is required'),
    otherwise: yup.mixed(),
  }),
});

const AddUserReview = ({items, orderId}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();

  return (
    <>
      <Button
        className="m-1 btn-mbl-text"
        variant="warning"
        onClick={handleShow}
      >
        Post Review
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Col md={12} className="box-shadows mbl-pedding-10 p-5">
          <div className="border-y mb-4">
            <h2 className="mb-0">Write a review..</h2>
          </div>
          <Formik
            validationSchema={schema}
            onSubmit={async (values, { resetForm }) => {
              const apiResp = await dispatch(addUserProductReview(values));
              if(apiResp?.payload?.status){
                resetForm({ values: "" });
                handleClose()
                dispatch(resetAdminOrderList());
                dispatch(userOrders());
              }
            }}
            initialValues={{
              product: "",
              title: "",
              rating: "",
              description: "",
              video:'',
              images:[],
              orderid:orderId
            }}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              setFieldValue,
              values,
              errors,
            }) => (
              <Form className="container" onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col md={12} sm={12}>
                    <Form.Label>Select Product</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        setFieldValue("product", e.target.value);
                      }}
                      name="product"
                      className="form-control"
                      value={values.product}
                      isInvalid={!!errors.product}
                    >
                      <option value="">Select Product</option>
                      {!!items &&
                        items.map((orders, index) => {
                          return (
                            <React.Fragment key={index}>
                              <option value={orders?.productId?.id}>
                                {orders?.productId?.name}
                              </option>
                            </React.Fragment>
                          );
                        })}
                    </Form.Control>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12} sm={12}>
                    <Form.Label>Select Review Type</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        setFieldValue("reviewType", e.target.value);
                      }}
                      name="reviewType"
                      className="form-control"
                      value={values.reviewType}
                      isInvalid={!!errors.reviewType}
                    >
                      <option value="">Select ReviewType</option>
                      <option value="video">Video</option>
                      <option value="content">Content</option>
                    </Form.Control>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={6} sm={12}>
                    <Form.Group>
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="title"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        isInvalid={!!errors.title}
                        className={errors.title && touched.title && "errors"}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.title}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6} sm={12}>
                    <Form.Label>Rating</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        setFieldValue("rating", e.target.value);
                      }}
                      name="rating"
                      className="form-control"
                      value={values.rating}
                      isInvalid={!!errors.rating}
                    >
                      <option value="">Select Rating</option>
                      <option value={1}>⭐</option>
                      <option value={2}>⭐⭐</option>
                      <option value={3}>⭐⭐⭐</option>
                      <option value={4}>⭐⭐⭐⭐</option>
                      <option value={5}>⭐⭐⭐⭐⭐</option>
                    </Form.Control>
                  </Col>
                </Row>
                <Row className="mb-3">
                {values?.reviewType === "content" ? <Col md={12} sm={12}>
                  <Form.Group>
                    <Form.Label for="myfile">Images</Form.Label>
                    <Form.Control
                      id="myfile"
                      type="file"
                      name="images"
                      multiple={true}
                      onChange={(event) => {
                        const files = event.target.files;
                        const imagesArray = Array.from(files); 
                        setFieldValue("images", imagesArray); 
                      }}
                      isInvalid={!!errors.images}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.images}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col> : <Col md={12} sm={12}>
                  <Form.Group>
                    <Form.Label htmlFor="myvideo">Video <span className="text-danger">(Max 30sec)</span></Form.Label>
                    <Form.Control
                      id="myvideo"
                      type="file"
                      name="video"
                      accept="video/*"
                      onChange={(event) => {
                        const video = event.target.files[0];
                        setFieldValue("video", video);
                      }}
                      isInvalid={!!errors.video}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.video}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>}
                </Row>
                <Row className="mb-3">
                <Col md={12} sm={12}>
                    <Form.Group>
                      <Form.Label>Enter your comment.</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="description"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        isInvalid={!!errors.description}
                        className={errors.description && "errors"}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Modal.Footer>
                  <Button
                    variant="success"
                    className="bg-thememain"
                    type="submit"
                  >
                    Post Your Review <i className="bi bi-pen"></i>
                  </Button>
                  <Button variant="danger" onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Col>
      </Modal>
    </>
  );
};

export default AddUserReview;
