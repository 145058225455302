import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function GotoTop() {
  const routePath = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    },500); 
  }, [routePath]);
  return null;
}
