import { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { handleContextMenu } from "../../const";

function OrderImgPopUp({ alt, image, title, btn}) {
  const [show, setShow] = useState(false);
  const width = useRef(window.innerWidth);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    {
      btn ? <Button onClick={handleShow} className="theme-btn px-2 py-1">View</Button> : <img
      onClick={handleShow}
      className={width.current > 500 ? "w-15-percent" : "w-50"}
      src={image}
      alt={alt}
      loading="lazy"
    />
    }
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            className="w-100"
            alt={alt}
            loading="lazy"
            onContextMenu={handleContextMenu}
            src={image}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OrderImgPopUp;
