import React from "react";
import Categories from "./Categories";
import Header from "./Header";
import { useSelector } from "react-redux";

const CommonHeader = ({customizeProductPositionMbl}) => {
  return (
    <>
       <Header customizeProductPositionMbl={customizeProductPositionMbl}/>
    </>
  );
};

export default CommonHeader;
