import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FieldArray, Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import {  getOffersForAdmin } from "../../reducers/commonReducer";

const schema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  rate: yup.number().required(),
  status: yup.number().required(),
  offerType: yup.number().required(),
  qty: yup.number().when('offerType', {
    is: 1,  
    then: yup.number().required('Qty is required when offerType is 1'),  
    otherwise: yup.number().notRequired() 
  }),
});

function OffersAction({
  title,
  popUpTitle,
  id,
  name,
  description,
  rate,
  status,
  offerType,
  api,
  qty
}) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => {setShow(false)};
  const handleShow = () => setShow(true);
  return (
    <>
      <Button className="bg-thememain" onClick={handleShow}>
        {title}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{popUpTitle}</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            const apiResp = await dispatch(api(values));
            if(apiResp?.payload?.status){
              dispatch(getOffersForAdmin());
              handleClose();
              resetForm();
            }
          }}
          initialValues={{
            id, name, description, rate, status, offerType, qty
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={6} sm={6}>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={6}>
                  <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Description"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      isInvalid={!!errors.description}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
              <Col md={6} sm={6}>
                  <Form.Group>
                    <Form.Label>Rate in %</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Rate"
                      name="rate"
                      value={values.rate}
                      onChange={handleChange}
                      isInvalid={!!errors.rate}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.rate}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              <Col md={6} sm={6}>
                  <Form.Group>
                    <Form.Label>Select Status</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        setFieldValue("status", +e.target.value);
                      }}
                      className="form-control bg"
                      name={`status`}
                      value={values.status}
                      isInvalid={!!errors.status}
                    >
                      <option value="">Select Status</option>
                      <option value={1}>Active</option>
                      <option value={0}>Inactive</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.status}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
              <Col md={6} sm={6}>
                  <Form.Group>
                    <Form.Label>Select OfferType</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        setFieldValue("offerType", +e.target.value);
                      }}
                      className="form-control bg"
                      name={`offerType`}
                      value={values.offerType}
                      isInvalid={!!errors.offerType}
                    >
                      <option value="">Select OfferType</option>
                      <option value={1}>Cart Qty</option>
                      <option value={2}>Coupon</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.offerType}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {values?.offerType === 1 && <Col md={6} sm={6}>
                  <Form.Group>
                    <Form.Label>Qty</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Qty"
                      name="qty"
                      value={values.qty}
                      onChange={handleChange}
                      isInvalid={!!errors.qty}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.qty}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>}
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
                <Button variant="danger" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default OffersAction;
