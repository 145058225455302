import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import sumBy from "lodash/sumBy";
import Modal from "react-bootstrap/Modal";
import { applyCartOffer, getOffers } from "../../../reducers/commonReducer";
import Coupon from "./Coupon";

const Offers = ({ couponRate, orderAmount, couponAmountStatus, couponTypeStatus }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const [couponQty, setCouponQty] = useState();
  useEffect(() => {
    dispatch(getOffers());
  }, []);
  const { offersData, getCartlistData } = useSelector(
    (state) => state.commonReducer
  );
  //cart item count
  let cartItems = sumBy(
    !!getCartlistData?.list && getCartlistData?.list,
    function (o) {
      return o.quantity;
    }
  );
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
    {/* Add Coupon Button */}
    <Button
      className="theme-btn w-100 py-2"
      style={{
        backgroundImage: "linear-gradient(90deg, #ff8e01, #ff5722)",
        color: "#fff",
        borderRadius: "50px",
        fontSize: "18px",
        fontWeight: "bold",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "15px 20px",
        transition: "all 0.3s ease",
        boxShadow: "0 8px 15px rgba(255, 87, 34, 0.3)",
      }}
      onClick={handleShow}
      onMouseEnter={(e) => (e.currentTarget.style.boxShadow = "0 10px 20px rgba(255, 87, 34, 0.5)")}
      onMouseLeave={(e) => (e.currentTarget.style.boxShadow = "0 8px 15px rgba(255, 87, 34, 0.3)")}
    >
      Add Coupon
      <i
        className="bi bi-arrow-right"
        style={{
          fontSize: "20px",
          marginLeft: "15px",
          transform: "rotate(45deg)",
        }}
      />
    </Button>
  
    {/* Modal */}
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body
        className="py-4"
        style={{
          backgroundColor: "#fff",
          borderRadius: "20px",
          padding: "30px",
          boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)",
          animation: "fadeIn 0.5s ease",
          
        }}
      >
        {/* Coupon Section */}
        <Row>
          <Col md={12} className="mb-3">
            <Coupon couponAmount={couponAmountStatus} popupClose={handleClose} />
          </Col>
        </Row>
  
        {/* Offer Details */}
        {offersData &&
          offersData.map((offer, index) =>
            offer.offerType === 1 ? (
              <div
                key={index}
                className={`p-3 mb-3 rounded ${
                  cartItems >= offer?.qty ? "border-success" : "border-light"
                }`}
                style={{
                  backgroundColor: cartItems >= offer?.qty ? "#e0ffe0" : "#f9f9f9",
                  border: `3px dashed ${
                    cartItems >= offer?.qty ? "#23e923" : "#ccc"
                  }`,
                  transition: "all 0.3s ease",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Row className="align-items-center">
                  <Col md={8}>
                    <strong
                      className={`${
                        cartItems >= offer?.qty ? "text-success" : "text-dark"
                      }`}
                      style={{ fontSize: "18px", textTransform: "uppercase" }}
                    >
                      🎁 {offer.name}
                    </strong>
                  </Col>
                  <Col md={4} className="text-right">
                  {/* {console.log("cartItems >= offer?.qty ", cartItems >= offer?.qty )} */}
                    {!couponTypeStatus && (
                      <Button
                        onClick={async () => {
                          const apiResp = await dispatch(
                            applyCartOffer({ qty: cartItems, couponName: offer?.id })
                          );
                          if (apiResp) {
                            localStorage.setItem("couponName", apiResp?.payload?.offer?.id)
                            couponAmountStatus(apiResp.payload.offer?.rate, true);
                            setCouponQty(apiResp.payload.offer?.qty);
                            localStorage.removeItem("code");
                            localStorage.setItem("couponoffer", false);
                            handleClose();
                          }
                        }}
                        className="px-4 py-2"
                        style={{
                            backgroundColor:
                            cartItems >= offer?.qty ? "#23e923" : "#ddd",
                            color: "#fff",
                            borderRadius: "10px",
                            fontWeight: "bold",
                            cursor: cartItems >= offer?.qty ? "pointer" : "not-allowed",
                            border:'1px solid whitesmoke'
                        }}
                        // disabled={cartItems >= offer?.qty ? true : false}
                        disabled={cartItems < offer?.qty}
                      >
                        Apply
                      </Button>
                    )}
                    {couponTypeStatus && couponQty >= offer?.qty && (
                      <p
                        onClick={() => couponAmountStatus(0, false)}
                        className="px-4 py-2 offercoupon"
                        
                      >
                        X
                      </p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mt-2">
                    <p
                      className={`mb-0 ${
                        cartItems >= offer?.qty ? "text-success" : "text-muted"
                      }`}
                      style={{ fontSize: "16px", fontStyle: "italic" }}
                    >
                      Save ₹{Math.round((+orderAmount / 100) * offer?.rate)} on your
                      order!
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p
                      className={`mb-0 ${
                        cartItems >= offer?.qty ? "text-success" : "text-muted"
                      }`}
                      style={{
                        fontSize: "14px",
                        textAlign: "justify",
                      }}
                    >
                      {offer.description}
                    </p>
                  </Col>
                </Row>
              </div>
            ) : null
          )}
  
        {/* Close Button */}
        <Row>
          <Col md={12}>
            <Button
              className="w-100 mt-3"
              style={{
                backgroundColor: "#cc0c39",
                color: "#fff",
                fontWeight: "bold",
                padding: "14px",
                fontSize: "16px",
                borderRadius: "20px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                border:'1px solid whitesmoke'
              }}
              onClick={handleClose}
            >
              Close
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  
    {/* Fade-In Animation */}
    <style>{`
      @keyframes fadeIn {
        from { opacity: 0; transform: scale(0.95); }
        to { opacity: 1; transform: scale(1); }
      }
    `}</style>
  </>
  
  );
};

export default Offers;
