import React, { useEffect, useRef, useState } from "react";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import CommonHeader from "./CommonHeader";
import { getProducts, resetProductList } from "../../reducers/commonReducer";
import { getCategoriesFrontEnd } from "../../reducers/frontEndReducer";
import dummy from "../../images/dummy.jpeg";
import commingsoon from "../../images/commingson.jpg";
import { Helmet } from "react-helmet";
import { userProductLikedList } from "../../reducers/orderDetailsReducer";
import {
  calculateImageSize,
  handleContextMenu,
  productRating,
  shop,baseUrl,
} from "../../const";
import CustomizeProduct from "./CustomizeProduct";
import Loader from "../../Components/Loader";
import { Col, Row } from "react-bootstrap";
import NumberPagination from "../../Pagination";

const Shop = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParameters = new URLSearchParams(window.location.search);
  const productId = queryParameters.get("ref_");
  const catName = queryParameters.get("catname");
  const pageCountner = queryParameters.get("page");
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(10000000);
  const [productRecord, setProductRecord] = useState(true);
  const [loading, setLoading] = useState(true);
  const [delayLoading, setDelayLoading] = useState(true);
  const [pWidth, setPWidth] = useState();
  const [pHeight, setPHeight] = useState();
  const [page, setPage] = useState(pageCountner ? +pageCountner : 1);
  const width = useRef(window.innerWidth);

  useEffect(() => {
    if (!location?.pathname !== "/shop") {
      window.scrollTo(0, 0);
    }
  }, [catName]);
  
  // useEffect(() => {
  //   return () => {
  //     localStorage.setItem("scrollPosition", window.scrollY);
  //   };
  // }, [location]);
  // catwise filter
  let multipleCat = localStorage.getItem("multiCat")?.split(",") || [];
  let multipleCats = [
    ...multipleCat?.filter((cat) => cat !== null && cat !== ""),
    productId,
  ]?.filter((item) => item !== null && item !== "");

  useEffect(() => {
    const { cheight, cwidth } = calculateImageSize(width.current);
    dispatch(resetProductList());
    const fetchData = async () => {
      setLoading(true);
      setDelayLoading(true);
      setPWidth(cwidth);
      setPHeight(cheight);
      const apiresp = await dispatch(
        getProducts({
          categories: productId ? [productId] : multipleCats,
          width: cwidth,
          height: cheight,
          page,
          minprice: +minPrice,
          maxprice: +maxPrice,
        })
      );
      if (apiresp?.payload?.status) {
        setLoading(false);
        apiresp?.payload?.list?.length > 0 ? setProductRecord(true) : setProductRecord(false)
      }
    };
    fetchData();
  }, [width.current, page, productId, dispatch, location.pathname]);
  //loader showing after loading true
  useEffect(() => {
    if (!loading) {
      const timer = setTimeout(() => {
        setDelayLoading(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [loading]);
  useEffect(() => {
    setLoading(true);
    dispatch(getCategoriesFrontEnd());
    localStorage.getItem("x-auth-token") && dispatch(userProductLikedList());
    setLoading(false);
  }, [width.current]);

  const { getProductsData, pageCount, shopProductsBlank } = useSelector(
    (state) => state.commonReducer
  );
  const { getCatsFrontEndData } = useSelector((state) => state.frontEndReducer);
  const handleCheckboxChange = (subCat, event) => {
    let selectCat =
      subCat === false ? [...categories, event.target.id] : [...categories];
    if (categories.includes(event.target.id)) {
      selectCat = selectCat?.filter((cat) => cat !== event.target.id);
    }
    dispatch(resetProductList());
    dispatch(
      getProducts({
        categories: selectCat,
        minprice: +minPrice,
          maxprice: +maxPrice,
        width: pWidth,
        height: pHeight,
      })
    );
    setCategories(selectCat);
  };
  useEffect(() => {
    localStorage.setItem("multiCat", categories);
  }, [categories]);
  //pagination
  const onPageChange = (number) => {
    setPage(number);
  };
  //price filter
  // useEffect(() => {
  //   let timeOut = setTimeout(() => {
  //     dispatch(resetProductList());
  //     dispatch(
  //       getProducts({
  //         categories: categories.length > 0 ? categories : "",
  //         minprice: +minPrice,
  //         maxprice: +maxPrice,
  //         width: pWidth,
  //         height: pHeight,
  //       })
  //     );
  //   }, 800);
  //   return () => clearTimeout(timeOut);
  // }, [minPrice, maxPrice]);
  //pagination fliter
  const paginationFilter = {
    categories: multipleCats,
    width: pWidth,
    height: pHeight,
    minprice: +minPrice,
    maxprice: +maxPrice,
  };

  return (
    <>
      <Helmet>
        <title>Ranbazaar - Shop</title>
        <meta name="description" content="Ranbazaar Private Limited"/>
        <link rel="canonical" href={`${baseUrl}shop`} />
        <meta property="og:title" content="Ranbazaar - Shop"/>
        <meta property="og:site_name" content="Ranbazaar" />
        <meta property="og:url" content={`${baseUrl}shop`} />
        <meta property="og:description" content="Ranbazaar Private Limited"/>
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/ranbazar.png"/>
      </Helmet>
      <CommonHeader />
      {delayLoading ? (
        <Loader />
      ) : (
        <div className="container-fluid mt-3 ">
          {catName && catName !== "null" && (
            <h2 className="text-center mb-0 light-bg">
              <b className="text-black">{catName}</b>
            </h2>
          )}
          {productId && getProductsData?.offerBanner && (
            <div className="banner-shop shadow-sm">
              <img
                className="rounded"
                src={getProductsData?.offerBanner}
                onContextMenu={handleContextMenu}
              />
            </div>
          )}

          <div className="row">
            <div className="col-md-2">
              <div className="sidebar p-1 bg-light rounded shadow-sm">
                {/* Container for Categories and Price Filter */}
                <div className="row">
                  {/* Product Categories Accordion */}
                  {!productId && (
                    <Col className="col-md-12 col-7">
                      <div className="accordion  mb-2" id="categoriesAccordion">
                        <div className="accordion-item border-0">
                          <h2 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button bg-light text-dark fw-bold rounded"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded={width > 468 ? true : false}
                              aria-controls="collapseOne"
                            >
                              Product Categories
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            className={`accordion-collapse collapse ${
                              width?.current > 550 ? `show` : ``
                            }`}
                            aria-labelledby="headingOne"
                            data-bs-parent="#categoriesAccordion"
                          >
                            <div className="accordion-body p-0">
                              <ul className="list-unstyled">
                                {!!getCatsFrontEndData &&
                                  getCatsFrontEndData.map((cat, index) => (
                                    <li className="py-2" key={index}>
                                      <label className="form-check d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          className="form-check-input me-2"
                                          name={cat?.name}
                                          id={cat?.id}
                                          value={cat?.id ? cat?.id : productId}
                                          checked={categories?.includes(
                                            cat?.id
                                          )}
                                          onChange={(e) =>
                                            handleCheckboxChange(false, e)
                                          }
                                        />
                                        {cat?.name}
                                      </label>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )}
                  <Col
                    className={`filter-button col-md-12 d-md-none ${
                      !productId ? `col-sm-5` : `col-sm-12`
                    }`}
                  >
                    <h1
                      className="btn btn-success w-100 mb-1 "
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#priceFilterCollapse"
                      aria-expanded="false"
                      aria-controls="priceFilterCollapse"
                    >
                      Filter by Price
                    </h1>
                  </Col>
                  <div className="col-md-12 d-md-none">
                    <div id="priceFilterCollapse" className="collapse">
                      <div className="p-3 bg-light rounded shadow-sm">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => {
                              window.location.reload();
                              // setMinPrice(0);
                              // setMaxPrice(5000);
                              // dispatch(
                              //   getProducts({
                              //     categories:
                              //       categories.length > 0 ? categories : "",
                              //     minprice: 0,
                              //     maxprice: 5000,
                              //     width: pWidth,
                              //     height: pHeight,
                              //   })
                              // );
                            }}
                          >
                            Clear
                          </button>
                        </div>
                        <div className="mb-3">
                          <label className="form-label text-dark fw-bold">
                            Min Price{" "}
                            <span className="text-muted">({minPrice})</span>
                          </label>
                          <input
                          type="number"
                            // type="range"
                            // className="form-range"
                            max="10000000"
                            value={minPrice}
                            onChange={(e) => {
                              let timeOut = setTimeout(async() => {
                               await dispatch(resetProductList());
                                dispatch(
                                  getProducts({
                                    categories: multipleCats.length > 0 ? multipleCats : [],
                                    minprice: +e.target.value,
                                    maxprice: +maxPrice,
                                    width: pWidth,
                                    height: pHeight,
                                  })
                                );
                              }, 800);
                              setMinPrice(e.target.value);
                              return () => clearTimeout(timeOut);
                              // dispatch(
                              //   getProducts({
                              //     categories:
                              //       categories.length > 0 ? categories : "",
                              //     minprice: +e.target.value,
                              //     maxprice: +maxPrice,
                              //     width: pWidth,
                              //     height: pHeight,
                              //   })
                              // );
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label text-dark fw-bold">
                            Max Price{" "}
                            <span className="text-muted">({maxPrice})</span>
                          </label>
                          <input
                          type="number"
                            // type="range"
                            // className="form-range"
                            max="10000000"
                            value={maxPrice}
                            onChange={(e) => {
                              let timeOut = setTimeout(async() => {
                                await dispatch(resetProductList());
                                dispatch(
                                  getProducts({
                                    categories: multipleCats.length > 0 ? multipleCats : [],
                                    minprice: +minPrice,
                                    maxprice: +e.target.value,
                                    width: pWidth,
                                    height: pHeight,
                                  })
                                );
                              }, 800);
                              setMaxPrice(e.target.value);
                              return () => clearTimeout(timeOut);
                              // dispatch(
                              //   getProducts({
                              //     categories:
                              //       categories.length > 0 ? categories : "",
                              //     minprice: +minPrice,
                              //     maxprice: +e.target.value,
                              //     width: pWidth,
                              //     height: pHeight,
                              //   })
                              // );
                            }}
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <select
                            className="form-select form-select-sm me-2"
                            value={minPrice}
                            onChange={(e) => {
                              dispatch(resetProductList())
                              dispatch(
                                getProducts({
                                  categories:
                                    categories.length > 0 ? categories : "",
                                  minprice: +e.target.value,
                                  maxprice: +maxPrice,
                                  width: pWidth,
                                  height: pHeight,
                                })
                              );
                              setMinPrice(e.target.value);
                            }}
                          >
                            <option value="Min">Min</option>
                            <option value="100">₹100</option>
                            <option value="500">₹500</option>
                            <option value="1000">₹1000</option>
                            <option value="2000">₹2000</option>
                            <option value="5000">₹5000</option>
                            <option value="10000">₹10000</option>
                            <option value="20000">₹20000</option>
                            <option value="50000">₹50000</option>
                            <option value="80000">₹80000</option>
                            <option value="100000">₹100000</option>
                          </select>
                          <span className="fw-bold">To</span>
                          <select
                            className="form-select form-select-sm ms-2"
                            value={maxPrice}
                            onChange={(e) => {
                              dispatch(resetProductList())
                              dispatch(
                                getProducts({
                                  categories:
                                    categories.length > 0 ? categories : "",
                                  minprice: minPrice,
                                  maxprice: +e.target.value,
                                  width: pWidth,
                                  height: pHeight,
                                })
                              );
                              setMaxPrice(e.target.value);
                            }}
                          >
                            <option value="">Max</option>
                            <option value="100">₹100</option>
                            <option value="500">₹500</option>
                            <option value="1000">₹1000</option>
                            <option value="2000">₹2000</option>
                            <option value="5000">₹5000</option>
                            <option value="10000">₹10000</option>
                            <option value="20000">₹20000</option>
                            <option value="50000">₹50000</option>
                            <option value="80000">₹80000</option>
                            <option value="100000">₹100000</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* For Desktop View */}
                  <div className="col-md-12 d-none d-md-block">
                    <button
                      className="btn btn-primary mt-2 w-100 theme-btn"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#priceFilterCollapseDesktop"
                      aria-expanded="false"
                      aria-controls="priceFilterCollapseDesktop"
                    >
                      Filter by Price
                    </button>
                    <div id="priceFilterCollapseDesktop" className="collapse">
                      <div className="p-3 bg-light rounded shadow-sm">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={() => {
                              setMinPrice(0);
                              setMaxPrice(10000000);
                              dispatch(
                                getProducts({
                                  categories:
                                    categories.length > 0 ? categories : "",
                                  minprice: 0,
                                  maxprice: 10000000,
                                  width: pWidth,
                                  height: pHeight,
                                })
                              );
                            }}
                          >
                            Clear
                          </button>
                        </div>
                        <div className="mb-3">
                          <label className="form-label text-dark fw-bold">
                            Min Price{" "}
                            <span className="text-muted">({minPrice})</span>
                          </label>
                          <input
                            type="number"
                            // type="range"
                            // className="form-range"
                            max="10000000"
                            value={minPrice}
                            onChange={(e) => {
                              let timeOut = setTimeout(async() => {
                               await dispatch(resetProductList());
                                dispatch(
                                  getProducts({
                                    categories: multipleCats.length > 0 ? multipleCats : [],
                                    minprice: +e.target.value,
                                    maxprice: +maxPrice,
                                    width: pWidth,
                                    height: pHeight,
                                  })
                                );
                              }, 800);
                              setMinPrice(e.target.value);
                              return () => clearTimeout(timeOut);
                              // dispatch(
                              //   getProducts({
                              //     categories:
                              //     categories.length > 0 ? categories : "",
                              //     minprice: +e.target.value,
                              //     maxprice: +maxPrice,
                              //     width: pWidth,
                              //     height: pHeight,
                              //   })
                              // );
                             
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label text-dark fw-bold">
                            Max Price{" "}
                            <span className="text-muted">({maxPrice})</span>
                          </label>
                          <input
                          type="number"
                            // type="range"
                            // className="form-range"
                            max="10000000"
                            value={maxPrice}
                            onChange={(e) => {
                              let timeOut = setTimeout(async() => {
                                await dispatch(resetProductList());
                                dispatch(
                                  getProducts({
                                    categories: multipleCats.length > 0 ? multipleCats : [],
                                    minprice: +minPrice,
                                    maxprice: +e.target.value,
                                    width: pWidth,
                                    height: pHeight,
                                  })
                                );
                              }, 800);
                              setMaxPrice(e.target.value);
                              return () => clearTimeout(timeOut);
                              // setMaxPrice(e.target.value);
                              // dispatch(
                              //   getProducts({
                              //     categories:
                              //       categories.length > 0 ? categories : "",
                              //     minprice: +minPrice,
                              //     maxprice: +e.target.value,
                              //     width: pWidth,
                              //     height: pHeight,
                              //   })
                              // );
                            }}
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <select
                            className="form-select form-select-sm me-2"
                            value={minPrice}
                            onChange={(e) => {
                              dispatch(resetProductList())
                              dispatch(
                                getProducts({
                                  categories:
                                  categories.length > 0 ? categories : "",
                                  minprice: +e.target.value,
                                  maxprice: maxPrice,
                                  width: pWidth,
                                  height: pHeight,
                                })
                              );
                              setMinPrice(e.target.value);
                            }}
                          >
                            <option value="Min">Min</option>
                            <option value="100">₹100</option>
                            <option value="500">₹500</option>
                            <option value="1000">₹1000</option>
                            <option value="2000">₹2000</option>
                            <option value="5000">₹5000</option>
                            <option value="10000">₹10000</option>
                            <option value="20000">₹20000</option>
                            <option value="50000">₹50000</option>
                            <option value="80000">₹80000</option>
                            <option value="100000">₹100000</option>
                          </select>
                          <span className="fw-bold">To</span>
                          <select
                            className="form-select form-select-sm ms-2"
                            value={maxPrice}
                            onChange={(e) => {
                              dispatch(resetProductList())
                              dispatch(
                                getProducts({
                                  categories:
                                    categories.length > 0 ? categories : "",
                                  minprice: +minPrice,
                                  maxprice: +e.target.value,
                                  width: pWidth,
                                  height: pHeight,
                                })
                              );
                              setMaxPrice(e.target.value);
                            }}
                          >
                            <option value="">Max</option>
                            <option value="100">₹100</option>
                            <option value="500">₹500</option>
                            <option value="1000">₹1000</option>
                            <option value="2000">₹2000</option>
                            <option value="5000">₹5000</option>
                            <option value="10000">₹10000</option>
                            <option value="20000">₹20000</option>
                            <option value="50000">₹50000</option>
                            <option value="80000">₹80000</option>
                            <option value="100000">₹100000</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-10">
              <div className="row">
                {(!productRecord) ? (
                  <div className={width > 468 ? "" : "notfountmbl"}>
                    <img src={commingsoon} onContextMenu={handleContextMenu} />
                  </div>
                ) : (
                  !!getProductsData &&
                  getProductsData.map((data, index) => {
                    return (
                      <div className="col-md-4 col-sm-6 col-6" key={index}>
                        <div
                          className="product-grid mx-0 card border-0 shadow-sm rounded-lg position-relative hover-grow"
                          style={{ maxWidth: "370px", borderRadius: "15px" }}
                        >
                          <div
                            className="product-image position-relative overflow-hidden"
                            style={{ borderRadius: "15px 15px 0 0" }}
                          >
                            <Link to={`/product/${data?.slug}`}>
                              <div className="card-img-actions position-relative">
                                {data?.productType === "customize" ? (
                                  data?.customizeDetails ? (
                                    <CustomizeProduct
                                      customizeDetails={data?.customizeDetails}
                                      page={page}
                                    />
                                  ) : (
                                    <h2>Loading...............</h2>
                                  )
                                ) : (
                                  <img
                                    src={
                                      data?.images
                                        ? `${data?.images[data?.defaultImage]}`
                                        : dummy
                                    }
                                    onContextMenu={handleContextMenu}
                                    className="img-fluid hover-scale"
                                    alt={data?.name}
                                    style={{
                                      // height: "150px",
                                      objectFit: "contain",
                                      borderRadius: "15px 15px 0 0",
                                    }}
                                  />
                                )}
                              </div>
                            </Link>
                            {!!data?.sale && (
                              <span className="product-discount-label bg-success text-white p-1 rounded position-absolute">
                                -
                                {(
                                  (100 * (data?.mrp - data?.sale)) /
                                  data?.mrp
                                ).toFixed(0)}
                                %
                              </span>
                            )}
                            <ul className="product-links list-inline m-0 position-absolute top-0 end-0 d-none">
                              <li className="list-inline-item">
                                <NavLink
                                  to={`/product/${data?.slug}`}
                                  className="text-dark"
                                >
                                  <i className="bi bi-search"></i>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                          <div className="product-content position-relative text-center p-2 bg-light rounded-bottom">
                            <button
                              onClick={() => navigate(`/product/${data?.slug}`)}
                              className="shop-cart-btn w-100 mt-1 py-1 text-light "
                            >
                              {data?.productType === "customize"
                                ? `Customize & Add To Cart`
                                : `Add to Cart`}
                            </button>
                            <Link to={`/product/${data?.slug}`}>
                              <div className="card-body p-2">
                                <div className="product-name-box">
                                  <p className="product-fullname mb-1 ">
                                    {data?.name.substring(0, 45)}...
                                  </p>
                                </div>
                                <hr />
                                <p className="mb-1">{data?.avgRating > 0 ? productRating(Math.round(data?.avgRating)) : null}</p>
                                <h3 className="mb-0 font-weight-semibold product__price text-success">
                                  <span className="discount-price text-danger">
                                    Rs.{data?.mrp}
                                  </span>{" "}
                                  Rs.{data?.sale}
                                </h3>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })
                  
                )}
              </div>
              {/* {!!shopProductsBlank && shopProductsBlank.length > 0 ? (
                  <Row>
                    <Col>
                    <Button
                    className="w-100 theme-btn mb-2"
                      onClick={() => {
                        setPage(page + 1);
                        dispatch(
                          getProducts({
                            page: page + 1,
                            categories: productId ? [productId] : "",
                            width: pWidth,
                            height: pHeight,
                          })
                        );
                      }}
                    >
                      Load More
                    </Button>
                    </Col>
                  </Row>
                ) : (
                  <p className="text-center">
                    <b>No record found 😔.</b>
                  </p>
                )} */}
            </div>
          </div>
        </div>
      )}
      <Row className="mt-3">
        <Col>
          <NumberPagination
            catName={catName}
            pageCount={pageCount}
            currentPage={page}
            api={getProducts}
            onPageChange={onPageChange}
            pageurl={shop}
            filters={paginationFilter}
            productId={productId}
          />
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default Shop;
