import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLogOut } from "../reducers/commonReducer";
import logo from "../images/logo.png";
import {
  addProduct,
  attributes,
  banners,
  categories,
  checker,
  codList,
  dashboard,
  EmailTemplates,
  fileSettings,
  home,
  infoPages,
  invoicePdf,
  login,
  manager,
  offers,
  orderPdf,
  packageList,
  productLabels,
  productList,
  reviews,
  settings,
  shippingCharges,
  socialMediaSetting,
  taxClasses,
  trackers,
  users,
  vendors,
  warehouse,
} from "../const";
import { Button } from "react-bootstrap";

const SideMenu = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [inactive, setInactive] = useState(false);

  const logOut = async () => {
    const apiResp = await dispatch(
      userLogOut({
        "x-auth-token": localStorage.getItem("x-auth-token"),
      })
    );
    if (apiResp?.payload?.status) {
      localStorage.clear();
      navigate(login);
    }
  };
  const toggleSidebar = () => {
    setInactive(!inactive);
  };
  return (
    <>
      <div className="row print-hide">
        <button className="toggle-button d-md-none" onClick={toggleSidebar}>
          <i
            className={`fa ${inactive ? "fa-times" : "fa-bars"} ${
              !inactive ? "" : "icon-rightt"
            }`}
            aria-hidden="true"
          ></i>
        </button>

        <div className={`col side-menu ${inactive ? "inactive" : ""}`}>
          <div className="top-section">
            <div className="logo px-1">
              <img src={logo} alt="Logo" />
              <button
                className="toggle-button d-md-none"
                onClick={toggleSidebar}
              >
                <i
                  className={`fa ${inactive ? "fa-times-circle" : "fa-bars"} ${
                    !inactive ? "" : "icon-right"
                  }`}
                  aria-hidden="true"
                ></i>
              </button>
            </div>

            <ul className="nav side-links fs-3 mt-1  flex-column">
              {localStorage.getItem("x-auth-token") &&
                localStorage.getItem("slug") === "admin" && (
                  <>
                    <NavLink to={dashboard}>
                      {inactive ? (
                        <i className="bi bi-bar-chart-line me-2 ">Dashboard</i>
                      ) : (
                        <ul>
                          {" "}
                          <li className="nav-items text-white">
                            <ul
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-bar-chart-line me-2 ds"></i>
                              Dashboard
                              <hr className="m-0" />
                            </ul>
                          </li>{" "}
                        </ul>
                      )}
                    </NavLink>
                    <ul>
      {/* Trigger Button for Collapse */}
      <li>
        <li
          className="nav-link sideMenuLinks bg-transparent pfont "
          data-bs-toggle="collapse"
          data-bs-target="#demo"
          aria-expanded="false"
          aria-controls="demo"
        >
          <i className="bi bi-box-seam  "></i> Product
          <i className="bi bi-chevron-double-down float-right "></i>
        </li>

        {/* Collapsible Content */}
        <div id="demo" className="collapse">
          <NavLink to={productList} className="nav-link sideMenuLinks">
            {inactive ? (
              <i className="bi bi-card-list me-2 mt-1">Products List</i>
            ) : (
              <ul>
                <li className="nav-items">
                  <ul
                    className="nav-link sideMenuLinks"
                    aria-current="page"
                    href="#"
                  >
                    <i className="bi bi-card-list me-2 ds"></i>
                    Products List
                  </ul>
                </li>
              </ul>
            )}
          </NavLink>

          <NavLink to={addProduct} className="nav-link sideMenuLinks">
            {inactive ? (
              <i className="bi bi-file-plus me-2 mt-2"> Add Products</i> 
            ) : (
              <ul>
                <li className="nav-items">
                  <ul
                    className="nav-link sideMenuLinks"
                    aria-current="page"
                    href="#"
                  >
                    <i className="bi bi-file-plus me-2 ds"></i>
                    Add Products
                  </ul>
                </li>
              </ul>
            )}
          </NavLink>
        </div>
        <hr className="m-0 mbl-hide" />
      </li>
    </ul>
                    <NavLink to="/orders">
                      {inactive ? (
                        <i className="bi bi-file-text me-2 ">Orders</i>
                      ) : (
                        <ul>
                          {" "}
                          <li className="nav-items">
                            <ul
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-file-text me-2 ds"></i>
                              Orders
                              <hr className="m-0" />
                            </ul>
                          </li>{" "}
                        </ul>
                      )}
                    </NavLink>
                    <NavLink to="/pending-orders">
                      {inactive ? (
                        <i className="bi bi-bag-check me-2 ">Pending Orders</i>
                      ) : (
                        <ul>
                          {" "}
                          <li className="nav-items">
                            <ul
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-bag-check me-2 ds"></i>
                              Pending Orders
                              <hr className="m-0" />
                            </ul>
                          </li>{" "}
                        </ul>
                      )}
                    </NavLink>
                    <NavLink to={users}>
                      {inactive ? (
                        <i className="bi bi-people me-2 ">Users</i>
                      ) : (
                        <ul>
                          {" "}
                          <li className="nav-items">
                            <ul
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-people me-2 ds"></i>
                              Users
                              <hr className="m-0" />
                            </ul>
                          </li>{" "}
                        </ul>
                      )}
                    </NavLink>
                    <NavLink to={vendors}>
                      {inactive ? (
                        <i className="bi bi-cash-stack me-2 ">Vendors</i>
                      ) : (
                        <ul>
                          <li className="nav-items">
                            <ul
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                            >
                              <i className="bi bi-cash-stack me-2 ds"></i>
                              Vendors
                              <hr className="m-0" />
                            </ul>
                          </li>
                        </ul>
                      )}
                    </NavLink>
                    <NavLink to={checker}>
                      {inactive ? (
                        <i className="bi bi-shield-check me-2 ">Checker</i>
                      ) : (
                        <ul>
                          <li className="nav-items">
                            <ul
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                            >
                              <i className="bi bi-shield-check me-2 ds"></i>
                              Checker
                              <hr className="m-0" />
                            </ul>
                          </li>
                        </ul>
                      )}
                    </NavLink>
                    <NavLink to={manager}>
                      {inactive ? (
                        <i className="bi bi-person-gear me-2 ">Manager</i>
                      ) : (
                        <ul>
                          <li className="nav-items">
                            <ul
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                            >
                              <i className="bi bi-person-gear me-2 ds"></i>
                              Manager
                              <hr className="m-0" />
                            </ul>
                          </li>
                        </ul>
                      )}
                    </NavLink>
                    <NavLink to={productLabels}>
                      {inactive ? (
                        <i className="bi bi-star me-2 ">Product Labels</i>
                      ) : (
                        <ul>
                          {" "}
                          <li className="nav-items text-white">
                            <ul
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-star me-2 ds"></i>
                              Product Labels
                              <hr className="m-0" />
                            </ul>
                          </li>{" "}
                        </ul>
                      )}
                    </NavLink>
                    <NavLink to={packageList}>
                      {inactive ? (
                        <i className="bi bi-box me-2 ">Package's</i>
                      ) : (
                        <ul>
                          {" "}
                          <li className="nav-items text-white">
                            <ul
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-box me-2 ds"></i>
                              Package's
                              <hr className="m-0" />
                            </ul>
                          </li>{" "}
                        </ul>
                      )}
                    </NavLink>
                    <NavLink to={codList}>
                      {inactive ? (
                        <i className="bi bi-wallet me-2 ">COD</i>
                      ) : (
                        <ul>
                          {" "}
                          <li className="nav-items text-white">
                            <ul
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-wallet me-2 ds"></i>
                              COD
                              <hr className="m-0" />
                            </ul>
                          </li>{" "}
                        </ul>
                      )}
                    </NavLink>
                    <NavLink to={warehouse}>
                      {inactive ? (
                        <i className="bi bi-buildings me-2 ">Warehouse</i>
                      ) : (
                        <ul>
                          {" "}
                          <li className="nav-items text-white">
                            <ul
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-buildings me-2 ds"></i>
                              Warehouse
                              <hr className="m-0" />
                            </ul>
                          </li>{" "}
                        </ul>
                      )}
                    </NavLink>
                    <NavLink to={shippingCharges}>
                      {inactive ? (
                        <i className="bi bi-truck me-2 ">Shipping Cost</i>
                      ) : (
                        <ul>
                          {" "}
                          <li className="nav-items">
                            <ul
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-truck ds"></i> Shipping Cost
                              <hr className="m-0" />
                            </ul>
                          </li>{" "}
                        </ul>
                      )}
                    </NavLink>
                    <NavLink to={trackers}>
                      {inactive ? (
                        <i className="bi bi-geo-alt me-2 ">Trackers</i>
                      ) : (
                        <ul>
                          {" "}
                          <li className="nav-items">
                            <ul
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-geo-alt ds"></i> Trackers
                              <hr className="m-0" />
                            </ul>
                          </li>{" "}
                        </ul>
                      )}
                    </NavLink>
                    <NavLink to={offers}>
                      {inactive ? (
                        <i className="bi bi-gift">Offers</i>
                      ) : (
                        <ul>
                          {" "}
                          <li className="nav-items">
                            <ul
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-gift ds"></i> Offers
                              <hr className="m-0" />
                            </ul>
                          </li>{" "}
                        </ul>
                      )}
                    </NavLink>
                    <NavLink to={attributes}>
                      {inactive ? (
                        <i className="bi bi-file-earmark-text me-2 ">
                          Attributes
                        </i>
                      ) : (
                        <ul>
                          {" "}
                          <li className="nav-items">
                            <ul
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-file-earmark-text me-2 ds"></i>
                              Attributes
                              <hr className="m-0" />
                            </ul>
                          </li>{" "}
                        </ul>
                      )}
                    </NavLink>
                    <NavLink to={taxClasses}>
                      {inactive ? (
                        <i className="bi bi-calculator me-2 ">TaxClasses</i>
                      ) : (
                        <ul>
                          <li className="nav-items">
                            <ul
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-calculator me-2 ds"></i>
                              TaxClasses
                              <hr className="m-0" />
                            </ul>
                          </li>
                        </ul>
                      )}
                    </NavLink>
                    <NavLink to={categories}>
                      {inactive ? (
                        <i className="bi bi-grid me-2 ">Categories</i>
                      ) : (
                        <ul>
                          {" "}
                          <li className="nav-items">
                            <ul
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-grid me-2 ds"></i>
                              Categories
                              <hr className="m-0" />
                            </ul>
                          </li>{" "}
                        </ul>
                      )}
                    </NavLink>
                    <NavLink to={banners}>
                      {inactive ? (
                        <i className="bi bi-card-heading me-2 ">Banners</i>
                      ) : (
                        <ul>
                          {" "}
                          <li className="nav-items">
                            <ul
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-card-heading me-2 ds"></i>
                              Banners
                              <hr className="m-0" />
                            </ul>
                          </li>{" "}
                        </ul>
                      )}
                    </NavLink>
                    <NavLink to={settings}>
                      {inactive ? (
                        <i className="bi bi-gear me-2 ">Settings</i>
                      ) : (
                        <ul>
                          {" "}
                          <li className="nav-items">
                            <ul
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-gear me-2 ds"></i>
                              Settings
                              <hr className="m-0" />
                            </ul>
                          </li>{" "}
                        </ul>
                      )}
                    </NavLink>
                    <NavLink to={fileSettings}>
                      {inactive ? (
                        <i className="bi bi-shield-lock me-2 ">File Settings</i>
                      ) : (
                        <ul>
                          {" "}
                          <li className="nav-items text-white">
                            <ul
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-file-code me-2 ds"></i>
                              File Settings
                              <hr className="m-0" />
                            </ul>
                          </li>{" "}
                        </ul>
                      )}
                    </NavLink>
                    <NavLink to={reviews}>
                      {inactive ? (
                        <i className="bi bi-heart me-2 ">Reviews</i>
                      ) : (
                        <ul>
                          {" "}
                          <li className="nav-items">
                            <ul
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-heart me-2 ds"></i>
                              Reviews
                              <hr className="m-0" />
                            </ul>
                          </li>{" "}
                        </ul>
                      )}
                    </NavLink>
                    <NavLink to={socialMediaSetting}>
                      {inactive ? (
                        <i className="bi bi-globe2 me-2 ">Social Setting</i>
                      ) : (
                        <ul>
                          {" "}
                          <li className="nav-items">
                            <ul
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-globe2 me-2 ds"></i>
                              Social Setting
                              <hr className="m-0" />
                            </ul>
                          </li>{" "}
                        </ul>
                      )}
                    </NavLink>
                    <NavLink to={EmailTemplates}>
                      {inactive ? (
                        <i className="bi bi-send me-2 ">Email Templates</i>
                      ) : (
                        <ul>
                          {" "}
                          <li className="nav-items">
                            <ul
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-send me-2 ds"></i>
                              Email Templates
                              <hr className="m-0" />
                            </ul>
                          </li>{" "}
                        </ul>
                      )}
                    </NavLink>

                    <NavLink to={infoPages}>
                      {inactive ? (
                        <i className="bi bi-info-circle me-2 ">Info Pages</i>
                      ) : (
                        <ul>
                          <li className="nav-items">
                            <ul
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-info-circle me-2 ds"></i>
                              Info Pages
                              <hr className="m-0" />
                            </ul>
                          </li>
                        </ul>
                      )}
                    </NavLink>
                    <NavLink to={orderPdf}>
                      {inactive ? (
                        <i className="bi bi-printer me-2 ">Pdf Orders</i>
                      ) : (
                        <ul>
                          <li className="nav-items">
                            <ul
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-printer me-2 ds"></i>
                              Pdf Orders
                              <hr className="m-0" />
                            </ul>
                          </li>
                        </ul>
                      )}
                    </NavLink>
                    <NavLink to={invoicePdf}>
                      {inactive ? (
                        <i className="bi bi-file-richtext me-2 ">Invoice</i>
                      ) : (
                        <ul>
                          <li className="nav-items">
                            <ul
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-file-richtext me-2 ds"></i>
                              Invoice
                              <hr className="m-0" />
                            </ul>
                          </li>
                        </ul>
                      )}
                    </NavLink>
                    {/* old */}
                  </>
                )}
              {/* Checker Panel */}
              {localStorage.getItem("x-auth-token") &&
                localStorage.getItem("slug") === "checker" && (
                  <>
                    <NavLink to="/orders">
                      {inactive ? (
                        <i className="bi bi-file-text me-2 ">Orders</i>
                      ) : (
                        <ul>
                          {" "}
                          <li className="nav-items">
                            <ul
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-file-text me-2 ds"></i>
                              Orders
                              <hr className="m-0" />
                            </ul>
                          </li>{" "}
                        </ul>
                      )}
                    </NavLink>
                  </>
                )}
            </ul>
          </div>
        </div>
      </div>

      <div className="col adminloggedin">
        <div className={`${inactive ? "content-active" : "content"}`}>
          <div className="d-flex justify-content-between  bg-side border-dark border-bottom">
            <div className="p-1">
              {/* <button
                  onClick={() => setInactive(!inactive)}
                  type="button"
                  className="btn btn-outline-primary"
                >
                  <i className="bi bi-hdd-stack"></i>
                </button> */}
            </div>
            <div className="p-2">
              {/* <Button variant="warning" className="btn">
                  <Link to="/my-profile">
                    <ul className="nav-link">
                      <i className="bi text-white bi-person-fill"></i>
                    </ul>
                  </Link>
                </Button> */}
              <Button
                onClick={() => navigate(home)}
                className="btn theme-btn btn-outline-primary m-1"
              >
                <ul className="nav-link text-white">Go To Website</ul>
              </Button>
              <Button
                variant="danger"
                onClick={logOut}
                className="btn btn-outline-primary"
              >
                <ul className="nav-link text-white">Log Out</ul>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideMenu;
