import React, { useEffect } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getInvoicePdfFilters } from "../../reducers/commonReducer";
import moment from "moment";
import FilterSettingAction from "./FilterSettingAction";

function FilterList() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getInvoicePdfFilters());
  }, []);
  const { getInvoicePdfFilterList } = useSelector(
    (state) => state.commonReducer
  );
  return (
    <Row>
      <Col md={12}>
        <h3 className="text-black">
          <b>Invoice</b>
        </h3>
      </Col>
      <Col md={12}>
        <Table striped={true} bordered hover>
          <thead>
            <tr className="tables">
              <th>Name</th>
              <th>Start</th>
              <th>End</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="tbody-table">
            <tr>
              <td>{getInvoicePdfFilterList?.name}</td>
              <td>
                {moment(getInvoicePdfFilterList?.startDate).format(
                  "YYYY-MM-DD"
                )}
              </td>
              <td>
                {moment(getInvoicePdfFilterList?.endDate).format(
                  "YYYY-MM-DD"
                )}
              </td>
              <td>
                <FilterSettingAction
                  name={getInvoicePdfFilterList?.name}
                  startDate={getInvoicePdfFilterList?.startDate}
                  endDate={getInvoicePdfFilterList?.endDate}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );
}

export default FilterList;
