import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import CommonHeader from "../Sub/CommonHeader";
import Footer from "../Sub/Footer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getORderResponse } from "../../reducers/commonReducer";
import { shop, userDashboard } from "../../const";
import Loader from "../../Components/Loader";
import ThankYouPage from "../Sub/ThankYouPage";

const Response = () => {
  const [loding, setLoading] = useState(true);
  const queryParameters = new URLSearchParams(window.location.search);
  const order_id = queryParameters.get("order_id");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const orderResp = async () => {
      setLoading(true);
      const apiResp = await dispatch(
        getORderResponse({
          orderid: order_id,
        })
      );
      if (apiResp?.payload?.status) {
        setLoading(false);
      }
    };
    orderResp();
  }, []);
  const { getORderResponseStatus } = useSelector(
    (state) => state.commonReducer
  );
  //0 pending,1 cancel, 2 processing, 3 verify 4 In_Transit , 5 shipping, 6 completed
  let orderRespStatus;
  let orderRespStatusClass;
//  let paymentStatus = 2;
  useEffect(() => {
    if (getORderResponseStatus?.paymentStatus === 2) {
      navigate(`/response?order-success&order_id=${order_id}`);
    }
  }, [getORderResponseStatus, navigate, order_id]);

  if (getORderResponseStatus?.paymentStatus === 3) {
    orderRespStatus = "Your Order is verify...";
    orderRespStatusClass = "text-secondary";
  } else if (getORderResponseStatus?.paymentStatus === 4) {
    orderRespStatus = "Your Order is In_Transit...";
    orderRespStatusClass = "text-warning";
  } else if (getORderResponseStatus?.paymentStatus === 5) {
    orderRespStatus = "Your Order is shipping...";
    orderRespStatusClass = "text-info";
  } else if (getORderResponseStatus?.paymentStatus === 6) {
    orderRespStatus = "Your Order is completed...";
    orderRespStatusClass = "text-success";
  } else {
    orderRespStatus = null;
    orderRespStatusClass = null;
  }
  const getBackgroundColor = (orderStatus) => {
    switch (orderStatus) {
      case 0:
        return "linear-gradient(45deg, #f39c12, #f1c40f)";
      case 1:
        return "linear-gradient(45deg, #ff9d93, #c0392b)";
      case 2:
        return "linear-gradient(45deg, #0a7bc7, #2980b9)";
      case 3:
        return "linear-gradient(45deg, #7f8c8d, #95a5a6)";
      case 4:
        return "linear-gradient(45deg, #fb0101, #f16161)";
      case 5:
        return "linear-gradient(45deg, #16415e, #2980b9)";
      case 6:
        return "linear-gradient(45deg, #2ecc71, #27ae60)";
      default:
        return "linear-gradient(45deg, #e73c3c1f, #e73c3c1f)";
    }
  };
  return (
    <>
      <CommonHeader />
      {/* <ThankYouPage
              status={2}
              title={"Thank You for Your Order!"}
              textColor={"text-success"}
              orderData={getORderResponse?.order}
              subTitle={"Success"}
            /> */}
      {loding ? (
        <Loader />
      ) : (
        <>
          {getORderResponseStatus?.paymentStatus === 0 ? (
            <ThankYouPage
              status={0}
              title={"Your Order is Pending. Please Try Again!"}
              textColor={"text-primary"}
              orderData={getORderResponse?.order}
              subTitle={"Pending"}
            />
          ) : null}
          {getORderResponseStatus?.paymentStatus === 1 ? (
            <ThankYouPage
              status={1}
              title={"Your Order is cancel. Please Try Again!"}
              textColor={"text-danger"}
              orderData={getORderResponse?.order}
              subTitle={"Cancel"}
            />
          ) : null}
          {getORderResponseStatus?.paymentStatus === 2 ? (
            <ThankYouPage
              status={2}
              title={"Thank You for Your Order!"}
              textColor={"text-success"}
              orderData={getORderResponse?.order}
              subTitle={"Success"}
            />
          ) : null}
        {(getORderResponseStatus?.paymentStatus !== 1 && getORderResponseStatus?.paymentStatus !== 2 && getORderResponseStatus?.paymentStatus !== 0) && <Container
            fluid
            className="py-5 order-status-container"
            style={{
              background: getBackgroundColor(
                getORderResponseStatus?.orderStatus
              ),
            }}
          >
            <div className="particle-background"></div>
            <Row className="justify-content-center text-center position-relative">
              <Col md={8} lg={6}>
                <h1
                  className={`display-3 mb-4 ${orderRespStatusClass} text-shadow`}
                >
                  {orderRespStatus}
                </h1>
                <div className="button-group">
                  <Link to={shop}>
                    <Button
                      variant="none"
                      size="lg"
                      className="px-4 py-2 custom-button"
                    >
                      Go To Shop
                    </Button>
                  </Link>
                  <Link to={userDashboard}>
                    <Button
                      variant="none"
                      size="lg"
                      className="px-4 py-2 custom-button"
                      style={{ marginLeft: "1rem" }}
                    >
                      Go To Order
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>}
        </>
      )}
      <Footer />
    </>
  );
};

export default Response;
