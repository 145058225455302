import React, { useEffect, useRef, useState } from "react";
import Footer from "../Sub/Footer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Sub/Header";
import { Button, Col, Row } from "react-bootstrap";
import { checkAddressArea, emptyCartlist, getCartlist, getUserAddressDetails, updateCart } from "../../reducers/commonReducer";
import Spinner from "react-bootstrap/Spinner";
import "../../table.css";
import { Helmet } from "react-helmet";
import {getUserAddress,  product, shop, baseUrl } from "../../const";
import OrderImgPopUp from "./OrderImgPopUp";

const Checkout = () => {
  const dispatch = useDispatch();
  const width = useRef(window.innerWidth);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  //api's
  useEffect(() => {
    setLoading(true);
    dispatch(getCartlist());
    setLoading(false);
    const intervalId = setInterval(() => {
      dispatch(getCartlist());
    }, 3000); 
    return () => clearInterval(intervalId);
  }, []);

  const { getCartlistData } = useSelector((state) => state.commonReducer);
  //update quantity
  const updateQuantity = async (quantity, id, itemRemove) => {
    if (itemRemove || quantity >= 1) {
      const apiResp = await dispatch(
        updateCart({ quantity, id, delete: itemRemove })
      );
      if (apiResp?.payload?.status) {
        dispatch(getCartlist());
      }
    }
  };
  useEffect(() => {
    if (getCartlistData?.list?.length === 0) {
      const timeout = setTimeout(() => {
        navigate('/shop');
      }, 2000); 
      return () => clearTimeout(timeout);
    }
  }, [getCartlistData?.list, navigate]);
  let totalAmount = 0;
  const isAllCustomize = getCartlistData?.list?.every(item => {
    if (item?.product?.productType === "customize") {
      return item?.mergeimg !== "";
    }
    return true;
  });
  return (
    <>
     <Helmet>
        <title>Ranbazaar - Checkout</title>
        <meta name="description" content="Ranbazaar Private Limited"/>
        <link rel="canonical" href={`${baseUrl}checkout`} />
        <meta property="og:title" content="Ranbazaar - Checkout"/>
        <meta property="og:site_name" content="Ranbazaar" />
        <meta property="og:url" content={`${baseUrl}checkout`} />
        <meta property="og:description" content="Ranbazaar Private Limited"/>
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/ranbazar.png"/>
      </Helmet>
      <Header />
      <div className="container pb-5 mt-4 order">
        <Row>
          <Col md={6} sm={6} xs={6}>
            <h4 className="d-flex justify-content-between align-items-center mb-3">
              <b>
                <span className="your-cart">Your cart</span>
              </b>
            </h4>
          </Col>
          <Col md={6} sm={6} xs={6}>
          <Button variant="danger" className="f-right" onClick={async() => {
           const apiResp = await dispatch(emptyCartlist())
           if(apiResp?.payload?.status){
            dispatch(getCartlist())
            navigate(shop)
           }
          }}>Cart Clear</Button>
          </Col>
        </Row>
        {loading ? (
          <Row className="justify-content-center">
            <Spinner animation="border" />
          </Row>
        ) : (
          <>
            <Row className="no-more-tables">
              <Col md={12}>
                {getCartlistData?.list?.length ? (
                  <table striped className="overflow-hidden">
                    <thead>
                      <tr>
                        <th>Sr No.</th>
                        <th>Product Name</th>
                        <th>Image</th>
                        <th>Qty</th>
                        <th></th>
                        <th>Price</th>
                        <th>SubTotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getCartlistData?.list?.map((item, index) => {
                        const subTotal = item?.quantity * item?.price;
                        totalAmount += subTotal;
                        let productImg = item?.product?.productType === "customize" ? item?.mergeimg : item?.product?.images?.[0]
                        return (
                          <tr key={index} className={width.current < 500 ? 'mt-2' : null}>
                            <td data-title="Sr No.">{index + 1}</td>
                            <td data-title="Product Name">
                              <Link to={`${product}/${item?.product?.slug}`}>{item?.product?.name}</Link>
                            </td>
                            <td data-title="Image">
                                {productImg ? 
                                <OrderImgPopUp title={"Check You Product"} image={productImg} alt={item?.product?.name}/>
                                 : <div className="spinner-border" role="status"></div>}
                            </td>
                            <td data-title="Qty">
                              <div className="numbers text-center d-flex">
                                <span
                                  className="minus bg-danger"
                                  onClick={async () =>
                                    updateQuantity(item?.quantity - 1, item?.id)
                                  }
                                >
                                  -
                                </span>
                                <input
                                  className="w-70"
                                  type="number"
                                  min={1}
                                  disabled
                                  value={item?.quantity}
                                />
                                <span
                                  className="plus bg-success"
                                  onClick={async () =>
                                    updateQuantity(item?.quantity + 1, item?.id)
                                  }
                                >
                                  +
                                </span>
                              </div>
                            </td>
                            <td data-title="Delete">
                              <Button
                                variant="danger"
                                className="px-2 py-1  "
                                onClick={async () =>
                                  updateQuantity(0, item?.id, true)
                                }
                              >
                                <i className="bi bi-trash3"></i>
                              </Button>
                            </td>
                            <td data-title="Price" className="text-success">
                              <span className="discount-price text-danger">
                                Rs.{item?.product?.mrp}
                              </span>{" "}
                              Rs.{item?.price}
                            </td>
                            <td data-title="SubTotal">
                              <b>{item?.quantity * item?.price}/-</b>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    {width.current > 500 ? (
                      <tfoot>
                        <tr className="totalsum">
                          <th colSpan={6}>Total</th>
                          <th>{totalAmount}/-</th>
                        </tr>
                      </tfoot>
                    ) : (
                      <Row>
                        <Col xs={9} sm={9}>
                          <h5 className="text-danger"><b >Total</b></h5>
                        </Col>
                        <Col xs={3} sm={3}>
                          <h5 className="text-danger"><b>{totalAmount}/-</b></h5>
                        </Col>
                      </Row>
                    )}
                  </table>
                ) : <Row>
                  <Col className="text-center">
                    <h2 className="text-danger">Record Not Found.</h2>
                    <Button className="btn-theme" onClick={() => navigate(shop)}>Continue To Shop</Button>
                  </Col>
                  </Row>}
              </Col>
            </Row>
            {(getCartlistData?.list?.length && isAllCustomize) ? <Row className="mt-3">
              <Col md={12}>
                {/* <Link to={`${getUserAddress}?amount=${totalAmount}`}> */}
                  <Button className="theme-btn w-100" onClick={async() => {
                    const apiResp = await dispatch(checkAddressArea())
                    if(apiResp?.payload?.status){
                      navigate(`${getUserAddress}?amount=${totalAmount}`)
                    }
                    }}> 
                    Buy Now <i className="bi bi-arrow-right"></i>
                  </Button>
                {/* </Link> */}
              </Col>
            </Row> : null}
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Checkout;
