import React, { useEffect } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getOrderPdfFilters } from "../../reducers/commonReducer";
import moment from "moment";
import FilterSettingAction from "./FilterSettingAction";

function FilterList() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrderPdfFilters());
  }, []);
  const { getOrderPdfFiltersList } = useSelector(
    (state) => state.commonReducer
  );
  return (
    <Row>
      <Col md={12}>
        <h3 className="text-black">
          <b>Settings</b>
        </h3>
      </Col>
      <Col md={12}>
        <Table striped={true} bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Start</th>
              <th>End</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{getOrderPdfFiltersList?.name}</td>
              <td>
                {moment(getOrderPdfFiltersList?.startDate).format(
                  "YYYY-MM-DD"
                )}
              </td>
              <td>
                {moment(getOrderPdfFiltersList?.endDate).format(
                  "YYYY-MM-DD"
                )}
              </td>
              <td>
                <FilterSettingAction
                  name={getOrderPdfFiltersList?.name}
                  startDate={getOrderPdfFiltersList?.startDate}
                  endDate={getOrderPdfFiltersList?.endDate}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );
}

export default FilterList;
