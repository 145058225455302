import Carousel from 'react-bootstrap/Carousel';
import { handleContextMenu } from '../../const';

function ProductMultiSlider({images}) {
  return (
    <Carousel>
        {
            !!images && images?.map((img, index) => <Carousel.Item key={index}>
            <img src={`${img}`} alt={img}  loading="lazy" onContextMenu={handleContextMenu}/>
          </Carousel.Item>)
        }
      
    </Carousel>
  );
}

export default ProductMultiSlider;